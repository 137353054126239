import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import LoginPage from './containers/LoginPage/loginPage'
import MasterPage from './containers/MasterPage/masterPage'
import withSplashScreen from './components/withSplashScreen'
import PrivateRoute from './containers/PrivateRoute/privateRoute'
import EarlyMasterPage from './containers/EarlyMasterPage/earlyMasterPage'
import commonActions from './store/actions/common'
import { connect } from 'react-redux'
import Snackbar from './components/SnackBar/snackBar'
import 'react-tippy/dist/tippy.css'
import history from './history'
import UserRegistration from './components/UserRegistration/UserRegistration'
import EditableFleetManagerInformation from './components/EditableFleetManagerInformation/EditableFleetManagerInformation'
import UserCreationConfirmation from './components/UserCreationConfirmation/UserCreationConfirmation'
import ResetPassword from './components/ResetPassword/ResetPassword'

const dispatchToProps = {
  setSnackbarRef: commonActions.setSnackbarRef
}

class App extends Component {
  snackbarRef = React.createRef()

  componentDidMount() {
    this.props.setSnackbarRef(this.snackbarRef)
  }

  render() {
    return (
      <>
        <Snackbar ref={this.snackbarRef} />
        <Router history={history}>
          <Switch>
            <Route path='/login' component={LoginPage} />
            <Route path='/sign-up-user' component={UserRegistration} />
            <Route path='/fleetManager/new' component={EditableFleetManagerInformation} />
            <Route path='/reset/:passCode?' component={ResetPassword} />
            <PrivateRoute path='/new/:userId' component={UserCreationConfirmation} />
            <PrivateRoute path='/devices/:selectedProfileId' component={MasterPage} />
            <PrivateRoute path='/' component={EarlyMasterPage} /> {/* render={() => <Redirect to='/devices' />} /> */}
          </Switch>
        </Router>
      </>
    )
  }
}

export default connect(null, dispatchToProps)(withSplashScreen(App))
