import React from 'react'
import './styles.scss'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'
import validators from '../../../utils/validators'
import editableUserActions from '../../../store/actions/editableUser'
import commonActions from '../../../store/actions/common'
import renderInput from '../../../utils/renderInput'

class EditableUserInformationForm extends React.Component {

    state = {
        backClicked: false,
        displayErrors: false,
        isEditable: false,
        isSavingData: false
    }

    componentDidMount() {
        const { updateTitle } = this.props

        if (this.props.userId !== 'new') {
            this.props.selectUserInfo(this.props.userId)
                .then(() => {
                    updateTitle(`${this.props.editableUser.data.lastName} ${this.props.editableUser.data.firstName}`)
                })
        }

        this.setState({
            isEditable: this.props.userId === 'new'
        })
    }

    componentWillUnmount() {
        this.props.clearUserInfo()
    }

    setDisplayErrors = (val) => {
        this.setState({ displayErrors: val })
    }

    setBackClicked = (val) => {
        this.setState({ backClicked: val })
    }

    handleBackClicked = () => {
        if (this.props.userId === 'new') {
            this.props.history.push('/login')
        } else {
            if (this.state.isEditable) {
                this.setState({ isEditable: false })
            } else {
                this.setBackClicked(true)
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const { currentFormValues, currentSyncErrors, saveUser, snackbarRef } = this.props

        this.setState({ isSavingData: true })

        for (const prop in currentSyncErrors) {
            if (currentSyncErrors.hasOwnProperty(prop)) {
                this.setDisplayErrors(true)
                this.setState({ isSavingData: false })
                return
            }
        }

        saveUser(this.props.userId, { ...currentFormValues, serialNumber: this.props.profileSerialNumber })
            .then(() => {
                this.setState({ isSavingData: false, isEditable: false })
                if (this.props.userId === 'new') {
                    this.props.setShowRegistrationApprovePopUp(true, 'Thanks for registering! You will receive an email when your registration has been approved by the system administrator')
                }
                this.props.history.push(`/user/${this.props.editableUser.userId}`)
                this.props.resetEditableUserFormValues()
                this.props.selectUserInfo(this.props.editableUser.userId)
                    .then(() => {
                        this.props.updateTitle(`${this.props.editableUser.data.lastName} ${this.props.editableUser.data.firstName}`)
                        snackbarRef.current.openSnackBar('User has been saved')
                    })
            })
            .catch((e) => {
                snackbarRef.current.openSnackBar(e.message)
                this.setState({ isSavingData: false })
            })
    }

    render() {
        const { backClicked, displayErrors, isSavingData } = this.state
        const { editableUser } = this.props

        if (backClicked) {
            return <Redirect to='/devices' />
        }

        return (
            <form className='EditableUserInformationForm' onSubmit={this.handleSubmit}>

                {
                    this.state.isEditable
                        ? <Field name='firstName' component={renderInput} label='First Name *' validate={validators.required} displayErrors={true} />
                        : <>
                            <label>First Name</label>
                            <div className='uneditable-form-field'>
                                {editableUser.data && editableUser.data.firstName}
                            </div>
                        </>
                }

                {
                    this.state.isEditable
                        ? <Field name='lastName' component={renderInput} label='Last Name *' validate={validators.required} displayErrors={true} />
                        : <>
                            <label>Last Name</label>
                            <div className='uneditable-form-field'>
                                {editableUser.data && editableUser.data.lastName}
                            </div>
                        </>
                }

                {
                    this.state.isEditable
                        ? <Field name='zip' component={renderInput} label='Zip/Postal Code *' validate={validators.required} displayErrors={displayErrors} />
                        : <>
                            <label>Zip/Postal Code</label>
                            <div className='uneditable-form-field'>
                                {editableUser.data && editableUser.data.zip}
                            </div>
                        </>
                }

                {
                    this.state.isEditable
                        ? <Field name='email' component={renderInput} label='Email *' type='email' validate={validators.required} displayErrors={displayErrors} />
                        : <>
                            <label>Email</label>
                            <div className='uneditable-form-field'>
                                {editableUser.data && editableUser.data.email}
                            </div>
                        </>
                }

                {
                    this.props.userId === 'new'
                        ? <>
                            <Field name='password' component={renderInput} label='Password *' type='password' validate={[validators.password, validators.required]} displayErrors={displayErrors} />
                            <Field name='confirmPassword' component={renderInput} label='Re-enter Password *' type='password' validate={[validators.password, validators.required]} displayErrors={displayErrors} />
                        </>
                        : <></>
                }


                <div className='required-label'>(*)<i>Required Fields</i></div>

                {
                    <div className='buttons-container'>
                        <input type='button' value='Back' className='btn gray big-radius shadow' onClick={() => this.handleBackClicked()} />
                        {
                            this.state.isEditable
                                ? <input type='submit' value='Save' className='btn yellow big-radius shadow' disabled={isSavingData} />
                                : <input type='button' value='Edit' className='btn yellow big-radius shadow' onClick={(e) => { e.preventDefault(); this.setState({ isEditable: true }) }} />
                        }
                    </div>
                }
            </form>
        )
    }
}

const stateToProps = state => ({
    initialValues: state.editableUser.data,
    currentFormValues: getFormValues('editableUserInformationForm')(state),
    currentSyncErrors: getFormSyncErrors('editableUserInformationForm')(state),
    snackbarRef: state.common.snackbarReference,
    editableUser: state.editableUser
})

const dispatchToProps = {
    saveUser: editableUserActions.saveUser,
    selectUserInfo: editableUserActions.selectUserInfo,
    clearUserInfo: editableUserActions.clearUserInfo,
    resetEditableUserFormValues: editableUserActions.resetEditableUserFormValues,
    setShowRegistrationApprovePopUp: commonActions.setShowRegistrationApprovePopUp
}

EditableUserInformationForm = reduxForm({
    form: 'editableUserInformationForm'
})(EditableUserInformationForm)

export default connect(stateToProps, dispatchToProps)(withRouter(EditableUserInformationForm))