import {
  FETCH_SELECTED_PROFILE_DATA,
  FETCH_SELECTED_PROFILE_DATA_SUCCESS,
  FETCH_SELECTED_PROFILE_DATA_ERROR,
  FETCH_SELECTED_PROFILE_SERVICE_HISTORY,
  FETCH_SELECTED_PROFILE_SERVICE_HISTORY_ERROR,
  FETCH_SELECTED_PROFILE_SERVICE_HISTORY_SUCCESS,
  FETCH_SELECTED_PROFILE_FEED,
  FETCH_SELECTED_PROFILE_FEED_ERROR,
  FETCH_SELECTED_PROFILE_FEED_SUCCESS,
  SET_EXPORT_REPORT_BUTTON_DISABLED,
  SET_SELECTED_PROFILE_MODEL_NUMBERS,
  SET_LIFT_CYCLE_HISTORY
} from '../types/selectedProfile'
import profileService from '../../services/productsService'
import clientService from '../../services/clientsService'
import serviceReportsService from '../../services/serviceReportsService'
import modelNumbersService from '../../services/modelNumbers'

const fetchSelectedProfileData = (profileId) => async dispatch => {
  try {
    dispatch({ type: FETCH_SELECTED_PROFILE_DATA })

    const profile = await profileService.fetchProfile(profileId)
    const client = await clientService.fetchClient(profile.clientId)
    const profileTypes = await profileService.fetchProfileTypes()

    const profileType = profileTypes.find(t => t.profileTypeId === profile.profileTypeId)

    profile.client = client
    profile.profileType = profileType

    dispatch({ type: FETCH_SELECTED_PROFILE_DATA_SUCCESS, profile })
    return profile
  } catch (error) {
    dispatch({ type: FETCH_SELECTED_PROFILE_DATA_ERROR, error })
  }
}

const fetchSelectedProfileServiceHistory = (profileId) => async dispatch => {
  try {
    dispatch({ type: FETCH_SELECTED_PROFILE_SERVICE_HISTORY })
    const repairTypes = await serviceReportsService.fetchRepairTypes()
    const reports = await serviceReportsService.fetchServiceReportByProfileId(profileId)
    dispatch({ type: FETCH_SELECTED_PROFILE_SERVICE_HISTORY_SUCCESS, reports, repairTypes })
  } catch (error) {
    dispatch({ type: FETCH_SELECTED_PROFILE_SERVICE_HISTORY_ERROR, error })
  }
}

const fetchProfileFeed = (profileId) => async dispatch => {
  try {
    dispatch({ type: FETCH_SELECTED_PROFILE_FEED })
    const profileFeed = await profileService.getProfileFeed(profileId)
    dispatch({ type: FETCH_SELECTED_PROFILE_FEED_SUCCESS, data: profileFeed })
  } catch (error) {
    dispatch({ type: FETCH_SELECTED_PROFILE_FEED_ERROR, error })
  }
}

const exportExcelReport = (profileId) => async dispatch => {
  try {
    dispatch({ type: SET_EXPORT_REPORT_BUTTON_DISABLED, disabled: true })
    const data = await profileService.exportExcelReport(profileId)
    console.log(data)
  } finally {
    dispatch({ type: SET_EXPORT_REPORT_BUTTON_DISABLED, disabled: false })
  }
}

const fetchModelNumbers = () => async (dispatch, getState) => {
  try {
    const items = await modelNumbersService.getModelNumbers()
    dispatch({ type: SET_SELECTED_PROFILE_MODEL_NUMBERS, items })
  } catch (error) {
    console.log(error)
  }
}

const fetchLiftCycleHistory = (profileId) => async (dispatch, getState) => {
  try {
    const items = await profileService.liftCycleHistory(profileId)
    dispatch({ type: SET_LIFT_CYCLE_HISTORY, items })
  } catch (error) {
    console.log(error)
  }
}

export default {
  fetchSelectedProfileData,
  fetchSelectedProfileServiceHistory,
  fetchProfileFeed,
  exportExcelReport,
  fetchModelNumbers,
  fetchLiftCycleHistory
}
