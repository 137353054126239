export const FETCH_EDITABLE_PROFILE_INFO = 'FETCH_EDITABLE_PROFILE_INFO'
export const FETCH_EDITABLE_PROFILE_INFO_SUCCESS = 'FETCH_EDITABLE_PROFILE_INFO_SUCCESS'
export const EDITABLE_PROFILE_ERROR = 'EDITABLE_PROFILE_ERROR'
export const SAVE_EDITABLE_PROFILE = 'SAVE_EDITABLE_PROFILE'
export const CLEAR_EDITABLE_PROFILE = 'CLEAR_EDITABLE_PROFILE'
export const CHANGE_SELECTED_DEVICE_TYPE = 'CHANGE_SELECTED_DEVICE_TYPE'
export const FETCH_MODEL_NUMBERS = 'FETCH_MODEL_NUMBERS'
export const FETCH_MODEL_NUMBERS_SUCCESS = 'FETCH_MODEL_NUMBERS_SUCCESS'
export const FETCH_MODEL_NUMBERS_ERROR = 'FETCH_MODEL_NUMBERS_ERROR'
export const SET_EDITABLE_PROFILE_CLIENT_INFO = 'SET_EDITABLE_PROFILE_CLIENT_INFO'
export const FETCH_EDITABLE_PROFILES_SUGGESTIONS = 'FETCH_EDITABLE_PROFILES_SUGGESTIONS'
export const FETCH_EDITABLE_PROFILES_SUGGESTIONS_SUCCESS = 'FETCH_EDITABLE_PROFILES_SUGGESTIONS_SUCCESS'
export const CHANGE_SUGGESTION_INPUT_VALUE = 'CHANGE_SUGGESTION_INPUT_VALUE'
export const CLEAR_AUTOSUGGEST = 'CLEAR_AUTOSUGGEST'