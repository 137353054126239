import React, { Component } from 'react'
import './styles.scss'

class RepairCheckBox extends Component {
  render () {
    const { name, repairTypeId, disabled, checked } = this.props.repair
    return (
      <label className='repair-checkbox' htmlFor={repairTypeId}>
        <input onChange={(e) => { this.props.onChange(e) }} disabled={disabled} checked={checked} type='checkbox' id={repairTypeId} />
        <div className='bgchk' />
        <p>{name}</p>
      </label>
    )
  }
}

export default RepairCheckBox
