import React, {useState} from 'react'
import './styles.scss'
import { Link, withRouter } from 'react-router-dom'
import EditableFleetAdminInformationForm from './EditableFleetAdminInformationForm/EditableFleetAdminInformationForm'

const EditableFleetAdminInformation = props => {

    const [title, setTitle] = useState('Loading...')

    return (
        <div className='EditableFleetAdminInformation'>
            <div className="back">
                <Link to='/devices' />
                <div className="wide-container">
                    <div className="h1">
                        {
                            props.match.params.fleetAdminId === 'new'
                                ? 'New Fleet Admin Profile'
                                : title
                        }
                    </div>
                </div>
            </div>
            <div className="wide-container profile-block">
                <EditableFleetAdminInformationForm fleetAdminId={props.match.params.fleetAdminId} updateTitle={setTitle} />
            </div>
        </div>
    )
}

export default withRouter(EditableFleetAdminInformation)
