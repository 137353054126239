import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import DetailServiceHistoryItem from '../DetailServiceHistoryItem/detailServiceHistoryItem'
import { withRouter } from 'react-router-dom'
import LoadingSpinner from '../LoadingSpinner/loadingSpinner'

const DetailServiceHistory = (props) => {
  const { selectedProfile, match } = props

  return (
    <div id='detail-service-history'>
      <div className='add-container'>
        <Link to={`${match.url}/new`}>
          <button className='btn orange small'>
            <span className='plus'>New Report</span>
          </button>
        </Link>
      </div>
      {
        selectedProfile.isServiceHistoryLoading
          ? <LoadingSpinner />
          : <div className='history-list'>
            {
              selectedProfile.reports.map((report, i) => {
                return (<DetailServiceHistoryItem key={report.serviceReportId} report={report}
                  number={selectedProfile.reports.length - i}
                  profileTypeId={selectedProfile.profileTypeId}
                  repairTypes={selectedProfile.repairTypes}
                  linkToDetail={`${match.url}/${report.serviceReportId}`} />)
              })
            }
          </div>
      }
    </div>
  )
}

export default withRouter(DetailServiceHistory)
