export const FETCH_SELCETED_REPORT = 'FETCH_SELCETED_REPORT'
export const FETCH_SELECTED_REPORT_SUCCESS = 'FETCH_SELECTED_REPORT_SUCCESS'

export const GENERATE_NEW_REPORT = 'GENERATE_NEW_REPORT'
export const GENERATE_NEW_REPORT_ERROR = 'GENERATE_NEW_REPORT_ERROR'

export const SAVE_SELECTED_REPORT = 'SAVE_SELECTED_REPORT'
export const SAVE_SELECTED_REPORT_SUCCESS = 'SAVE_SELECTED_REPORT_SUCCESS'

export const SELECTED_REPORT_ERROR = 'SELECTED_REPORT_ERROR'
