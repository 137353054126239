import React from 'react'
import './styles.scss'
import AnalyticButtons from '../AnalyticButtons/analyticButtons'

const HandicareAnalyticsView = (props) => {

    return (
        <div className='HandicareAnalyticsView'>
            <div className='analytics-label'>
                <b>Handicare</b> Analytics
            </div>
            <AnalyticButtons {...props} />
        </div>
    )
}

export default HandicareAnalyticsView