import React, { Component } from 'react'
import './styles.scss'

class ConfirmationDialog extends Component {

  render() {
    const {
      onOk,
      onCancel,
      submitOnly,
      show,
      title,
      cancelText,
      confirmText
    } = this.props

    return (
      <div className={`overlay ${show ? 'show' : 'hide'}`} onClick={onCancel}>
        <div className='confirmation-dialog'>
          <p>{title}</p>
          <div>
            {
              submitOnly
                ? <></>
                : <button className='btn cancel shadow' onClick={onCancel}>{cancelText || 'Cancel'}</button>
            }
            <button className='btn yellow shadow' onClick={onOk}>{confirmText || 'Confirm'}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmationDialog
