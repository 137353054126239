import React from 'react'
import './styles.scss'
import {
    MdPerson,
    MdAdd,
    MdAirlineSeatReclineExtra,
    MdAssignmentInd,
    MdFolderShared
} from 'react-icons/md'
import authService from '../../services/auth'
import userRole from '../../constants/userRole'
import { withRouter } from 'react-router-dom'

class DashboardAddButton extends React.Component {

    state = {
        isOpen: false
    }

    handleButtonClick = () => {
        this.setState((state) => ({
            isOpen: !state.isOpen
        }))
    }

    handleOptionClick = (redirectTo) => {
        this.props.history.push(redirectTo)
    }

    renderOption = (title, icon, redirectTo) => {
        return (
            <div className='add-options-item'
                onClick={() => this.handleOptionClick(redirectTo)}
                key={title}>
                <span className='action-text'>{title}</span>
                <span className='action-icon'>{icon}</span>
            </div>
        )
    }

    renderOptionsByRole = () => {
        const role = authService.getCurrentUserRole()

        switch (role) {
            case userRole.globalAdmin:
                return [
                    this.renderOption('+ Add New Regional Admin', <MdAirlineSeatReclineExtra />, '/localAdmin/new'),
                ]
            case userRole.localAdmin:
                return [
                    this.renderOption('+ Add New Fleet Admin', <MdAssignmentInd />, '/fleetAdmin/new')
                ]
            case userRole.fleetAdmin:
                return [
                    this.renderOption('+ Add New User', <MdFolderShared />, '/profile/new')
                ]
            case userRole.fleetManager:
                return [
                    this.renderOption('+ Add New User', <MdFolderShared />, '/profile/new')
                ]
            default:
                return []

        }
    }

    render() {
        const { isOpen } = this.state
        return (
            <div className='DashboardAddButton'>
                <div className='add-button' onClick={() => this.handleButtonClick()}>
                    <MdAdd className='add-icon' strokeWidth={1} />
                    <MdPerson className='person-icon' />
                </div>
                <div className={`add-options ${isOpen ? 'show' : ''}`}>
                    {
                        this.renderOptionsByRole()
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(DashboardAddButton)
