// import {
// } from '../types/forgotPassword'
import passwordService from '../../services/forgotPassword'

const sendForgotPasswordEmail = email => async dispatch => {
    try {
        await passwordService.sendForgotPasswordEmail(email)
    } catch (error) {
        console.error(error)
    }
}

// for forgot password flow
const resetPassword = (passCode, password) => async dispatch => {
    try {
        await passwordService.resetPassword(passCode, password)
    } catch (error) {
        console.error(error)
        throw error.response.data
    }
}

const updatePassword = (password) => async dispatch => {
    try {
        await passwordService.updatePassword(password)
    } catch (error) {
        console.error(error)
        throw error.response.data
    }
}

export default {
    sendForgotPasswordEmail,
    resetPassword,
    updatePassword
}