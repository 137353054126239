export const FETCH_SELECTED_PROFILE_DATA = 'FETCH_SELECTED_PROFILE'
export const FETCH_SELECTED_PROFILE_DATA_SUCCESS = 'FETCH_SELECTED_PROFILE_SUCCESS'
export const FETCH_SELECTED_PROFILE_DATA_ERROR = 'FETCH_SELECTED_PROFILE_ERROR'

export const FETCH_SELECTED_PROFILE_SERVICE_HISTORY = 'FETCH_SELECTED_PROFILE_SERVICE_HISTORY'
export const FETCH_SELECTED_PROFILE_SERVICE_HISTORY_SUCCESS = 'FETCH_SELECTED_PROFILE_SERVICE_HISTORY_SUCCESS'
export const FETCH_SELECTED_PROFILE_SERVICE_HISTORY_ERROR = 'FETCH_SELECTED_PROFILE_SERVICE_HISTORY_ERROR'

export const FETCH_SELECTED_PROFILE_FEED = 'FETCH_SELECTED_PROFILE_FEED'
export const FETCH_SELECTED_PROFILE_FEED_SUCCESS = 'FETCH_SELECTED_PROFILE_FEED_SUCCESS'
export const FETCH_SELECTED_PROFILE_FEED_ERROR = 'FETCH_SELECTED_PROFILE_FEED_ERROR'

export const CLEAR_SELECTED_PROFILE = 'CLEAR_SELECTED_PROFILE'

export const SET_EXPORT_REPORT_BUTTON_DISABLED = 'SET_EXPORT_REPORT_BUTTON_DISABLED'

export const SET_SELECTED_PROFILE_MODEL_NUMBERS = 'SET_SELECTED_PROFILE_MODEL_NUMBERS'
export const SET_LIFT_CYCLE_HISTORY = 'SET_LIFT_CYCLE_HISTORY'
