import {
  FETCH_EDITABLE_PROFILE_INFO,
  FETCH_EDITABLE_PROFILE_INFO_SUCCESS,
  EDITABLE_PROFILE_ERROR,
  CLEAR_EDITABLE_PROFILE,
  SAVE_EDITABLE_PROFILE,
  FETCH_MODEL_NUMBERS_SUCCESS,
  SET_EDITABLE_PROFILE_CLIENT_INFO,
  CHANGE_SUGGESTION_INPUT_VALUE,
  CLEAR_AUTOSUGGEST,
  FETCH_EDITABLE_PROFILES_SUGGESTIONS_SUCCESS,
  FETCH_EDITABLE_PROFILES_SUGGESTIONS
} from '../types/editableProfile'

export const initialUserSuggestions = [
  {
    clientRaw: {
      firstName: 'New User',
      lastName: '',
      userType: 'new'
    },
    userId: 'new'
  }
]

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  modelNumbers: [],
  profileId: null, // need to redirect to created device page
  isSuggestionsLoading: false,
  suggestionInputValue: '',
  suggestions: initialUserSuggestions
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_EDITABLE_PROFILE_INFO:
      return { ...state, isLoading: true }
    case FETCH_EDITABLE_PROFILE_INFO_SUCCESS:
      return { ...state, isLoading: false, data: payload.data }
    case EDITABLE_PROFILE_ERROR:
      return { ...state, isLoading: false, error: payload.error }
    case CLEAR_EDITABLE_PROFILE:
      return initialState
    case SAVE_EDITABLE_PROFILE:
      return { ...state, profileId: payload.data.profile.profileId, isLoading: false, error: null, data: payload.data }
    case FETCH_MODEL_NUMBERS_SUCCESS:
      return { ...state, modelNumbers: payload.modelNumbers }
    case SET_EDITABLE_PROFILE_CLIENT_INFO:
      const newData = { ...state.data, client: payload.client }
      return { ...state, data: newData }
    case CHANGE_SUGGESTION_INPUT_VALUE:
      return { ...state, suggestionInputValue: payload.value }
    case CLEAR_AUTOSUGGEST:
      return {
        ...state, isSuggestionsLoading: false, suggestionInputValue: '', suggestions: initialUserSuggestions
      }
    case FETCH_EDITABLE_PROFILES_SUGGESTIONS:
      return {
        ...state,
        isSuggestionsLoading: true,
        suggestions: payload.suggestions
      }
    case FETCH_EDITABLE_PROFILES_SUGGESTIONS_SUCCESS:
      return { 
        ...state, 
        suggestions: payload.suggestions, 
        isSuggestionsLoading: false 
      }
    default:
      return state
  }
}
