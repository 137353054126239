import { HANDICARE_USER_STORAGE_KEY } from '../constants'
import moment from 'moment'
import axios from 'axios'
import usersService from './usersService'
import profileService from './productsService'
import userRole from '../constants/userRole'

const login = async (username, password) => {
  try {
    const tokenResponse = await axios.post('auth/authenticate', { username, password }, { baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL })
    const token = tokenResponse.data
    const user = userFromToken(token.accessToken)

    const session = {
      token,
      expirationDateTime: moment().add(token.expiresIn, 'seconds').toDate()
    }

    localStorage.setItem(HANDICARE_USER_STORAGE_KEY, JSON.stringify(session))

    let domainUser

    if (user.extension_Role === userRole.user) {
      domainUser = await usersService.getUser(user.oid)
    }

    if (!domainUser) {
      return session
    }

    const openProfile = await profileService.fetchProfileBySerialNumber(domainUser.serialNumber)

    session.user = domainUser
    session.profile = openProfile

    localStorage.setItem(HANDICARE_USER_STORAGE_KEY, JSON.stringify(session))

    return session
  } catch (error) {
    throw error.response.data
  }
}

const logout = () => {
  localStorage.removeItem(HANDICARE_USER_STORAGE_KEY)
}

const refreshAuth = async () => {
  const session = JSON.parse(localStorage.getItem(HANDICARE_USER_STORAGE_KEY))
  console.log('refreshing auth')
  try {
    const token = (await axios.post('auth/refresh', { refreshToken: session.token.refreshToken }, { baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL })).data
    const user = userFromToken(token.accessToken)

    const newSession = {
      token,
      expirationDateTime: moment().add(token.expiresIn, 'seconds').toDate()
    }

    localStorage.setItem(HANDICARE_USER_STORAGE_KEY, JSON.stringify(newSession))

    let domainUser

    if (user.extension_Role === userRole.user) {
      domainUser = await usersService.getUser(user.oid)
    }

    if (!domainUser) {
      return newSession
    }

    const openProfile = await profileService.fetchProfileBySerialNumber(domainUser.serialNumber)

    newSession.user = domainUser
    newSession.profile = openProfile

    localStorage.setItem(HANDICARE_USER_STORAGE_KEY, JSON.stringify(newSession))

    return newSession
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getSession = () => {
  const session = JSON.parse(localStorage.getItem(HANDICARE_USER_STORAGE_KEY))
  return session
}

const getCurrentUser = () => {
  const token = getSession().token.accessToken
  return userFromToken(token)
}

const userFromToken = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

const getCurrentUserRole = () => {
  return getCurrentUser().extension_Role
}

const isAdmin = () => {
  return getCurrentUserRole() === userRole.globalAdmin
}

const isFleetAdmin = () => {
  return getCurrentUserRole() === userRole.fleetAdmin
}

const isLocalAdmin = () => {
  return getCurrentUserRole() === userRole.localAdmin
}

const isFleetManager = () => {
  return getCurrentUserRole() === userRole.fleetManager
}

export const rolePriorities = {
  user: 0,
  fleetManager: 1,
  fleetAdmin: 2,
  localAdmin: 3,
  globalAdmin: 5
}

export default {
  login,
  logout,
  refreshAuth,
  getSession,
  getCurrentUser,
  isAdmin,
  isFleetAdmin,
  isLocalAdmin,
  isFleetManager,
  getCurrentUserRole
}
