import {
  FETCH_EDITABLE_FLEET_ADMIN_ERROR,
  FETCH_EDITABLE_FLEET_ADMIN_INFO,
  FETCH_EDITABLE_FLEET_ADMIN_INFO_SUCCESS,
  CLEAR_EDITABLE_FLEET_ADMIN,
  SAVE_EDITABLE_FLEET_ADMIN,
  SET_EDITABLE_FLEET_ADMIN_INFO
} from '../types/editableFleetAdmin'

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  fleetAdminId: null
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_EDITABLE_FLEET_ADMIN_INFO:
      return { ...state, isLoading: true }
    case FETCH_EDITABLE_FLEET_ADMIN_INFO_SUCCESS:
      return { ...state, isLoading: false, data: payload.fleetAdmin }
    case FETCH_EDITABLE_FLEET_ADMIN_ERROR:
      return { ...state, isLoading: false, error: payload.error }
    case CLEAR_EDITABLE_FLEET_ADMIN:
      return initialState
    case SAVE_EDITABLE_FLEET_ADMIN:
      return { ...state, fleetAdminId: payload.fleetAdmin.id, isLoading: false, error: null }
    case SET_EDITABLE_FLEET_ADMIN_INFO:
      return { ...state, data: payload.data }
    default:
      return state
  }
}
