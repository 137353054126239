import axios from 'axios'
import { HANDICARE_USER_STORAGE_KEY } from '../constants'

const GET = 'GET'
const POST = 'POST'
const PUT = 'PUT'
const DELETE = 'DELETE'
const PATCH = 'PATCH'

const AUTH_URLS = ['auth/authenticate', 'auth/refresh']

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accepts'] = 'application/json'

axios.interceptors.request.use((config) => {
  const session = JSON.parse(localStorage.getItem(HANDICARE_USER_STORAGE_KEY))

  if (session && session.token && !(config.url in AUTH_URLS)) {
    config.headers['Authorization'] = `Bearer ${session.token.accessToken}`
  }
  return config
}, (error) => {
  console.error(error)
  return Promise.reject(error)
})

const createRequestConfig = async (method, url, params, body) => {
  const config = {
    method: method,
    data: body,
    url: url,
    baseURL: process.env.REACT_APP_HANDICARE_BASE_URL,
    params: params
  }

  return config
}

const request = async (method, url, params, body) => {
  const config = await createRequestConfig(method, url, params, body)
  return await axios.request(config)
}

const get = async (url, params) => {
  return await request(GET, url, params, null)
}

const post = async (url, body, params) => {
  return await request(POST, url, params, body)
}

const put = async (url, body, params) => {
  return await request(PUT, url, params, body)
}

const delete_ = async (url, body, params) => {
  return await request(DELETE, url, params, body)
}

const patch = async (url, body, params) => {
  return await request(PATCH, url, params, body)
}

export default {
  get,
  post,
  put,
  delete: delete_,
  patch
}
