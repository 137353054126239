import {
  FETCH_REPORTS,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_ERROR
} from '../types/serviceHistory'

const initialState = {
  reports: [],
  repairTypes: [],
  isLoading: false,
  error: null
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_REPORTS:
      return { ...state, isLoading: true }
    case FETCH_REPORTS_SUCCESS:
      return { ...state, isLoading: false, reports: payload.items }
    case FETCH_REPORTS_ERROR:
      return { ...state, isLoading: false, error: payload.error }
    default:
      return state
  }
}
