import {
  CLOSE_DIAGNOSTIC_QUESTIONS_POP_UP,
  SET_SELECTED_QUESTION_TREE,
  CLEAR_QUESTION_TREE_TIMEOUT,
  SET_HIDE_FREQUENCY_TIMER_FOR_SELECTED_QUESTION_TREE,
  SET_QUESTION_POP_UP_POSITION
} from '../types/questions'
import questionsService from '../../services/questions'

const closeDiagnosticsQuestionPopUps = (clearCooldown = false) => async (dispatch, getState) => {
  const questionsState = getState().questions
  if (questionsState.hideFrequencyTimer) {
    clearTimeout(questionsState.hideFrequencyTimer)
  }

  if (clearCooldown) {
    const repeatFrequencyTimer = questionsState.questionCooldowns[questionsState.selectedQuestionTree.diagnosticsRuleId]
    if (repeatFrequencyTimer) {
      clearTimeout(repeatFrequencyTimer)
    }
  }

  dispatch({ type: CLOSE_DIAGNOSTIC_QUESTIONS_POP_UP })
}

const fetchDiagnosticQuestion = (profileId) => async (dispatch, getState) => {
  try {
    const questionsState = getState().questions
    const currentQTId = questionsState.selectedQuestionTree && questionsState.selectedQuestionTree.diagnosticsRuleId

    const questionTrees = await questionsService.fetchQuestions(profileId)

    let selectedQuestionTree = null

    // clear cooldowns if issue was fixed
    for (let key in questionsState.questionCooldowns) {
      if (questionsState.questionCooldowns.hasOwnProperty(key)) {
        if (questionTrees.findIndex(t => t.diagnosticsRuleId === +key) === -1) {
          clearTimeout(questionsState.questionCooldowns[key])
          dispatch({ type: CLEAR_QUESTION_TREE_TIMEOUT, diagnosticsRuleId: key })
        }
      }
    }

    // check if there is cooldown for question then do not dispaly it
    for (let i = 0; i < questionTrees.length; i++) {
      if (questionTrees[i].diagnosticsRuleId === currentQTId) {
        return
      }

      if (questionsState.questionCooldowns[questionTrees[i].diagnosticsRuleId]) {
        continue
      }

      selectedQuestionTree = questionTrees[i]
      break
    }

    if (!selectedQuestionTree) {
      if (questionsState.selectedQuestionTree) {
        dispatch(closeDiagnosticsQuestionPopUps())
      }
      return
    }

    // set cooldown for selected question tree
    const selectedQuestionTreeTimeout = selectedQuestionTree && selectedQuestionTree.repeatFrequency
      ? setTimeout(() => dispatch({ type: CLEAR_QUESTION_TREE_TIMEOUT, diagnosticsRuleId: selectedQuestionTree.diagnosticsRuleId }), selectedQuestionTree.repeatFrequency * 1000)
      : null

    dispatch({ type: SET_SELECTED_QUESTION_TREE, selectedQuestionTree, selectedQuestionTreeTimeout })
  } catch (error) {
    console.error(error)
  }
}

const setHideFrequencyTimer = () => async (dispatch, getState) => {
  const selectedQuestionTree = getState().questions.selectedQuestionTree

  if (selectedQuestionTree && selectedQuestionTree.hideFrequency) {
    console.log('hide frequency', selectedQuestionTree.hideFrequency)
    const timer = setTimeout(() => {
      dispatch(closeDiagnosticsQuestionPopUps(true))
    }, selectedQuestionTree.hideFrequency * 1000)
    dispatch({ type: SET_HIDE_FREQUENCY_TIMER_FOR_SELECTED_QUESTION_TREE, timer })
  }
}

const setQuestionPopUpPosition = (x, y) => async dispatch => {
  dispatch({ type: SET_QUESTION_POP_UP_POSITION, position: { x, y } })
}

export default {
  closeDiagnosticsQuestionPopUps,
  fetchDiagnosticQuestion,
  setHideFrequencyTimer,
  setQuestionPopUpPosition
}
