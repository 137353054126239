import React, { Component } from 'react'
import './styles.scss'
import LoadingSpinner from '../LoadingSpinner/loadingSpinner'
import moment from 'moment'
import authSvc from '../../services/auth'

class BriefDeviceFeed extends Component {
  state = {
    lastMessageTimeLabel: moment.utc(moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(moment(this.props.lastMessageTime, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss") // this.props.lastMessageTime
  }

  componentDidMount() {
    this.interval = setInterval(() => { this.setState({ lastMessageTimeLabel: moment.utc(moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(moment(this.props.lastMessageTime, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss") }) })
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleAssignAssistButton = (event) => {
    this.props.onAssignAssistClick()
  }

  render() {
    const { feed, isFeedLoading, profile, lastMessageTime } = this.props
    return (
      <div className='BriefDeviceFeed'>
        <div className='col'>
          <div className='device-feed-last-update'>
            <span>
              {
                profile && profile.assistId && lastMessageTime
                  ? `Last updated ${this.state.lastMessageTimeLabel}`
                  : ''
              }
            </span>
          </div>
          <div className='table'>
            {
                isFeedLoading
                  ? <LoadingSpinner /> // if is loading then show loading spinner
                  : profile && !profile.assistId
                    ? <div className='assign-device-btn-container'>
                      <button className='btn orange small'
                        onClick={this.handleAssignAssistButton}
                        disabled={!(authSvc.isFleetManager() || authSvc.isFleetAdmin() || authSvc.isLocalAdmin())}>
                        <span>Assign Assist</span>
                      </button>
                    </div>
                  : (
                    !feed
                      ? <h1 className='no-info'>No info...</h1> // if loaded and no feed data then display message
                      : Array.isArray(feed) // if have some data then check if it's array then
                        ? feed.map(i => // map array to proper elemtns in DOM
                          <div className='row' key={i.key || i.k}>
                            <div className='name'>{`${i.key || i.k}:`}</div>
                            <div className='value' style={{ color: i.color || i.c }}>{i.value || i.v}</div>
                          </div>)
                        : <h1 className='no-info'>No info...</h1> // if it' not array then it has no data (it's linked with dta format coming from backend)
                  )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default BriefDeviceFeed
