import {
  FETCH_PRODUCT_TYPES,
} from '../types/products'

const initialState = {
  types: []
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_PRODUCT_TYPES:
      return { ...state, types: payload.types }
    default:
      return state
  }
}
