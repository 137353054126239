import React from 'react'
import smallLogo from '../../assets/img/logo-small.svg'
import EditableUserInformationForm from '../EditableUserInformation/EditableUserInformationForm/EditableUserInformationForm'
import { withRouter, Link, Redirect } from 'react-router-dom'
import history from '../../history'
import './styles.scss'

const UserRegistration = props => {

    if (!(history.location
        && history.location.state
        && history.location.state.serialNumber)) {
            return <Redirect to='/login' />
    }

    return (
        <div className='UserRegistration'>
            <header id='search-device-header'>
                <div className='container'>
                    <div className='logo'><img src={smallLogo} alt='handicare' />User</div>
                    <div className='toolbar'>
                        <div className='settings'>
                            <span className='icon-gear' />
                            <div className='settings-menu'>
                                <ul>
                                    {
                                        // eslint-disable-next-line
                                    }<li><a>Log Out</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="back">
                <Link to='/login' />
                <div className="wide-container">
                    <div className="h1">
                        New User Profile
                    </div>
                </div>
            </div>
            <div className="wide-container profile-block">
                <EditableUserInformationForm userId='new' profileSerialNumber={history.location.state.serialNumber} />
            </div>
        </div>
    )
}

export default withRouter(UserRegistration)
