export const FETCH_DIAGNOSTIC_QUESTIONS = 'FETCH_DIAGNOSTIC_QUESTIONS'
export const FETCH_DIAGNOSTIC_QUESTIONS_SUCCESS = 'FETCH_DIAGNOSTIC_QUESTIONS_SUCCESS'
export const FETCH_DIAGNOSTIC_QUESTIONS_ERROR = 'FETCH_DIAGNOSTIC_QUESTIONS_ERROR'

export const SET_SELECTED_QUESTION_TREE = 'SET_SELECTED_QUESTION_TREE'

export const SET_HIDE_FREQUENCY_TIMER_FOR_SELECTED_QUESTION_TREE = 'SET_HIDE_FREQUENCY_TIMER_FOR_SELECTED_QUESTION_TREE'

export const CLEAR_QUESTION_TREE_TIMEOUT = 'CLEAR_QUESTION_TREE_TIMEOUT'

export const CLEAR_QUESTION_COOLDOWNS = 'CLEAR_QUESTION_COOLDOWNS'

export const CLOSE_DIAGNOSTIC_QUESTIONS_POP_UP = 'CLOSE_DIAGNOSTIC_QUESTIONS_POP_UP'

export const SET_QUESTION_POP_UP_POSITION = 'SET_QUESTION_POP_UP_POSITION'
