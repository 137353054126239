import React, { Component } from 'react'
import SplashScreen from './SplashScreen/splashScreen'

const withSplashScreen = (WrappedComponent) => {
  return class extends Component {
    constructor (props) {
      super(props)
      this.state = {
        loading: true
      }
    }

    componentDidMount () {
      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 3000)
    }

    render () {
      if (this.state.loading) return <SplashScreen />

      return <WrappedComponent {...this.props} />
    }
  }
}

export default withSplashScreen
