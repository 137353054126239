import React, { Component } from 'react'
import './styles.scss'
import Draggable from 'react-draggable'

class QuestionairePopUp extends Component {

    state = {
        currentQuestion: 'root',
    }
    questionPopUpRef = React.createRef()

    selectAnswer = (answ) => {
        const hasAnswers = !!this.props.questionTree.questions[this.state.currentQuestion].answers

        if (hasAnswers) {
            const next = answ
                ? this.props.questionTree.questions[this.state.currentQuestion].answers[0].nextId
                : this.props.questionTree.questions[this.state.currentQuestion].answers[1].nextId

            if (next) {
                this.setState(() => ({ currentQuestion: next }), () => {
                    if (!this.props.questionTree.questions[next].answers) {
                        this.props.setLastQuestion()
                    }
                })
            } else {
                this.props.handleClose()
            }
        } else {
            this.props.handleClose()
        }
    }

    render() {
        const { questionTree, handleClose, handleDragStop, position } = this.props
        const screen = document.body

        if (!this.state.currentQuestion) {
            return <></>
        }
        return (
            <Draggable bounds='parent'
                handle='.popup-question-title'
                onStop={() => handleDragStop(this.questionPopUpRef)}
                defaultPosition={position || { x: screen.offsetWidth / 2 - 200, y: screen.offsetHeight / 2 - 200 }}>
                <div className='popup-question' ref={this.questionPopUpRef}>
                    <div className="popup-question-title">
                        <p className="icon-info">Diagnostic Questions</p>
                        <div className="icon-close" onClick={handleClose}></div>
                    </div>
                    <div className="popup-question-message">
                        <p>{questionTree.questions[this.state.currentQuestion].title}</p>
                    </div>
                    {
                        questionTree.questions[this.state.currentQuestion].answers
                            ? <div className="popup-question-btns">
                                <button className="btn round orange"
                                    onClick={() => this.selectAnswer(true)}>Y</button>
                                <button className="btn round orange"
                                    onClick={() => this.selectAnswer(false)}>N</button>
                            </div>
                            : <></>
                    }
                </div>
            </Draggable>
        )
    }
}

export default QuestionairePopUp