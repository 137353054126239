import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import requestMiddleware from './middlewares/requestMiddleware'

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(requestMiddleware, thunk)
    )
  )
  return store
}

export {
  configureStore
}
