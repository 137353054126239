import React from 'react'
import LargeLogo from '../LargeLogo/largeLogo'
import './styles.scss'

const SplashScreen = () => {
  return (
    <div id='splash-screen'>
      <LargeLogo />
    </div>
  )
}

export default SplashScreen
