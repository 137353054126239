import {
    FETCH_CONFIRM_USER,
    FETCH_CONFIRM_USER_SUCCESS,
    CLEAR_CONFIRM_USER
} from '../types/confirmUser'
import usersService from '../../services/usersService'

const fetchUser = (userId) => async dispatch => {
    try {
        dispatch({ type: FETCH_CONFIRM_USER })
        const user = await usersService.getUser(userId)
        dispatch({ type: FETCH_CONFIRM_USER_SUCCESS, user })
    } catch (error) {

    }
}

const accept = (userId) => async dispatch => {
    try {
        const user = await usersService.getUser(userId)
        await usersService.updateUser({ id: user.id, isActive: true })
    } catch (error) {
        throw error
    }
}

const reject = (userId) => async dispatch => {
    try {
        // const user = await usersService.getUser(userId)
        // await usersService.updateUser({...user, isActive: true })
    } catch (error) {

    }
}

const clearConfirmUserInfo = () => async dispatch => {
    dispatch({ type: CLEAR_CONFIRM_USER })
}

export default {
    fetchUser,
    accept,
    reject,
    clearConfirmUserInfo
}