import React, { useState } from 'react'
import './styles.scss'
import Intro from './Intro/Intro'
import Scanning from './Scanning/Scanning'
import Assigning from './Assigning/Assigning'
import Success from './Success/Success'
import Error from './Error/Error'

const assignDeviceViews = {
    intro: 'intro',
    scanning: 'scanning',
    assigning: 'assigning',
    success: 'success',
    error: 'error'
}

const AssignDeviceFlow = (props) => {

    const [view, setView] = useState(assignDeviceViews.intro)

    const getView = () => {
        switch (view) {
            case assignDeviceViews.intro:
                return <Intro onNext={handleIntroNext} onCancel={handleIntroCancel} />
            case assignDeviceViews.scanning:
                return <Scanning onNext={handleScanningNext} onCancel={handleScanningCancel} />
            case assignDeviceViews.assigning:
                return <Assigning />
            case assignDeviceViews.success:
                return <Success onContinue={handleSuccessNext} />
            case assignDeviceViews.error:
                return <Error onTryAgain={() => props.toMainView()} />
            default:
                return null
        }
    }

    const handleIntroCancel = () => {
        props.toMainView()
    }

    const handleIntroNext = () => {
        setView(assignDeviceViews.scanning)
    }

    const handleScanningCancel = () => {
        props.toMainView()
    }

    const checkProfile = () => {

        // 1.5 min delay while device is connecting divided by 5sec delay between requests
        const COUNTER_LIMIT = (1.5 * 60 * 1000) / (5 * 1000)

        let counter = 0

        const callback = () => {
            counter++

            props.getProfileInfo()
                .then(p => {
                    if (p.assistId) {
                        props.onAssignSuccess()
                            .then((res) => {
                                // setView(assignDeviceViews.success)
                                // // check if we can keep success view
                                props.toMainView()
                            })
                    } else {
                        if (counter > COUNTER_LIMIT) {
                            setView(assignDeviceViews.error)
                        } else {
                            setTimeout(callback, 5000)
                        }
                    }
                })
                .catch(e => {
                    if (counter > COUNTER_LIMIT) {
                        setView(assignDeviceViews.error)
                    } else {
                        setTimeout(callback, 5000)
                    }
                })
        }

        setTimeout(callback, 5000)
    }

    const handleScanningNext = (assistId) => {
        setView(assignDeviceViews.assigning)
        props.assignDevice(assistId)
            .then(() => {
                checkProfile()
            })
            .catch(() => {
                setView(assignDeviceViews.error)
            })
    }

    const handleSuccessNext = () => {
        props.toMainView()
    }

return (
    <div className='AssignDeviceFlow'>
        {
            getView()
        }
    </div>
)
}

export default AssignDeviceFlow
