export const CONNECT_TO_STREAMING_SOCKET = 'CONNECT_TO_STREAMING_SOCKET'
export const CLOSE_STREAMING_SOCKET = 'CLOSE_STREAMING_SOCKET'
export const CONNECTION_TO_STREAMING_SOCKET_ESTABLISHED = 'CONNECTION_TO_STREAMING_SOCKET_ESTABLISHED'
export const STREAMING_ERROR = 'STREAMING_ERROR'

export const SET_DEVICE_FEED_DATA = 'SET_DEVICE_FEED_DATA'
export const SET_LIFT_OPERATION_STATE_DATA = 'SET_LIFT_OPERATION_STATE_DATA'
export const SET_BATTERY_STATE_DATA = 'SET_BATTERY_STATE_DATA'
export const SET_DIAGNOSTICS_LOG_DATA = 'SET_DIAGNOSTICS_LOG_DATA'
export const SET_ERROR_LOG_DATA = 'SET_ERROR_LOG_DATA'

export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED'
export const NO_MESSAGE_RECIEVED = 'NO_MESSAGE_RECIEVED'
export const SET_DISCONNECTED_TIMEOUT = 'SET_DISCONNECTED_TIMEOUT'
export const SET_APP_CONNECTED_INTERVAL = 'SET_APP_CONNECTED_INTERVAL'

export const C2D_CONNECTED_MESSAGE = 'С2D_CONNECTED_MESSAGE'
export const C2D_DISCONNECTED_MESSAGE = 'C2D_DISCONNECTED_MESSAGE'

export const SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING = 'SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING'
