import React from 'react'
import './styles.scss'

const Confirmation = (props) => {

    const {
        nextServiceDate,
        strapReplacement,
        batteryReplacement
    } = props.data

    return (
        <div className='Confirmation'>
            <div className='description'>Please confirm the information is correct</div>
            <div className='row-item'>
                <div className='name'>Next Service Date:</div>
                <div className='value'>{nextServiceDate}</div>
            </div>
            <div className='row-item'>
                <div className='name'>Strap Replacement:</div>
                <div className='value'>{strapReplacement ? 'Yes' : 'No'}</div>
            </div>
            <div className='row-item'>
                <div className='name'>Battery Replacement:</div>
                <div className='value'>{batteryReplacement ? 'Yes' : 'No'}</div>
            </div>
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onBack}>Back</button>
                <button className='btn orange shadow' onClick={props.onReset} disabled={props.resetting}>Reset</button>
            </div>
        </div>
    )
}

export default Confirmation
