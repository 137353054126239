import React from 'react'
import './styles.scss'

const Error = (props) => {
    return (
        <div className='Error'>
            <div className='description'>
                Assignment failed. Power cycle the assist, and wait for a status LED to stop blinking before re-trying the assignment.
            </div>
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onTryAgain}>Try Again</button>
            </div>
        </div>
    )
}

export default Error
