import React, { useState } from 'react'
import smallLogo from '../../assets/img/logo-small.svg'
import { Link, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import './styles.scss'
import forgotPasswordActions from '../../store/actions/forgotPassword'
import authService from '../../services/auth'
import { PASSWORD_REGEX } from '../../utils/validators'
import ConfirmationDialog from '../ConfirmationDialog/confirmationDialog'
import authActions from '../../store/actions/auth'
import commonActions from '../../store/actions/common'

let ResetPassword = (props) => {

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState(null)
    const [redirect, setRedirect] = useState(false)
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false)

    const handleCloseLogoutConfirmation = (val) => {
        if (val) {
            props.logout()
            props.history.push('/login')
        }
        setShowLogoutConfirmation(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setPasswordError(null)

        if (!(password && confirmPassword)) {
            setPasswordError('One or more field is empty')
            return
        }

        if (password !== confirmPassword) {
            setPasswordError('Password doesn\'t match Confirm Password')
            return
        }

        if (!PASSWORD_REGEX.test(password)) {
            setPasswordError('Password must contain 1 capital letter, 1 digit, 1 special character and must be at least 8 characters long')
            return
        }

        // if passCode provided then we handle forgot password flow
        if (props.match.params.passCode) {
            props.resetPassword(props.match.params.passCode, password)
                .then(() => {
                    setRedirect(true)
                    props.snackbarRef.current.openSnackBar('Password was updated')
                })
                .catch(e => {
                    setPasswordError(e.message)
                })
        } else {
            props.updatePassword(password)
                .then(() => {
                    setRedirect(true)
                    props.snackbarRef.current.openSnackBar('Password was updated')
                })
                .catch(e => {
                    setPasswordError(e.message)
                })
        }
    }

    const session = authService.getSession()

    if (!(props.match.params.passCode || session)) {
        props.snackbarRef.current.openSnackBar('No authorized user or passCode set')
        return <Redirect to='/login' />
    }

    if (redirect) {
        return <Redirect to='/login' />
    }

    const classes = `settings ${props.logoutMenuOpen ? 'open' : ''}`

    return (
        <div className='ResetPassword'>
            <ConfirmationDialog title='Are you sure you would like to Log Out?'
                show={showLogoutConfirmation}
                onOk={() => handleCloseLogoutConfirmation(true)}
                onCancel={() => handleCloseLogoutConfirmation(false)} />
            <header id='search-device-header'>
                <div className='container'>
                    <div className='logo'><img src={smallLogo} alt='handicare' />Customer Service</div>
                    <div className='toolbar'>
                        {
                            authService.getSession()
                                ? <div className={classes}>
                                    <span className='icon-gear' onClick={() => props.setLogoutMenuOpenState(!props.logoutMenuOpen)} />
                                    <div className='settings-menu'>
                                        <ul>
                                            {
                                                // eslint-disable-next-line
                                            }<li><a onClick={() => setShowLogoutConfirmation(true)}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </div>
                                : <></>
                        }
                    </div>
                </div>
            </header>
            <div className="back">
                <Link to='/login' />
                <div className="wide-container">
                    <div className="h1">
                        Reset Password
                    </div>
                </div>
            </div>
            <div className="wide-container profile-block">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='full-width-block'>
                        <label htmlFor='password'>Password</label>
                        <input onChange={(e) => setPassword(e.target.value)}
                            value={password} type='password'
                            id='password'
                            placeholder='Password must contain 1 capital letter, 1 digit, 1 special character, and must be at least 8 characters long' />
                    </div>
                    <div className='full-width-block'>
                        <label htmlFor='confirmPassword'>Confirm Password</label>
                        <input onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword} type='password'
                            id='confirmPassword'
                            placeholder='Password must contain 1 capital letter, 1 digit, 1 special character, and must be at least 8 characters long' />
                    </div>
                    {
                        passwordError && <span className='error'>{passwordError}</span>
                    }
                    <input type='submit' value='Reset' className='btn yellow big-radius shadow' />
                </form>
            </div>
        </div>
    )
}

const stateToProps = state => ({
    snackbarRef: state.common.snackbarReference,
    logoutMenuOpen: state.common.logoutMenuOpen
})

const dispatchToProps = {
    logout: authActions.logout,
    ...forgotPasswordActions,
    setLogoutMenuOpenState: commonActions.setLogoutMenuOpenState
}

ResetPassword = withRouter(ResetPassword)
ResetPassword = connect(stateToProps, dispatchToProps)(ResetPassword)

export default ResetPassword