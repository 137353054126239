import React, { useState } from 'react'
import './styles.scss'
import { MdInfo, MdClose } from 'react-icons/md'
import EnterPassword from './EnterPassword/EnterPassword'
import TryAgain from './TryAgain/TryAgain'
import EditableResetPMForm from './EditableResetPMForm/EditableResetPMForm'
import Confirmation from './Confirmation/Confirmation'
import { connect } from 'react-redux'
import resetPMActions from '../../store/actions/resetPM'
import { withRouter } from 'react-router-dom'

const views = {
    enterPassword: 'enterPassword',
    tryAgain: 'tryAgain',
    editableForm: 'editableForm',
    confirmation: 'confirmation'
}

const stateToProps = (state) => ({
    checkingPassword: state.resetPM.checkPasswordLoading,
    resetting: state.resetPM.resetting,
    snackbarRef: state.common.snackbarReference,
})

const dispatchToProps = {
    ...resetPMActions
}

const ResetPMPopUp = (props) => {
    const {
        requestClose,
        checkingPassword,
        resetting,
        checkResetPMPassword,
        match: { params: urlParams },
        resetPM,
        snackbarRef
    } = props

    const [view, setView] = useState(views.enterPassword)
    const [resetPMData, setResetPMData] = useState(null)

    const getView = (viewName) => {
        switch (viewName) {
            case views.enterPassword:
                return <EnterPassword checking={checkingPassword} onNext={(p) => enterPasswordNextHandler(p)} onBack={() => requestClose()} />
            case views.tryAgain:
                return <TryAgain onTryAgain={() => setView(views.enterPassword)} />
            case views.editableForm:
                return <EditableResetPMForm onNext={(data) => editableResetPMFormNextHandler(data)} onBack={() => requestClose()} />
            case views.confirmation:
                return <Confirmation resetting={resetting} data={resetPMData} onReset={() => confirmationResetHandler()} onBack={() => setView(views.editableForm)} />
            default:
                return null
        }
    }

    const enterPasswordNextHandler = (password) => {
        const { selectedProfileId } = urlParams
        checkResetPMPassword(selectedProfileId, password)
            .then(passwordCorrect => {
                if (passwordCorrect) {
                    setView(views.editableForm)
                } else {
                    setView(views.tryAgain)
                }
            })
            .catch(e => {
                console.error(e)
                snackbarRef.current.openSnackBar('Errors occured. Try again later.', { backgroundColor: '#a83256' })
                requestClose()
            })
    }

    const editableResetPMFormNextHandler = (data) => {
        setResetPMData(data)
        setView(views.confirmation)
    }

    const confirmationResetHandler = () => {
        const { selectedProfileId } = urlParams
        resetPM(selectedProfileId, resetPMData)
            .then(success => {
                if (success) {
                    snackbarRef.current.openSnackBar('Operation successful.', { backgroundColor: '#469D7C' })
                } else {
                    snackbarRef.current.openSnackBar('Operation failed.', { backgroundColor: '#a83256' })
                }
                requestClose()
            })
            .catch(e => {
                console.error(e)
                snackbarRef.current.openSnackBar('Errors occured. Try again later.', { backgroundColor: '#a83256' })
                requestClose()
            })
    }

    return (
        <>
            <div className='ResetPMPopUp'>
                <div className='overlay' onClick={() => requestClose()}></div>
                <div className='header-bar'>
                    <MdInfo />
                    <div className='title'>Reset PM</div>
                    <div className='spacer'></div>
                    <MdClose className='close' onClick={() => requestClose()} />
                </div>
                <div className='content'>
                    {getView(view)}
                </div>
            </div>
        </>
    )
}

export default connect(stateToProps, dispatchToProps)(withRouter(ResetPMPopUp))
