import React, { useState } from 'react'
import './styles.scss'
import { Link, withRouter } from 'react-router-dom'
import EditableProfileInformationForm from './EditableProfileInformationForm/EditableProfileInformationForm'

const EditableProfileInformation = (props) => {

    const [title, setTitle] = useState('Loading...')

    return (
        <div className='EditableProfileInformation'>
            <div className="back">
                <Link to='/devices' />
                <div className="wide-container">
                    <div className="h1">
                        {
                            props.match.params.profileId === 'new'
                                ? 'New Profile'
                                : title
                        }
                    </div>
                </div>
            </div>
            <div className="wide-container profile-block">
                <EditableProfileInformationForm profileId={props.match.params.profileId} updateTitle={setTitle} />
            </div>
        </div>
    )
}

export default withRouter(EditableProfileInformation)