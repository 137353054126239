import {
    SET_CHECK_RESET_PM_PASSWORD_LOADING,
    SET_RESETTING_PM
} from '../types/resetPM'
import resetPMService from '../../services/resetPMService'

const checkResetPMPassword = (profileId, password) => async (dispatch) => {
    try {
        dispatch({ type: SET_CHECK_RESET_PM_PASSWORD_LOADING, loading: true })
        const checked = await resetPMService.checkPassword(profileId, password)
        dispatch({ type: SET_CHECK_RESET_PM_PASSWORD_LOADING, loading: false })

        return checked.success
    } catch (error) {
        dispatch({ type: SET_CHECK_RESET_PM_PASSWORD_LOADING, loading: false })
    }
}

const resetPM = (profileId, data) => async (dispatch) => {
    try {
        dispatch({ type: SET_RESETTING_PM, resetting: true })
        const result = await resetPMService.resetPM(profileId, data)
        dispatch({ type: SET_RESETTING_PM, resetting: false })
        
        return result.success
    } catch (error) {
        dispatch({ type: SET_RESETTING_PM, resetting: false })
    }
}

export default {
    checkResetPMPassword,
    resetPM
}
