import React, { useState } from 'react'
import './styles.scss'

const EnterPassword = (props) => {

    const [password, setPassword] = useState('')


    return (
        <div className='EnterPassword'>
            <label htmlFor='reset-pm-password'>Enter Password</label>
            <input id='reset-pm-password' type='password' value={password} onChange={e => setPassword(e.target.value)}></input>
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onBack}>Back</button>
                <button className='btn orange shadow' onClick={() => props.onNext(password)} disabled={props.checking}>Next</button>
            </div>
        </div>
    )
}

export default EnterPassword
