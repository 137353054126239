import React, { useState } from 'react'
import './styles.scss'
import QrReader from 'react-qr-reader'

const Scanning = (props) => {

    const [scannedData, setScannedData] = useState('')

    const successMessage = "Data scanned successfully";

    const handleScan = (data) => {
        if (!data) {
            return
        }

        if (scannedData) {
            return
        }

        setScannedData(successMessage)
        setTimeout(() => props.onNext(data), 1500);
    }

    const handleError = (error) => {
        console.log(error)
    }

    return (
        <div className='Scanning'>
            <div className='qr-container'>
                <QrReader
                    delay={500}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }} />
            </div>
            {
                scannedData
                    ? <div className='scanned-data'>
                        <div className='data'>
                            {scannedData}
                        </div>
                    </div>
                    : <div className='no-data'>No scanned data</div>
            }
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onCancel}>Cancel</button>
            </div>
        </div>
    )
}

export default Scanning