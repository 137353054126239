import http from './http'

const getCategoryAmounts = async () => {
    const response = await http.get('dashboard')
    return response.data
}

const getFleetAdmins = async (search) => {
    search = search && search.trim()
    const response = await http.get('dashboard/fleetAdmins', { search })
    return response.data
}

const getUsers = async (search) => {
    search = search && search.trim()
    const response = await http.get('dashboard/users', { search })
    return response.data
}

const getAssists = async () => {
    const response = await http.get('dashboard/assists')
    return response.data
}

const getProblemProfiles = async () => {
    const response = await http.get('dashboard/problem')
    return response.data
}

const getOverdueProfiles = async () => {
    const response = await http.get('dashboard/overdue')
    return response.data
}

const getLocalAdmins = async (search) => {
    search = search && search.trim()
    const response = await http.get('dashboard/localAdmins', { search })
    return response.data
}

const getFleetManagers = async (search) => {
    search = search && search.trim()
    const response = await http.get('dashboard/fleetManagers', { search })
    return response.data
}

export default {
    getCategoryAmounts,
    getFleetAdmins,
    getUsers,
    getAssists,
    getProblemProfiles,
    getOverdueProfiles,
    getLocalAdmins,
    getFleetManagers
}
