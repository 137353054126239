export default {
    nextServiceDate: "Next Service",
    lastServiceDate: "Last Service",
    heavyLiftsPostPM: "Heavy Lifts (PPM)",
    normalLiftsPostPM: "Normal Lifts (PPM)",
    weighCyclesPostPM: "Weigh Cycles(PPM)",
    strapServiceDueIn: "Strap Life",
    batteryServiceDueIn: "Battery Life",
    totalHeavyLifts: "Heavy Lifts",
    totalNormalLifts: "Normal Lifts",
    weighCycles: "Weigh Cycles",
    totalChargeCycles: "Charge Cycles",
    totalChargeCyclesPostPM: "Charge Cycles (PPM)",
    totalLifts: "Total Lifts",
    totalPMLifts: "Total Lifts (PPM)",
    totalLiftsLastWeek: "Cycles Last Week",
    averageLiftsPerWeek: "Avg. Cycles Per Week"
}
