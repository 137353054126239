import React, { Component } from 'react'
import './snackbar.scss'

export default class Snackbar extends Component {
  message = ''
  style = null

  state = {
    isActive: false,
  }

  openSnackBar = (message = 'Something went wrong...', style = null) => {
    this.message = message
    this.style = style

    this.setState({ isActive: true }, () => {
      setTimeout(() => {
        this.setState({ isActive: false })
      }, 3000)
    })
  }

  render() {
    const { isActive } = this.state

    return (
      <div className='snackbar-container'>
        <div className={`snackbar ${isActive ? 'show' : ''}`} style={this.style}>
          {this.message}
        </div>
      </div>
    )
  }
}
