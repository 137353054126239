import React, { Component } from 'react'
import Slider from 'react-slick'
import './styles.scss'
import LoadingSpinner from '../LoadingSpinner/loadingSpinner'
import moment from 'moment'
import { toTitleCase } from '../../utils/toTitleCase'
import ResetPMPopUp from '../ResetPMPopUp/ResetPMPopUp'
import liftCycleHistoryNames from '../../constants/liftCycleHistoryNames'

const AdvancedDiagnosticLogData = (props) => {
  const { selectedProfile: { liftCycleHistory } } = props
  // const diagnosticsLogReadStatus = selectedProfile.diagnosticsLog.readStatus && selectedProfile.diagnosticsLog.readStatus.toLowerCase()
  // const liftOperationStateValue = selectedProfile.liftOperationState && selectedProfile.liftOperationState.v.toLowerCase()

  // if (diagnosticsLogReadStatus === 'stopped'
  //   || diagnosticsLogReadStatus === 'failed'
  //   || liftOperationStateValue === 'wait'
  //   || !Array.isArray(selectedProfile.diagnosticsLog)
  //   || !selectedProfile.diagnosticsLog.length) {
  //   return <h1 className='no-info'>No info...</h1>
  // }

  if (!liftCycleHistory || !liftCycleHistory.length) {
    return <h1 className='no-info'>No info...</h1>
  }

  return (
    <>
      {/* {
        selectedProfile.diagnosticsLog.map((i, idx) =>
          <div className='row' key={idx}>
            <div>{i.key || i.k}</div>
            <div className='value' style={{ color: i.color || i.c }}>{toTitleCase(i.value || i.v)}</div>
          </div>
        )
      } */}
      {
        liftCycleHistory.map((i, idx) => (
          <div className='row' key={`lift-cycle-history-item-${idx}`}>
            <div>{`${idx + 1}. ${toTitleCase(i.cycleType)}:`}</div>
            <div className='value'>{moment(i.updatedAt || i.createdAt).format('MM/DD/YYYY HH:mm')}</div>
          </div>
        ))
      }
    </>
  )
}

const ErrorLogData = (props) => {
  const { selectedProfile, deviceAnalyticsProps } = props
  const { profile } = selectedProfile
  // const diagnosticsLogReadStatus = selectedProfile.diagnosticsLog.readStatus && selectedProfile.diagnosticsLog.readStatus.toLowerCase()
  // const liftOperationStateValue = selectedProfile.liftOperationState && selectedProfile.liftOperationState.v.toLowerCase()

  // if (diagnosticsLogReadStatus === 'stopped'
  //   || diagnosticsLogReadStatus === 'failed'
  //   || liftOperationStateValue === 'wait'
  //   || !Array.isArray(selectedProfile.errorLog)) {
  //   return <h1 className='no-info'>No info...</h1>
  // }

  const items = deviceAnalyticsProps.map(name => {
    switch (name) {
      case 'totalPMLifts':
        return { name: liftCycleHistoryNames[name], value: profile.heavyLiftsPostPM + profile.normalLiftsPostPM }
      case 'totalLifts':
        return { name: liftCycleHistoryNames[name], value: profile.totalHeavyLifts + profile.totalNormalLifts }
      case 'averageLiftsPerWeek':
        return { name: liftCycleHistoryNames[name], value: Math.round((profile.totalHeavyLifts + profile.totalNormalLifts) / 52) } // totalLifts / 52
      case 'nextServiceDate':
      case 'lastServiceDate':
        const value = profile[name] ? moment(profile[name]).format('MM-DD-YYYY') : null
        return { name: liftCycleHistoryNames[name], value }
      default:
        return { name: liftCycleHistoryNames[name], value: profile[name] }
    }
  })
  return (
    <>
      {/* {
        selectedProfile.errorLog.map((i, idx) =>
          <div className='row' key={idx}>
            <div>{`${i.key || i.k}:`}</div>
            <div className='value' style={{ color: i.color || i.c }}>{toTitleCase(i.value || i.v)}</div>
          </div>
        )
      } */}
      {
        items.map((i, idx) => (
          <div className='row' key={`device-analytics-item-${idx}`}>
            <div>{`${i.name}:`}</div>
            <div className='value'>{i.value}</div>
          </div>
        ))
      }
    </>
  )
}

class DetailDeviceFeed extends Component {

  state = {
    resetPMModalOpen: false,
    lastDeviceFeedMessageTimeLabel: moment.utc(moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(moment(this.props.lastDeviceFeedMessageTime, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss"), // this.props.lastMessageTime
  }

  componentDidMount() {
    this.deviceFeedTimeInterval = setInterval(() => { this.setState({ lastDeviceFeedMessageTimeLabel: moment.utc(moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(moment(this.props.lastDeviceFeedMessageTime, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss") }) })
  }

  componentWillUnmount() {
    clearInterval(this.deviceFeedTimeInterval)
  }

  setResetPMModalOpen = (value) => {
    this.setState({ resetPMModalOpen: value })
  }

  render() {
    const {
      isSliding,
      sliderSettings,
      sliderRef,
      selectedProfile,
      exportExcelReportButtonDisabled,
      exportExcelReport,
      deviceAnalyticsProps
    } = this.props

    return (
      <>
        {
          this.state.resetPMModalOpen
            ? <ResetPMPopUp requestClose={() => this.setResetPMModalOpen(false)} />
            : null

        }
        <div id='mobile-content-slides'>
          {
            isSliding
              ? <Slider {...sliderSettings} ref={sliderRef}>
                <div className='col feed'>
                  {
                    selectedProfile.profile
                      ? <span className='update-label'>{`Last message retained: ${moment(selectedProfile.profile.lastMessageRetained).format('MMMM Do YYYY, h:mm:ss a')}`}</span>
                      : <></>
                  }
                  {
                    this.props.lastDeviceFeedMessageTime
                      ? <span className='update-label'>{`Last updated ${this.state.lastDeviceFeedMessageTimeLabel}`}</span>
                      : <></>
                  }
                  <div className='table'>
                    {
                      !selectedProfile.deviceFeed
                        ? <LoadingSpinner /> // <h1 className='no-info'>Waiting for info...</h1>
                        : Array.isArray(selectedProfile.deviceFeed) && selectedProfile.deviceFeed.length
                          ? selectedProfile.deviceFeed.map((i, idx) =>
                            <div className='row' key={idx}>
                              <div>{`${i.key || i.k}:`}</div>
                              <div className='value' style={{ color: i.color || i.c }}>{i.value || i.v}</div>
                            </div>)
                          : <h1 className='no-info'>No info...</h1>
                    }
                  </div>
                </div>
                <div className='col feed'>
                  <div className='button-aligner'>
                    <button className='btn orange small'
                      onClick={() => exportExcelReport()}
                      disabled={exportExcelReportButtonDisabled}>
                      <span>Export</span>
                    </button>
                  </div>
                  <div className='table'>
                    <AdvancedDiagnosticLogData selectedProfile={selectedProfile} />
                  </div>
                </div>
                <div className='col feed'>
                  <div className='button-aligner'>
                    <button className='btn orange small'
                      onClick={() => this.setResetPMModalOpen(true)}>
                      <span>Reset PM</span>
                    </button>
                  </div>
                  <div className='table'>
                    <ErrorLogData selectedProfile={selectedProfile} deviceAnalyticsProps={deviceAnalyticsProps} />
                  </div>
                </div>
              </Slider>
              : <>
                <div className='col'>
                  <h3>Device Feed</h3>
                  {
                    this.props.lastDeviceFeedMessageTime
                      ? <span className='update-label'>{`Last updated ${this.state.lastDeviceFeedMessageTimeLabel}`}</span>
                      : <></>
                  }
                  <div className='table'>
                    {
                      !selectedProfile.deviceFeed
                        ? <LoadingSpinner />
                        : Array.isArray(selectedProfile.deviceFeed) && selectedProfile.deviceFeed.length
                          ? selectedProfile.deviceFeed.map(
                            (i, idx) =>
                              <div className='row' key={idx}>
                                <div>{`${i.key || i.k}:`}</div>
                                <div className='value' style={{ color: i.color || i.c }}>{i.value || i.v}</div>
                              </div>)
                          : <h1 className='no-info'>No info...</h1>
                    }
                  </div>
                </div>
                <div className='col'>
                  <h3>Cycle History</h3>
                  <div className='button-aligner'>
                    <button className='btn orange small' onClick={() => exportExcelReport()}
                      disabled={exportExcelReportButtonDisabled}>
                      <span>Export</span>
                    </button>
                  </div>
                  <div className='table diag-log-table'>
                    <AdvancedDiagnosticLogData selectedProfile={selectedProfile} />
                  </div>
                </div>
                <div className='col'>
                  <h3>Device Analytics</h3>
                  <div className='button-aligner'>
                    <button className='btn orange small'
                      onClick={() => this.setResetPMModalOpen(true)}>
                      <span>Reset PM</span>
                    </button>
                  </div>
                  <div className='table diag-log-table'>
                    <ErrorLogData selectedProfile={selectedProfile} deviceAnalyticsProps={deviceAnalyticsProps} />
                  </div>
                </div>
              </>
          }
        </div>
      </>
    )
  }
}

export default DetailDeviceFeed
