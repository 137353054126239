import React from 'react'
import './styles.scss'
import CardBlock from '../../containers/CardBlock/cardBlock'
import AssignDeviceFlow from './AssignDeviceFlow/AssignDeviceFlow'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import c2dSvc from '../../services/c2dService'
import selectedProfileActions from '../../store/actions/selectedProfile'
import streamingActions from '../../store/actions/streaming'
import profileSvc from '../../services/productsService'
import authSvc from '../../services/auth'

const stateToProps = (state) => ({
    selectedProfile: state.selectedProfile
})

const dispatchToProps = {
    ...selectedProfileActions,
    ...streamingActions
}

const AssignDeviceCardBlock = (props) => {

    const goToMainView = () => {
        props.history.push(`/devices/${props.selectedProfile.profile.profileId}`)
    }

    const assignDevice = (assistId) => {
        return c2dSvc.sendData(assistId, { assignmentRequest: props.selectedProfile.profile.profileId })
    }

    const onAssignSuccess = () => {
        const {
            match: { params },
            fetchSelectedProfileData,
            fetchSelectedProfileServiceHistory,
            fetchProfileFeed,
            connectToStreamingSocket,
            sendRenewAdvancedDiagnosticsData
        } = props

        const { selectedProfileId } = params
        return Promise.all([
            fetchSelectedProfileData(selectedProfileId),
            fetchSelectedProfileServiceHistory(selectedProfileId),
            fetchProfileFeed(selectedProfileId)
        ]).then((data) => {
            console.log(data)
            const profile = data[0]

            // check if assistId exists
            // actually we definitely have assistId here however we check it for existance
            if (profile.assistId) {
                sendRenewAdvancedDiagnosticsData(profile.assistId, 'advancedDiagnosticsData')
                connectToStreamingSocket(profile.assistId, 1, authSvc.getSession().token.accessToken)
            }
        })
    }

    const getProfileInfo = () => {
        const {
            match: { params: { selectedProfileId } }
        } = props

        return profileSvc.fetchProfile(+selectedProfileId)
    }

    return (
        <CardBlock header='Assign Assist' linkTo={null}>
            <AssignDeviceFlow profile={props.selectedProfile.profile}
                toMainView={() => goToMainView()}
                assignDevice={assignDevice}
                onAssignSuccess={onAssignSuccess}
                getProfileInfo={getProfileInfo} />
        </CardBlock>
    )
}

export default connect(stateToProps, dispatchToProps)(withRouter(AssignDeviceCardBlock))
