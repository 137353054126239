import React from 'react'
import './styles.scss'
import LoadingSpinner from '../LoadingSpinner/loadingSpinner'
import moment from 'moment'
import { MdDone } from 'react-icons/md'
import { BsExclamationTriangle , BsExclamation } from "react-icons/bs";
import userTypes from '../../utils/userTypes'

const iconState = {
  ok: 0,
  warning: 1,
  danger: 2
}

const DetailSystemInformation = (props) => {
  const { profile, loading, displaySpinner } = props

  const getIconByValue = (value) => {
    if (value === iconState.ok) {
      return <MdDone className='third-party-icon green' />
    }

    if (value === iconState.warning) {
      return <BsExclamationTriangle className='third-party-icon yellow' />
    }

    if (value === iconState.danger) {
      return <BsExclamation className='third-party-icon red' />
    }

    return null
  }

  const getSubtitleByUserType = (userType) => {
    if (!userType) {
      return
    }

    const formatted = userType.trim().toLowerCase()
    let result = 'Client'

    if (formatted === userTypes.facility) {
      result = 'Location'
    }

    return result
  }

  const getDescriptionByUserType = (userType) => {
    if (!userType) {
      return
    }

    const formatted = userType.trim().toLowerCase()

    switch (formatted) {
      case userTypes.facility:
        return (
          <p>
            {`${profile.clientRaw.companyName},`}
            <br />
            {`${profile.clientRaw.unit},`}
            <br />
            {`Room ${profile.clientRaw.roomNumber}`}
          </p>
        )
      case userTypes.individual:
        return (
          <p>
            {`${profile.clientRaw.firstName} ${profile.clientRaw.lastName}${profile.clientRaw.streetAddress ? ',' : ''}`}
            <br />
            {`${profile.clientRaw.streetAddress || ''}${profile.clientRaw.city ? ',' : ''}`}
            <br />
            {`${profile.clientRaw.city || ''}${profile.clientRaw.state ? ',' : ''} ${profile.clientRaw.state || ''}`}
          </p>
        )
      default:
        return null
    }
  }
  
  return (
    <div className='DetailSystemInformation'>
      <div className='col'>
        {
          !profile || (loading && displaySpinner)
            ? <LoadingSpinner />
            : <>
              <div className='top-info'>
                <div className='device-title'>{profile.serialNumber}</div>
                <div className='text'>
                  <span className='h6'>Model</span>
                  <p>{profile.modelNumber}</p>
                  <span className='h6'>{getSubtitleByUserType(profile.clientRaw.userType)}</span>
                  {getDescriptionByUserType(profile.clientRaw.userType)}
                </div>
              </div>
              <div className='table'>
                <div className={`row ${profile.productionDate ? '' : 'not-available'}`}>
                  <div className='name'>Production Date:</div>
                  <div className='value'>{profile.productionDate ? `${moment(profile.productionDate).format('DD/MM/YYYY')}` : ''}</div>
                </div>
                <div className={`row ${profile.installationDate ? '' : 'not-available'}`}>
                  <div className='name'>Install Date:</div>
                  <div className='value'>{profile.installationDate ? `${moment(profile.installationDate).format('DD/MM/YYYY')}` : ''}</div>
                </div>
                <div className={`row ${profile.warrantyCoverage ? '' : 'not-available'}`}>
                  <div className='name'>Warranty Coverage:</div>
                  <div className='value'>{`${profile.warrantyCoverage ? profile.warrantyCoverage : ''}`}</div>
                </div>
                <div className={`row ${profile.warrantyEndDate ? '' : 'not-available'}`}>
                  <div className='name'>Warranty End Date:</div>
                  <div className='value'>{profile.warrantyEndDate ? `${moment(profile.warrantyEndDate).format('DD/MM/YYYY')}` : ''}</div>
                </div>
                <div className={`row ${typeof profile.systemHealth === 'number' ? '' : 'not-available'}`}>
                  <div className='name'>System Health:</div>
                  <div className='value'>
                    {
                      typeof profile.systemHealth === 'number'
                        ? getIconByValue(profile.systemHealth)
                        : 'N/A'
                    }
                  </div>
                </div>
                <div className={`row ${typeof profile.chargerHealth === 'number' ? '' : 'not-available'}`}>
                  <div className='name'>Charger Health:</div>
                  <div className='value'>
                    {
                      typeof profile.chargerHealth === 'number'
                        ? getIconByValue(profile.chargerHealth)
                        : 'N/A'
                    }
                  </div>
                </div>
                <div className={`row ${typeof profile.batteryHealth === 'number' ? '' : 'not-available'}`}>
                  <div className='name'>Battery Health:</div>
                  <div className='value'>
                    {
                      typeof profile.batteryHealth === 'number'
                        ? getIconByValue(profile.batteryHealth)
                        : 'N/A'
                    }
                  </div>
                </div>
                <div className={`row no-border`}>
                  <div className='name' />
                  <div className='value' />
                </div>
              </div>
            </>
        }
      </div>
    </div>

  )
}

export default DetailSystemInformation
