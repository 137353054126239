import {
    FETCH_EDITABLE_USER_ERROR,
    FETCH_EDITABLE_USER_INFO,
    FETCH_EDITABLE_USER_INFO_SUCCESS,
    CLEAR_EDITABLE_USER,
    SAVE_EDITABLE_USER
} from '../types/editableUser'
import usersService from '../../services/usersService'
import openProfileService from '../../services/openProfileService'
import { reset } from 'redux-form'

const saveUser = (userId, userData) => async dispatch => {
    try {
        const profileData = await openProfileService.getOpenProfileBySerialNumber(userData.serialNumber)

        validateUserData(profileData.client, userData)

        let user = null

        if (userId === 'new') {

            const newUser = {
                firstName: userData.firstName,
                lastName: userData.lastName,
                serialNumber: userData.serialNumber,
                isActive: false,
                email: userData.email,
                role: 'user',
                password: userData.password,
                zip: userData.zip
            }

            user = await usersService.createUser(newUser)
        } else {
            const editedUser = {
                id: userId,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email
            }

            user = await usersService.updateUser(editedUser)
        }

        dispatch({ type: SAVE_EDITABLE_USER, user })
    } catch (error) {
        dispatch({ type: FETCH_EDITABLE_USER_ERROR, error })
        throw error
    }
}

const selectUserInfo = (userId) => async dispatch => {
    try {
        dispatch({ type: FETCH_EDITABLE_USER_INFO })
        const user = await usersService.getUser(userId)

        if (user.role !== 'user') {
            throw new Error('Selected user is not a user by role.')
        }

        dispatch({ type: FETCH_EDITABLE_USER_INFO_SUCCESS, user })
    } catch (error) {
        dispatch({ type: FETCH_EDITABLE_USER_ERROR, error })
        throw error
    }
}

const clearUserInfo = () => async dispatch => {
    dispatch({ type: CLEAR_EDITABLE_USER })
}

const resetEditableUserFormValues = () => async dispatch => {
    dispatch(reset('editableUserInformationForm'))
}

const validateUserData = (clientData, userData) => {
    if (clientData.firstName !== userData.firstName) {
        throw new Error('First Name doesn\'t match the First Name of profile\'s client.')
    }

    if (clientData.lastName !== userData.lastName) {
        throw new Error('Last Name doesn\'t match the Last Name of profile\'s client.')
    }

    if (clientData.zip !== userData.zip) {
        throw new Error('Zip Code doesn\'t match the Zip Code of profile\'s client.')
    }
}

export default {
    saveUser,
    selectUserInfo,
    clearUserInfo,
    resetEditableUserFormValues
}
