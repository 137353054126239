import React from 'react'
import './styles.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'

const DetailServiceHistoryItem = (props) => {
  const { report, linkToDetail, repairTypes, number } = props

  return (
    <div className='detail-service-history-item'>
      <Link to={linkToDetail} />
      <div className='info'>
        <div className='work-types'>
          {
            report.repairs.ids.map(repairId => <span key={`${report.serviceReportId}-${repairId}`} className='work-type'>{repairTypes.find(t => t.repairTypeId === repairId).name}</span>)
          }
        </div>
        <div className='info'>
          <span className='number'>{`${number}. `}</span>
          <span className='date'>{moment(report.reportDate).format('LL')}</span>
        </div>
      </div>
    </div>
  )
}

export default DetailServiceHistoryItem
