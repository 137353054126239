import React from 'react'
import './styles.scss'

const Success = (props) => {
    return (
        <div className='Success'>
            <div className='description'>
                Assignment completed successfully. <br />
                Check device feed for data.
            </div>
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onContinue}>Next</button>
            </div>
        </div>
    )
}

export default Success
