import React, { Component } from 'react'
import SearchDeviceForm from '../../components/SearchDeviceForm/searchDeviceForm'
import SearchDeviceList from '../SearchDeviceList/searchDeviceList'
import { connect } from 'react-redux'
import LoadingSpinner from '../../components/LoadingSpinner/loadingSpinner'
import usersActions from '../../store/actions/users'
import authService from '../../services/auth'
import HandicareAnalyticsView from '../../components/HandicareAnalyticsView/handicareAnalyticsView'
import dashboardActions, { resetSearched, setDashboardCategory, setSearchValue, clearDashboardItems } from '../../store/actions/dashboard'
import dashboardUrls from '../../constants/dashboardUrls'
import userRole from '../../constants/userRole'
import { SEARCH_USER_REGEX } from '../../utils/validators'

const stateToProps = (state) => ({
  devices: state.products,
  snackbarRef: state.common.snackbarReference,
  categoryAmounts: state.dashboard.categoryAmounts,
  selectedCategory: state.dashboard.selectedCategory,
  searchedCategory: state.dashboard.searchedCategory,
  items: state.dashboard.items,
  searchValue: state.dashboard.searchValue,
  searched: state.dashboard.searched,
  isLoading: state.dashboard.isLoading
})

const dispatchToProps = {
  sendSignUpInfo: usersActions.sendSignUpInfo,
  ...dashboardActions
}

class DeviceListPage extends Component {

  getItemsByCategory = (category, searchValue) => {
    switch (category) {
      case dashboardUrls.assists:
        this.props.getAssists()
        break
      case dashboardUrls.problem:
        this.props.getProblemProfiles()
        break
      case dashboardUrls.overdue:
        this.props.getOverdueProfiles()
        break
      case dashboardUrls.users:
        this.props.getUsers(searchValue)
        break
      case dashboardUrls.fleetAdmins:
        this.props.getFleetAdmins(searchValue)
        break
      case dashboardUrls.fleetManagers:
        this.props.getFleetManagers(searchValue)
        break
      case dashboardUrls.localAdmins:
        this.props.getLocalAdmins(searchValue)
        break
      default:
        break
    }
  }

  componentDidMount() {
    if (authService.getCurrentUserRole() === userRole.user) {
      this.props.profilesByUser()
      return
    }
    const {
      searchedCategory,
      searchValue,
      searched
    } = this.props

    if (searchValue || searched) {
      this.getItemsByCategory(searchedCategory, searchValue)
    }

    this.props.getCategoryAmounts()
  }

  handleSearchButtonClick = (searchValue) => {
    const {snackbarRef, dispatch, selectedCategory} = this.props
    
    if (SEARCH_USER_REGEX.test(searchValue)) {
      snackbarRef.current.openSnackBar('Search string contains forbidden symbols: "&" or "#"', { backgroundColor: '#a83256' })
      return
    }
    
    this.getItemsByCategory(selectedCategory, searchValue)
    dispatch(setSearchValue(searchValue))
  }

  sendSignUpInfo = async (userId) => {
    return await this.props.sendSignUpInfo(userId)
  }

  clearDashboardSearchResults = () => {
    this.props.dispatch(resetSearched())
    this.props.dispatch(clearDashboardItems())
  }

  getAnalyticsViewHidden = () => {
    if (authService.getCurrentUserRole() === userRole.user) {
      return true
    }

    return this.props.items.length
      || this.props.searched
  }

  handleCategorySelect = (category) => {
    const {
      dispatch,
      setSearchValue
    } = this.props

    dispatch(setDashboardCategory(category))
    setSearchValue('')
    this.clearDashboardSearchResults()
  }

  render() {
    return (
      <>
        {
          authService.getCurrentUserRole() === userRole.user
            ? <></>
            : <SearchDeviceForm onSubmit={s => this.handleSearchButtonClick(s)}
              onSelectCategory={cat => this.handleCategorySelect(cat)}
              selectedCategory={this.props.selectedCategory}
              setSearchValue={val => this.props.setSearchValue(val)}
              searched={this.props.searched}
              resetSearched={() => this.clearDashboardSearchResults()} />
        }
        {
          this.getAnalyticsViewHidden()
            ? <></>
            : <HandicareAnalyticsView buttonsData={this.props.categoryAmounts}
              getLocalAdmins={() => this.props.getLocalAdmins()}
              getFleetAdmins={() => this.props.getFleetAdmins()}
              getFleetManagers={() => this.props.getFleetManagers()}
              getUsers={() => this.props.getUsers()}
              getAssists={() => this.props.getAssists()}
              getProblemProfiles={() => this.props.getProblemProfiles()}
              getOverdueProfiles={() => this.props.getOverdueProfiles()} />
        }

        {
          this.props.isLoading
            ? <LoadingSpinner />
            : <SearchDeviceList items={this.props.items}
              types={this.props.devices.types}
              searched={this.props.searched}
              sendSignUpInfo={this.sendSignUpInfo}
              snackbar={this.props.snackbarRef} />
        }
      </>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(DeviceListPage)
