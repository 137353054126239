import {
    FETCH_USERS,
    FETCH_USERS_ERROR,
    FETCH_USERS_SUCCESS,
    CLEAR_USERS_LIST
} from '../types/users'

const initialState = {
    items: [],
    searched: false,
    isLoading: false,
    error: null
}

export default (state = initialState, payload) => {
    switch (payload.type) {
        case FETCH_USERS:
            return { ...state, isLoading: true }
        case FETCH_USERS_ERROR:
            return { ...state, isLoading: false, error: payload.error }
        case FETCH_USERS_SUCCESS:
            return { ...state, isLoading: false, items: payload.items, error: null, searched: true }
        case CLEAR_USERS_LIST:
            return { ...state, items: [], isLoading: false, error: null, searched: false }
        default:
            return state
    }
}
