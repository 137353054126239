import {
  FETCH_PRODUCT_TYPES
} from '../types/products'
import profilesService from '../../services/productsService'

const fetchProfileTypes = () => async dispatch => {
  try {
    const profileTypes = await profilesService.fetchProfileTypes()
    dispatch({ type: FETCH_PRODUCT_TYPES, types: profileTypes })
  } catch (error) {
    console.log(error)
  }
}

export default {
  fetchProfileTypes
}
