import React from 'react'
import './styles.scss'

const LoadingSpinner = props => {
  return (
    <div className='loader'>Loading...</div>
  )
}

export default LoadingSpinner
