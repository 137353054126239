import http from './http'

const getModelNumbers = async () => {
    const response = await http.get(`modelNumbers`)
    return response.data
}

export default {
    getModelNumbers
}
