import {
  FETCH_SELECTED_PROFILE_DATA,
  FETCH_SELECTED_PROFILE_DATA_SUCCESS,
  FETCH_SELECTED_PROFILE_DATA_ERROR,

  FETCH_SELECTED_PROFILE_SERVICE_HISTORY,
  FETCH_SELECTED_PROFILE_SERVICE_HISTORY_ERROR,
  FETCH_SELECTED_PROFILE_SERVICE_HISTORY_SUCCESS,

  FETCH_SELECTED_PROFILE_FEED,
  FETCH_SELECTED_PROFILE_FEED_SUCCESS,
  CLEAR_SELECTED_PROFILE,
  SET_EXPORT_REPORT_BUTTON_DISABLED,
  SET_SELECTED_PROFILE_MODEL_NUMBERS,
  SET_LIFT_CYCLE_HISTORY
} from '../types/selectedProfile'
import {
  SAVE_SELECTED_REPORT
} from '../types/serviceHistoryItem'
import {
  SET_BATTERY_STATE_DATA,
  SET_DEVICE_FEED_DATA,
  SET_DIAGNOSTICS_LOG_DATA,
  SET_ERROR_LOG_DATA,
  SET_LIFT_OPERATION_STATE_DATA,
  NO_MESSAGE_RECIEVED,
  SET_APP_CONNECTED_INTERVAL,
  C2D_CONNECTED_MESSAGE,
  SET_DISCONNECTED_TIMEOUT,
  MESSAGE_RECEIVED,
  SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING
} from '../types/streaming'

const initialState = {
  profile: null,
  repairTypes: [],
  reports: [],
  isSystemInfoLoading: false,
  displaySystemInfoSpinner: true,
  isServiceHistoryLoading: true,
  isDeviceFeedLoading: true,
  deviceFeed: null,
  lastDeviceFeedMessageTime: null,
  liftOperationState: null,
  liftOperationStateMessageReceived: false,
  messageCounter: 0,
  messageReceived: false, // we receive at least 1 message from socket
  isConnected: true, // if wasnt received any message in 15 sec then set to false
  disconnectedTimeout: null,
  appConnectedInterval: null,
  batteryState: null,
  diagnosticsLog: {},
  diagnosticsLogTimeout: null,
  advDiagnosticDataAwaiting: false,
  lastDiagnosticsLogMessageTime: null,
  errorLog: {},
  error: null,
  lastErrorLogMessageTime: null,
  exportExcelReportButtonDisabled: false,
  modelNumbers: [],
  liftCycleHistory: []
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_SELECTED_PROFILE_DATA:
      return { ...state, isSystemInfoLoading: true }
    case FETCH_SELECTED_PROFILE_DATA_SUCCESS:
      return { ...state, isSystemInfoLoading: false, profile: payload.profile, displaySystemInfoSpinner: false }
    case FETCH_SELECTED_PROFILE_DATA_ERROR:
      return { ...state, isSystemInfoLoading: false, error: payload.error, displaySystemInfoSpinner: true }
    case FETCH_SELECTED_PROFILE_SERVICE_HISTORY:
      return { ...state, isServiceHistoryLoading: true }
    case FETCH_SELECTED_PROFILE_SERVICE_HISTORY_SUCCESS:
      return { ...state, isServiceHistoryLoading: false, reports: payload.reports, repairTypes: payload.repairTypes }
    case FETCH_SELECTED_PROFILE_SERVICE_HISTORY_ERROR:
      return { ...state, isServiceHistoryLoading: false, error: payload.error }
    case SAVE_SELECTED_REPORT:
      const reportIdx = state.reports.findIndex(r => r.serviceReportId === payload.report.serviceReportId)
      let newReports = []
      if (reportIdx === -1) {
        newReports = [payload.report, ...state.reports]
      } else {
        newReports = [
          ...state.reports.slice(0, reportIdx),
          payload.report,
          ...state.reports.slice(reportIdx + 1)
        ]
      }
      return { ...state, reports: newReports }
    case FETCH_SELECTED_PROFILE_FEED:
      return { ...state, isDeviceFeedLoading: true }
    case FETCH_SELECTED_PROFILE_FEED_SUCCESS:
      return {
        ...state,
        isDeviceFeedLoading: false,
        deviceFeed: payload.data.liveView,
        liftOperationState: payload.data.liftOperationState,
        diagnosticsLog: {},
        errorLog: {},
        batteryState: payload.data.batteryState
      }
    case SET_DEVICE_FEED_DATA:
      state.appConnectedInterval && clearInterval(state.appConnectedInterval)
      return { ...state, deviceFeed: payload.data, lastDeviceFeedMessageTime: new Date() }
    case SET_BATTERY_STATE_DATA:
      state.appConnectedInterval && clearInterval(state.appConnectedInterval)
      return { ...state, batteryState: payload.data }
    case SET_DIAGNOSTICS_LOG_DATA:
      state.appConnectedInterval && clearInterval(state.appConnectedInterval)
      state.diagnosticsLogTimeout && clearTimeout(state.diagnosticsLogTimeout)

      return {
        ...state,
        diagnosticsLogTimeout: null,
        diagnosticsLog: payload.data,
        lastDiagnosticsLogMessageTime: new Date()
      }
    case SET_ERROR_LOG_DATA:
      state.appConnectedInterval && clearInterval(state.appConnectedInterval)
      return { ...state, errorLog: payload.data, lastErrorLogMessageTime: new Date() }
    case SET_LIFT_OPERATION_STATE_DATA:
      state.appConnectedInterval && clearInterval(state.appConnectedInterval)
      return { ...state, liftOperationState: payload.data, liftOperationStateMessageReceived: true, }
    case C2D_CONNECTED_MESSAGE:
      return { ...state, messageCounter: state.messageCounter + 1 }
    case MESSAGE_RECEIVED: // ==================================================
      return { ...state, messageReceived: true, isConnected: true, messageCounter: 0 }
    case NO_MESSAGE_RECIEVED:
      return { ...state, isConnected: false }
    case SET_APP_CONNECTED_INTERVAL:
      if (state.appConnectedInterval) {
        clearInterval(state.appConnectedInterval)
      }
      return { ...state, appConnectedInterval: payload.appConnectedInterval }
    case SET_DISCONNECTED_TIMEOUT:
      if (state.disconnectedTimeout) {
        clearTimeout(state.disconnectedTimeout)
      }

      return { ...state, disconnectedTimeout: payload.disconnectedTimeout, isConnected: !!payload.disconnectedTimeout }
    case CLEAR_SELECTED_PROFILE:
      if (state.appConnectedInterval) {
        clearInterval(state.appConnectedInterval)
      }
      return initialState
    case SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING:
      return { ...state, advDiagnosticDataAwaiting: payload.value }
    case SET_EXPORT_REPORT_BUTTON_DISABLED:
      return { ...state, exportExcelReportButtonDisabled: payload.disabled }
    case SET_SELECTED_PROFILE_MODEL_NUMBERS:
      return { ...state, modelNumbers: payload.items }
    case SET_LIFT_CYCLE_HISTORY:
      return { ...state, liftCycleHistory: payload.items }
    default:
      return state
  }
}
