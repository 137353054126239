import React from 'react'
import './styles.scss'

const TryAgain = (props) => {
    return (
        <div className='TryAgain'>
            <div className='message'>
                The password entered is incorrect. Please try again.
            </div>
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onTryAgain}>Try Again</button>
            </div>
        </div>
    )
}

export default TryAgain
