import React from 'react'

export default (props) => {
    const { type, meta: { error, touched }, input, label, displayErrors, maxLength } = props
    const hasError = (displayErrors || touched ) && error
    return <>
        <label htmlFor={input.name}>{label}</label>
        {hasError && <div className='validation-message'>{error}</div>}
        <input type={type || 'text'}
            placeholder={type === 'password' ? 'Password must contain 1 capital letter, 1 digit, 1 special character, and must be at least 8 characters long' : null}
            id={input.name}
            maxLength={maxLength || 40}
            {...input} />
    </>
}
