import React from 'react'
import './styles.scss'

const CheckSwitcher = (props) => {
    // checked value needs to be passed
    return (
        <input className='CheckSwitcher' id={props.id} type='checkbox' value={props.value} onChange={props.onChange}></input>
    )
}

export default CheckSwitcher
