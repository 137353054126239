import React, { useState } from 'react'
import './styles.scss'

const EnterSignUpCode = (props) => {

    const [signUpCode, setSignUpCode] = useState('')

    const { onSubmit, onCancel } = props

    const handleSubmit = (event) => {
        event.preventDefault()
        onSubmit(signUpCode)
    }

    const handleCancel = () => {
        onCancel()
    }

    return (
        <div className='EnterSignUpCode'>
            <form onSubmit={handleSubmit}>
                <h4>Enter Sign-Up Code or Serial Number</h4>
                <input onChange={e => setSignUpCode(e.target.value)} value={signUpCode} type='text' />
                <div className={`help-label ${!props.isCodeValid ? 'invalid' : ''}`}>
                    {
                        props.isCodeValid
                        ? 'Enter the code provided by Handicare or your Fleet Admin.'
                        : 'Incorrect Sign-Up Code. Contact Handicare or your Fleet Admin to retrieve a new code.'
                    }
                </div> {/** add .invalid class when need orange label */}
                <input type='submit' disabled={!signUpCode || props.isCodeValidating} value='Enter' className={`btn yellow big-radius shadow`} />
                <input type='button' disabled={props.isCodeValidating} value='Cancel' className={`btn gray-m big-radius shadow`} onClick={handleCancel} />
            </form>
        </div>
    )
}

export default EnterSignUpCode
