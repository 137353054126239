import React from 'react'
import CardBlock from '../CardBlock/cardBlock'
import DetailSystemInformation from '../../components/DetailSystemInformation/detailSystemInformation'
import { useSelector } from 'react-redux'

export default (props) => {
  const selectedProfile = useSelector(state => state.selectedProfile)

  const linkBack = props.match.url.substring(0, props.match.url.lastIndexOf('/'))

  return (
    <CardBlock header='System Information' linkTo={linkBack}>
      <DetailSystemInformation profile={selectedProfile.profile} loading={selectedProfile.isSystemInfoLoading} displaySpinner={selectedProfile.displaySystemInfoSpinner} />
    </CardBlock>
  )
}