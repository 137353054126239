import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { MdEmail } from "react-icons/md"
import './styles.scss'
import { FaUser, FaUsers } from 'react-icons/fa'
import userTypes from '../../utils/userTypes'

const bgColors = {
  fleetAdmin: '#EEC600',
  fleetManager: '#46919D',
  user: '#469D7C',
  localAdmin: '#7A10D3'
}
const avaTitles = {
  fleetAdmin: 'FA',
  fleetManager: 'FM',
  user: 'U',
  localAdmin: 'RA'
}
const titles = {
  fleetAdmin: 'Fleet Admin',
  fleetManager: 'Fleet Manager',
  user: 'User',
  localAdmin: 'Regional Admin'
}

class SearchDeviceList extends Component {

  state = {
    signUpInfoExpectants: []
  }

  selectProfile = (profile) => {
    this.props.history.push(`/devices/${profile.profileId}`)
  }

  getDeviceImageBackgroundColor = (device) => {
    return device.assistId
      ? (
        device.is_problem
          || device.is_overdue
          ? '#F58D8D'
          : '#FFF'
      )
      : null
  }

  getDeviceImageBorder = (device) => {
    return device.is_problem
      || device.is_overdue
      ? '2px solid #A90000'
      : '2px solid #fff'
  }

  handleSendSignUpInfo = (userId) => {
    if (this.state.signUpInfoExpectants.indexOf(userId) !== -1) {
      return
    }

    this.setState((state) => {
      return {
        signUpInfoExpectants: [...state.signUpInfoExpectants, userId]
      }
    })

    this.props.sendSignUpInfo(userId)
      .then(() => {
        this.setState((state) => {
          return {
            signUpInfoExpectants: state.signUpInfoExpectants.filter(id => id !== userId)
          }
        })
        this.props.snackbar.current.openSnackBar('Email was sent', { backgroundColor: '#469D7C' })
      })
      .catch(e => {
        this.setState((state) => {
          return {
            signUpInfoExpectants: state.signUpInfoExpectants.filter(id => id !== userId)
          }
        })
        this.props.snackbar.current.openSnackBar(e.message, { backgroundColor: '#a83256' })
      })
  }

  getSignUpInfoIconStyle = (userId) => {
    if (this.state.signUpInfoExpectants.indexOf(userId) !== -1) {
      return { color: 'grey', hover: null }
    } else {
      return null
    }
  }

  render() {
    const { items, searched } = this.props

    const content = items.length <= 0
      ? <div className='mock-background'>
        {
          searched
            ? <h1>No results found...</h1>
            : <></>
        }
      </div>
      : <div id='results'>
        <div className='row head'>
          <div className='container'>
            <div className='col name'>Name</div>
            <div className='col device'>
              {
                items[0].clientRaw !== undefined
                  ? 'Device'
                  : 'Role'
              }
            </div>
            <div className='col serial'>
              {
                items[0].clientRaw !== undefined
                  ? 'Serial Number'
                  : 'Phone Number'
              }
            </div>
          </div>
        </div>
        {
          items.map(i => {
            // if has field clientRaw then it is profile
            if (i.clientRaw !== undefined) {
              const profileTypeName = this.props.types.find(t => i.profileTypeId === t.profileTypeId).name
              const profileTypeImg = require(`../../assets/img/deviceTypes/${profileTypeName}.png`)
              const assistIdInfo = i.apName
                ? `Assist ${i.apName.toString().slice(6, 12)}`
                : 'No Assist Assigned'

              return (
                <div className={i.assistId ? 'row selectible-profile' : 'row'} key={i.profileId} onClick={() => { this.selectProfile(i) }} >
                  <div className='container'>
                    <div className='col name'>
                      <Link to={`/profile/${i.profileId}`} onClick={(e) => e.stopPropagation()}>
                        {
                          i.clientRaw && i.clientRaw.userType === userTypes.facility
                            ? <>
                              <div className='user-icon-container'><FaUsers className='user-icon big-user-icon' /></div>
                              <div className='user-name'>
                                <div><strong>{i.clientRaw && i.clientRaw.companyName}</strong></div>
                                <div>{i.clientRaw && i.clientRaw.unit},  {i.clientRaw && i.clientRaw.roomNumber}</div>
                              </div>
                            </>
                            : <>
                              <div className='user-icon-container'><FaUser className='user-icon' /></div>
                              <div className='user-name'>
                                <div><strong>{i.clientRaw && i.clientRaw.lastName}</strong></div>
                                <div>{i.clientRaw && i.clientRaw.firstName}</div>
                              </div>
                            </>
                        }
                      </Link>
                    </div>
                    <div className='col device'>
                      <span className='device-img'
                        style={{
                          backgroundImage: `url(${profileTypeImg})`,
                          backgroundColor: this.getDeviceImageBackgroundColor(i),
                          border: this.getDeviceImageBorder(i)
                        }} />
                      <span className='text-container'>
                        <strong>{`${i.modelNumber}`}</strong>
                        <br />{assistIdInfo}
                      </span>
                    </div>
                    <div className='col serial'>
                      <span className='text-container'>{i.serialNumber}</span>
                    </div>
                  </div>
                </div>
              )
            } else {
              const descriptions = {
                fleetAdmin: <div className='sign-up-code-block'><div className='sign-up-code'>{i.signUpCode}</div> <MdEmail className='message-icon' onClick={() => this.handleSendSignUpInfo(i.id)} style={this.getSignUpInfoIconStyle(i.id)} /></div>, // ===============
                fleetManager: i.companyName,
                user: i.email,
                localAdmin: i.email
              }

              const namesPosition = i.lastName && i.firstName
                ? <>
                  <div><strong>{i.lastName}</strong></div>
                  <div>{i.firstName}</div>
                </>
                : <div>No user name</div>

              const names = {
                fleetAdmin: i.companyName ? <div><strong>{i.companyName}</strong></div> : namesPosition,
                fleetManager: namesPosition,
                user: namesPosition,
                localAdmin: namesPosition
              }

              const editUrls = {
                fleetAdmin: `/fleetAdmin/${i.id}`,
                fleetManager: `/fleetManager/${i.id}`,
                user: '',
                localAdmin: `/localAdmin/${i.id}`
              }

              return (
                <div className='row' key={i.id}>
                  <div className='container'>
                    <div className='col name'>
                      <Link to={editUrls[i.role]} onClick={(e) => e.stopPropagation()}>
                        <div className='user-name'>
                          {names[i.role]}
                        </div>
                      </Link>
                    </div>
                    <div className='col device'>
                      <span className='ava-title' style={{ backgroundColor: bgColors[i.role] }}>
                        {avaTitles[i.role]}
                      </span>
                      <span className='text-container'>
                        <strong>{titles[i.role]}</strong>
                        <br />{descriptions[i.role]}
                      </span>
                    </div>
                    <div className='col serial'>
                      <span className='text-container'>{i.phone}</span>
                    </div>
                  </div>
                </div>
              )
            }
          })
        }

        <div className='row last'>
          <div className='container'>
            <div className='col name' />
            <div className='col device' />
            <div className='col serial' />
          </div>
        </div>
      </div>

    return (
      <div id='search-results'>
        {content}
      </div>
    )
  }
}

export default withRouter(SearchDeviceList)
