import React from 'react'
import './styles.scss'
import { Redirect, withRouter } from 'react-router-dom'
import { Field, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'
import { connect } from 'react-redux'
import PhoneInput from '../../PhoneInput/phoneInput'
import renderInput from '../../../utils/renderInput'
import validators from '../../../utils/validators'
import forgotPassword from '../../../services/forgotPassword'
import usersService from '../../../services/usersService'
import editableLocalAdminActions, { setEditableLocalAdminInfo } from '../../../store/actions/editableLocalAdmin'
import countryList from 'country-list'
import DeleteConfirmationDialog from '../../DeleteConfirmationDialog/deleteConfirmationDialog'
import renderSelect from '../../../utils/renderSelect'
import CountryDefaultOption from '../../CountryDefaultOption/CountryDefaultOption'

class EditableLocalAdminInformationForm extends React.Component {

    state = {
        backClicked: false,
        displayErrors: false,
        isEditable: false,
        buttonsDisabled: true,
        showDeleteConfirmationDialog: false
    }

    componentDidMount() {
        const { updateTitle } = this.props

        if (this.props.userId !== 'new') {
            this.props.selectLocalAdminInfo(this.props.userId)
                .then(() => {
                    updateTitle(`${this.props.editableLocalAdmin.data.lastName} ${this.props.editableLocalAdmin.data.firstName}`)
                    this.setState({
                        buttonsDisabled: false
                    })
                })
        } else {
            this.setState({
                buttonsDisabled: false,
                isEditable: true
            })
        }
    }

    componentWillUnmount() {
        this.props.clearLocalAdminInfo()
    }

    setDisplayErrors = (val) => {
        this.setState({ displayErrors: val })
    }

    setBackClicked = (val) => {
        this.setState({ backClicked: val })
    }

    handleBackClicked = () => {
        if (this.props.userId === 'new') {
            this.props.history.push('/devices')
        } else {
            if (this.state.isEditable) {
                this.setState({ isEditable: false })
            } else {
                this.setBackClicked(true)
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const { currentFormValues, currentSyncErrors, saveLocalAdmin, snackbarRef } = this.props

        this.setState({ buttonsDisabled: true })

        for (const prop in currentSyncErrors) {
            if (currentSyncErrors.hasOwnProperty(prop)) {
                this.setDisplayErrors(true)
                this.setState({ buttonsDisabled: false })
                return
            }
        }

        saveLocalAdmin(this.props.userId, currentFormValues)
            .then(() => {
                this.setState({ buttonsDisabled: false, isEditable: false })
                this.props.resetEditableLocalAdminFormValues()
                this.props.history.push(`/localAdmin/${this.props.editableLocalAdmin.userId}`)
                this.props.selectLocalAdminInfo(this.props.editableLocalAdmin.userId)
                    .then(() => {
                        this.props.updateTitle(`${this.props.editableLocalAdmin.data.lastName} ${this.props.editableLocalAdmin.data.firstName}`)
                        snackbarRef.current.openSnackBar('Regional Admin has been saved', { backgroundColor: '#469D7C' })
                    })
            })
            .catch((e) => {
                snackbarRef.current.openSnackBar(e.message, { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    handleBlockUnblock = (event) => {
        event.preventDefault()

        const { snackbarRef, editableLocalAdmin, dispatch } = this.props

        const snackBarText = !editableLocalAdmin.data.isActive
            ? 'Regional Admin was activated successfully.'
            : 'Regional Admin was deactivated successfully.'

        this.setState({ buttonsDisabled: true })

        usersService.updateUser({ id: this.props.userId, isActive: !editableLocalAdmin.data.isActive })
            .then((user) => {
                dispatch(setEditableLocalAdminInfo(user))
                snackbarRef.current.openSnackBar(snackBarText, { backgroundColor: '#469D7C' })
                this.setState({ buttonsDisabled: false })
            })
            .catch(() => {
                snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    handleDelete = (event) => {
        event.preventDefault()
        this.setState({ showDeleteConfirmationDialog: true })
    }

    handleDeleteConfirmationClick = (val) => {
        if (val) {
            const { snackbarRef } = this.props

            this.setState({ buttonsDisabled: true })

            usersService.deleteUser(this.props.userId)
                .then(() => {
                    this.props.history.push('/devices')
                    snackbarRef.current.openSnackBar('Regional Admin was deleted successfully.', { backgroundColor: '#469D7C' })
                })
                .catch(() => {
                    snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                    this.setState({ buttonsDisabled: false })
                })
        }

        this.setState({ showDeleteConfirmationDialog: false })
    }

    handlePasswordReset = (event) => {
        event.preventDefault()

        const { snackbarRef, editableLocalAdmin } = this.props

        this.setState({ buttonsDisabled: true })

        forgotPassword.sendForgotPasswordEmail(editableLocalAdmin.data.email)
            .then(() => {
                snackbarRef.current.openSnackBar('Reset password email was sent successfully.', { backgroundColor: '#469D7C' })
                this.setState({ buttonsDisabled: false })
            })
            .catch(() => {
                snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    render() {
        const { backClicked, displayErrors, buttonsDisabled } = this.state
        const { editableLocalAdmin } = this.props

        if (backClicked) {
            return <Redirect to='/devices' />
        }

        return (
            <>
                <DeleteConfirmationDialog show={this.state.showDeleteConfirmationDialog}
                    onClose={this.handleDeleteConfirmationClick}
                    warningText={<p>Are you sure you would like to delete Regional Admin?</p>} />
                <form className='EditableLocalAdminInformationForm' onSubmit={this.handleSubmit}>
                    {
                        this.state.isEditable
                            ? <Field name='firstName' component={renderInput} label='First Name *' validate={validators.required} displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">First Name</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.firstName}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='lastName' component={renderInput} label='Last Name *' validate={validators.required} displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">Last Name</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.lastName}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='companyName' component={renderInput} label='Company Name' />
                            : <>
                                <label className="not-editable">Company Name</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.companyName}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='streetAddress' component={renderInput} label='Street Address' />
                            : <>
                                <label className="not-editable">Street Address</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.streetAddress}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='city' component={renderInput} label='City' displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">City</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.city}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='state' component={renderInput} label='State/Province' displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">State/Province</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.state}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='country' component={renderSelect} label='Country *' displayErrors={displayErrors} validate={validators.required} options={countryList.getData()}
                                displayFn={c => `${c.code} - ${c.name}`} getValueFn={c => `${c.code} - ${c.name}`} renderDefaultOption={() => <CountryDefaultOption disabled />} />
                            : <>
                                <label className="not-editable">Country</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.country}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='zip' component={renderInput} label='Zip/Postal Code' displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">Zip/Postal Code</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.zip}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <><label htmlFor='phone'>Cell Phone Number (Alerts)</label>
                                <Field name='phone' id='phone' component={PhoneInput} validate={validators.phone} /></>
                            : <>
                                <label className="not-editable">Cell Phone Number (Alerts)</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.phone}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <><label htmlFor='mobilePhone'>Contact Phone Number</label>
                                <Field id='mobilePhone' name='mobilePhone' component={PhoneInput} validate={validators.phone} /></>
                            : <>
                                <label className="not-editable">Contact Phone Number</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.mobilePhone}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='email' component={renderInput} label='Email *' type='email' validate={[validators.required, validators.email]} displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">Email</label>
                                <div className='uneditable-form-field'>
                                    {editableLocalAdmin.data && editableLocalAdmin.data.email}
                                </div>
                            </>
                    }

                    {
                        this.props.userId === 'new'
                            ? <>
                                <Field name='password' component={renderInput} label='Password *' type='password' validate={validators.password} displayErrors={displayErrors} />
                                <Field name='confirmPassword' component={renderInput} label='Re-enter Password *' type='password' validate={validators.password} displayErrors={displayErrors} />
                            </>
                            : <></>
                    }


                    {
                        this.state.isEditable
                            ? <div className='required-label'>(*)<i>Required Fields</i></div>
                            : <></>
                    }
                    {
                        <div className='buttons-container'>
                            {
                                this.state.isEditable
                                    ? <input type='submit' value='Save' className='btn green big-radius shadow' disabled={buttonsDisabled} />
                                    : <input type='button' value='Edit' className='btn green big-radius shadow' disabled={buttonsDisabled} onClick={(e) => { e.preventDefault(); this.setState({ isEditable: true }) }} />
                            }
                            {
                                this.props.userId === 'new'
                                    ? <></>
                                    : <>
                                        <input type='button' value='Password Reset' className='btn green big-radius shadow'
                                            disabled={this.state.isEditable || buttonsDisabled} onClick={e => this.handlePasswordReset(e)} />
                                        <input type='button' value={editableLocalAdmin.data && editableLocalAdmin.data.isActive ? 'Deactivate' : 'Activate'}
                                            className='btn yellow big-radius shadow' disabled={this.state.isEditable || buttonsDisabled}
                                            onClick={e => this.handleBlockUnblock(e)} />
                                        <input type='button' value='Delete' className='btn red big-radius shadow'
                                            disabled={this.state.isEditable || buttonsDisabled} onClick={e => this.handleDelete(e)} />
                                    </>
                            }
                        </div>
                    }

                </form>
            </>
        )
    }
}


const stateToProps = state => ({
    initialValues: state.editableLocalAdmin.data,
    currentFormValues: getFormValues('editableLocalAdminInformationForm')(state),
    currentSyncErrors: getFormSyncErrors('editableLocalAdminInformationForm')(state),
    snackbarRef: state.common.snackbarReference,
    editableLocalAdmin: state.editableLocalAdmin
})

const dispatchToProps = {
    ...editableLocalAdminActions
}

EditableLocalAdminInformationForm = reduxForm({
    form: 'editableLocalAdminInformationForm',
    enableReinitialize: true
})(EditableLocalAdminInformationForm)

export default connect(stateToProps, dispatchToProps)(withRouter(EditableLocalAdminInformationForm))
