import { SAVE_EDITABLE_USER } from '../types/editableUser'

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  userId: null
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case SAVE_EDITABLE_USER:
      return { ...state, data: payload.user, userId: payload.user.id }
    //   case FETCH_EDITABLE_FLEET_ADMIN_INFO:
    //     return { ...state, isLoading: true }
    //   case FETCH_EDITABLE_FLEET_ADMIN_INFO_SUCCESS:
    //     return { ...state, isLoading: false, data: payload.fleetAdmin }
    //   case FETCH_EDITABLE_FLEET_ADMIN_ERROR:
    //     return { ...state, isLoading: false, error: payload.error }
    //   case CLEAR_EDITABLE_FLEET_ADMIN:
    //     return initialState
    //   case SAVE_EDITABLE_FLEET_ADMIN:
    //     return { ...state, fleetAdminId: payload.fleetAdmin.id, isLoading: false, error: null }
    default:
      return state
  }
}
