import {
    FETCH_PRODUCT_TYPES
} from '../types/products'
import {
    FETCH_DASHBOARD_CATEGORY_AMOUNTS,
    FETCH_DASHBOARD_CATEGORY_AMOUNTS_ERROR,
    FETCH_DASHBOARD_CATEGORY_AMOUNTS_SUCCESS,
    SET_DASHBOARD_CATEGORY,
    FETCH_DASHBOARD_ITEMS,
    FETCH_DASHBOARD_ITEMS_ERROR,
    FETCH_DASHBOARD_ITEMS_SUCCESS,
    SET_DASHBOARD_SEARCHVALUE,
    RESET_DASHBOARD_SEARCHED,
    CLEAR_DASHBOARD_ITEMS
} from '../types/dashboard'
import dashboardService from '../../services/dashboard'
import profilesService from '../../services/productsService'

const dashboardUrls = {
    fleetAdmins: 'fleetAdmins',
    fleetManagers: 'fleetManagers',
    localAdmins: 'localAdmins',
    users: 'users',
    assists: 'assists',
    problem: 'problem',
    overdue: 'overdue'
}

const getCategoryAmounts = () => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_CATEGORY_AMOUNTS })
        const categoryAmounts = await dashboardService.getCategoryAmounts()
        dispatch({ type: FETCH_DASHBOARD_CATEGORY_AMOUNTS_SUCCESS, categoryAmounts })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_CATEGORY_AMOUNTS_ERROR, error })
    }
}

const getFleetAdmins = (searchValue) => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const items = await dashboardService.getFleetAdmins(searchValue)
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items, searchedCategory: dashboardUrls.fleetAdmins })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

const getUsers = (searchValue) => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const result = await Promise.all([
            dashboardService.getUsers(searchValue),
            profilesService.fetchProfileTypes()
        ])
        dispatch({ type: FETCH_PRODUCT_TYPES, types: result[1] })
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items: result[0], searchedCategory: dashboardUrls.users })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

const getAssists = () => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const result = await Promise.all([
            dashboardService.getAssists(),
            profilesService.fetchProfileTypes()
        ])
        dispatch({ type: FETCH_PRODUCT_TYPES, types: result[1] })
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items: result[0], searchedCategory: dashboardUrls.assists })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

const getProblemProfiles = () => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const result = await Promise.all([
            dashboardService.getProblemProfiles(),
            profilesService.fetchProfileTypes()
        ])
        dispatch({ type: FETCH_PRODUCT_TYPES, types: result[1] })
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items: result[0], searchedCategory: dashboardUrls.problem })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

const getOverdueProfiles = () => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const result = await Promise.all([
            dashboardService.getOverdueProfiles(),
            profilesService.fetchProfileTypes()
        ])
        dispatch({ type: FETCH_PRODUCT_TYPES, types: result[1] })
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items: result[0], searchedCategory: dashboardUrls.overdue })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

const getLocalAdmins = (searchValue) => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const items = await dashboardService.getLocalAdmins(searchValue)
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items, searchedCategory: dashboardUrls.localAdmins })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

const getFleetManagers = (searchValue) => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const items = await dashboardService.getFleetManagers(searchValue)
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items, searchedCategory: dashboardUrls.fleetManagers })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

const profilesByUser = () => async dispatch => {
    try {
        dispatch({ type: FETCH_DASHBOARD_ITEMS })
        const result = await Promise.all([
            profilesService.profilesByUser(),
            profilesService.fetchProfileTypes()
        ])
        dispatch({ type: FETCH_PRODUCT_TYPES, types: result[1] })
        dispatch({ type: FETCH_DASHBOARD_ITEMS_SUCCESS, items: result[0], searchedCategory: null })
    } catch (error) {
        dispatch({ type: FETCH_DASHBOARD_ITEMS_ERROR, error })
    }
}

export const setSearchValue = (value) => ({
    type: SET_DASHBOARD_SEARCHVALUE,
    value
})

export const resetSearched = () => ({
    type: RESET_DASHBOARD_SEARCHED,
    searched: false
})

export const clearDashboardItems = () => ({
    type: CLEAR_DASHBOARD_ITEMS
})

export const setDashboardCategory = (category) => ({
    type: SET_DASHBOARD_CATEGORY,
    category
})

export default {
    getCategoryAmounts,
    getFleetAdmins,
    getUsers,
    getAssists,
    getProblemProfiles,
    getOverdueProfiles,
    getLocalAdmins,
    getFleetManagers,
    profilesByUser,
    setSearchValue
}