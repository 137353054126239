import http from './http'
import axios from 'axios'
import fileSaver from 'file-saver'

// !!!  product = profile  !!!

const findProducts = async (searchValue) => {
  try {
    const response = await http.get(`profile/find/${searchValue}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchProductTypes = async () => {
  try {
    const response = await http.get('profileTypes')
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const createProduct = async (product) => {
  try {
    const response = await http.post('profile', product)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const updateProduct = async (product) => {
  try {
    const response = await http.put('profile', product)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchProduct = async (productId) => {
  try {
    const response = await http.get(`profile/${productId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const assignAssistToProduct = async (productId, assistId) => {
  try {
    const response = await http.post(`profile/${productId}/assign/${assistId}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const unassignAssistFromProduct = async (productId) => {
  try {
    const response = await http.post(`profile/${productId}/unassign`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const removeProduct = async (productId) => {
  try {
    await http.delete(`profile/${productId}`)
  } catch (error) {
    console.log(error)
    throw error
  }
}

const getProfileFeed = async (profileId) => {
  try {
    const profileFeedResponse = await http.get(`profileFeed/${profileId}`)
    return profileFeedResponse.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchProfileBySerialNumber = async (serialNumber) => {
  try {
    const profileBySerialResponse = await http.get(`profile/byserial/${serialNumber}`)
    return profileBySerialResponse.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchProfilesByFleetAdmin = async (fleetAdminId) => {
  try {
    const profilesByFleetAdminResponse = await http.get(`profile/bydealer/${fleetAdminId}`)
    return profilesByFleetAdminResponse.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const unassignClientFromProfile = async (profileId) => {
  const response = await http.delete(`profile/${profileId}/client`)
  return response.data
}

const profilesByUser = async () => {
  const response = await http.get('profile/find/byuser')
  return response.data
}

const exportExcelReport = async (profileId) => {
  const requsetConfig = {
    responseType: 'arraybuffer',
    baseURL: process.env.REACT_APP_HANDICARE_BASE_URL
  }

  await axios.get(`report/profile/${profileId}`, requsetConfig)
    .then((response) => {
      var blob = new Blob([response.data], { type: response.headers['content-type'] })
      fileSaver.saveAs(blob, `report_profile_id_${profileId}.xlsx`)
    })
}

const liftCycleHistory = async (profileId) => {
  const response = await http.get(`liftCycleHistory/${profileId}`)
  return response.data
}

export default {
  findProfiles: findProducts,
  fetchProfileTypes: fetchProductTypes,
  createProfile: createProduct,
  updateProfile: updateProduct,
  fetchProfile: fetchProduct,
  assignAssistToProfile: assignAssistToProduct,
  unassignAssistFromProfile: unassignAssistFromProduct,
  removeProfile: removeProduct,
  getProfileFeed,
  fetchProfileBySerialNumber,
  fetchProfilesByFleetAdmin,
  unassignClientFromProfile,
  profilesByUser,
  exportExcelReport,
  liftCycleHistory
}
