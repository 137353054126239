import {
  SET_LOGOUT_MENU_OPEN_STATE,
  SET_DEVICE_LOGO_MENU_OPEN_STATE,
  SET_DEVICE_FEED_HEADER_SLIDER,
  SET_DEVICE_FEED_CONTENT_SLIDER,
  SET_SNACKBAR_REF,
  CHECK_SIGN_UP_CODE_EXISTENCE,
  SET_SIGN_UP_CODE_VALIDATION,
  CHECK_SIGN_UP_CODE_EXISTENCE_ERROR,
  SET_SHOW_REGISTRATION_APPROVE_POP_UP
} from '../types/common'


const initialState = {
  logoutMenuOpen: false,
  deviceLogoMenuOpen: false,
  deviceFeedHeaderSliderRef: null,
  deviceFeedContentSliderRef: null,
  snackbarReference: null,

  isSignUpCodeValid: true,
  isSignUpCodeValidating: false, // need to block submit button while loading

  showRegistrationApprovePopUp: false,
  registrationApprovePopUpText: null

}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case SET_LOGOUT_MENU_OPEN_STATE:
      return { ...state, logoutMenuOpen: payload.logoutMenuOpen }
    case SET_DEVICE_LOGO_MENU_OPEN_STATE:
      return { ...state, deviceLogoMenuOpen: payload.deviceLogoMenuOpen }
    case SET_DEVICE_FEED_HEADER_SLIDER:
      return { ...state, deviceFeedHeaderSliderRef: payload.slider }
    case SET_DEVICE_FEED_CONTENT_SLIDER:
      return { ...state, deviceFeedContentSliderRef: payload.slider }
    case SET_SNACKBAR_REF:
      return { ...state, snackbarReference: payload.snackbarRef }
    case CHECK_SIGN_UP_CODE_EXISTENCE:
      return { ...state, isSignUpCodeValidating: true }
    case SET_SIGN_UP_CODE_VALIDATION:
      return { ...state, isSignUpCodeValidating: false, isSignUpCodeValid: payload.isValid }
    case CHECK_SIGN_UP_CODE_EXISTENCE_ERROR:
      return { ...state, isSignUpCodeValidating: false }
    case SET_SHOW_REGISTRATION_APPROVE_POP_UP:
      return {
        ...state,
        showRegistrationApprovePopUp: payload.value,
        registrationApprovePopUpText: payload.text
      }
    default:
      return state
  }
}
