import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute/privateRoute'
import './styles.scss'
import { connect } from 'react-redux'
import authActions from '../../store/actions/auth'
import commonActions from '../../store/actions/common'
import SearchDeviceHeader from '../SearchDeviceHeader/searchDeviceHeader'
import DeviceListPage from '../DeviceListPage/deviceListPage'
import EditableProfileInformation from '../../components/EditableProfileInformation/EditableProfileInformation'
import EditableFleetAdminInformation from '../../components/EditableFleetAdminInformation/EditableFleetAdminInformation'
import EditableFleetManagerInformation from '../../components/EditableFleetManagerInformation/EditableFleetManagerInformation'
import EditableLocalAdminInformation from '../../components/EditableLocalAdminInformation/EditableLocalAdminInformation'

const stateToProps = (state) => ({
    devices: state.products,
    showOverlay: (state.common.logoutMenuOpen || state.common.deviceLogoMenuOpen)
})

const dispatchToProps = {
    logout: authActions.logout,
    setLogoutMenuOpenState: commonActions.setLogoutMenuOpenState,
    setDeviceLogoMenuOpenState: commonActions.setDeviceLogoMenuOpenState
}

class EarlyMasterPage extends Component {

    closeMenus = () => {
        this.props.setLogoutMenuOpenState(false)
    }

    render() {
        return (
            <>
                <div className={`overlay-bg ${this.props.showOverlay ? 'show' : 'hide'}`} onClick={this.closeMenus}></div>
                <div id='search-page'>
                    <SearchDeviceHeader />
                    <Switch>
                        <PrivateRoute path='/devices' component={DeviceListPage} />
                        <PrivateRoute path='/profile/:profileId' component={EditableProfileInformation} />
                        <PrivateRoute path='/fleetAdmin/:fleetAdminId' component={EditableFleetAdminInformation} />
                        <PrivateRoute path='/localAdmin/:userId' component={EditableLocalAdminInformation} />
                        <PrivateRoute path='/fleetManager/:userId' component={EditableFleetManagerInformation} />
                        <Route path='/' render={() => <Redirect to='/devices' />} />
                    </Switch>
                </div>
            </>
        )
    }
}

export default connect(stateToProps, dispatchToProps)(EarlyMasterPage)