// import http from './http'
import axios from 'axios'

const checkSignUpCode = async code => {
    try {
        const response = await axios({
            method: 'post',
            url: '/users/signupcode/check',
            baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL,
            data: {
                signUpCode: code
            }
        })
        
        return response.data.exists
    } catch (error) {
        throw error
    }
}

const createUser = async userData => {
    try {
        const response = await axios({
            method: 'post',
            url: '/users',
            baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL,
            data: userData
        })

        return response.data
    } catch (error) {
        throw error.response.data
    }
}

const getUser = async userId => {
    try {
        const response = await axios({
            method: 'get',
            url: `/users/${userId}`,
            baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL
        })

        return response.data
    } catch (error) {
        throw error.response.data
    }
}

const getAll = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: '/users',
            baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL
        })

        return response.data
    } catch (error) {
        throw error.response.data
    }
}

const searchUsers = async (searchValue) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/users',
            params: {
                search: searchValue
            },
            baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL
        })

        return response.data
    } catch (error) {
        throw error.response.data
    }
}

const updateUser = async (userData) => {
    try {
        const response = await axios({
            method: 'patch',
            url: `/users/${userData.id}`,
            baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL,
            data: userData
        })

        return response.data
    } catch (error) {
        throw error.response.data
    }
}

const sendSignUpInfo = async (userId) => {
    const response = await axios ({
        method: 'post',
        url: `/users/email/signupinfo/${userId}`,
        baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL
    })

    return response
}

const deleteUser = async (userId) => {
    const response = await axios ({
        method: 'delete',
        url: `/users/${userId}`,
        baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL
    })

    return response
}

export default {
    checkSignUpCode,
    createUser,
    getUser,
    getAll,
    searchUsers,
    updateUser,
    sendSignUpInfo,
    deleteUser
}