import React, { Component } from 'react'
import './styles.scss'
import { withRouter, Redirect } from 'react-router-dom'
import Slider from 'react-slick'
import { Field, FormSection, reduxForm, isPristine, isValid, getFormValues, getFormSyncErrors } from 'redux-form'
import { connect } from 'react-redux'
import editableProfileActions from '../../../store/actions/editableProfile'
import profileActions from '../../../store/actions/products'
import validators from '../../../utils/validators'
import DeleteConfirmationDialog from '../../DeleteConfirmationDialog/deleteConfirmationDialog'
import countryList from 'country-list'
import { change } from 'redux-form'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from '../../PhoneInput/phoneInput'
import { Tooltip } from 'react-tippy'
import authService from '../../../services/auth'
import clientsService from '../../../services/clientsService'
import usersService from '../../../services/usersService'
import forgotPassword from '../../../services/forgotPassword'
import productsService from '../../../services/productsService'
import userRole from '../../../constants/userRole'
import AutosuggestField from '../../../containers/Autosuggest/autosuggestField'
import { FaUser, FaUsers, FaClock, FaBan, FaUserPlus } from 'react-icons/fa'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { toTitleCase } from '../../../utils/toTitleCase'
import moment from 'moment'
import renderInput from '../../../utils/renderInput'
import warrantyCoverageOptions from '../../../constants/warrantyCoverageOptions'
import renderSelect from '../../../utils/renderSelect'
import CountryDefaultOption from '../../CountryDefaultOption/CountryDefaultOption'
import userTypes from '../../../utils/userTypes'

const stateToProps = (state) => ({
    initialValues: state.editableProfile.data,
    currentSyncErrors: getFormSyncErrors('editableProfileForm')(state),
    currentFormValues: getFormValues('editableProfileForm')(state),
    isFormValid: isValid('editableProfileForm')(state),
    isFormPristine: isPristine('editableProfileForm')(state),
    editableProfile: state.editableProfile,
    snackbarRef: state.common.snackbarReference,
    profileTypes: state.products.types,
    suggestions: state.editableProfile.suggestions,
    autosuggestValue: state.editableProfile.suggestionInputValue
})

const dispatchToProps = {
    ...editableProfileActions,
    fetchProfileTypes: profileActions.fetchProfileTypes
}

class EditableProfileInformationForm extends Component {
    state = {
        headerSliderRef: null,
        contentSliderRef: null,
        isEditable: false,
        cancelClicked: false,
        currentSlideIndex: 0,
        showRemoveConfirm: false,
        clientTypeSelected: false,
        userType: '',
        buttonsDisabled: true
    }

    componentDidMount() {

        const { profileId, updateTitle } = this.props

        this.setState({
            clientTypeSelected: profileId !== 'new'
        })

        this.props.fetchProfileTypes()

        this.props.selectProfileInfo(profileId)
            .then(() => {
                if (profileId !== 'new') {
                    const client = this.props.editableProfile.data.client

                    const pageTitle = client.userType === userTypes.individual
                        ? `${client.firstName} ${client.lastName}'s Profile`
                        : `${client.companyName}, ${client.unit}, ${client.roomNumber} Profile`

                    updateTitle(pageTitle)
                    this.setState({
                        userType: client.userType,
                        buttonsDisabled: false
                    })
                } else {
                    this.setState({
                        buttonsDisabled: false
                    })
                }
            })

        this.setState({
            headerSliderRef: this.headerSliderRef,
            contentSliderRef: this.contentSliderRef
        })
    }

    componentWillUnmount() {
        this.props.clearEditableProfile()
    }

    renderRequiredLabel = (htmlFor, title) => {
        return <label htmlFor={htmlFor}>{`${title}${this.state.isEditable ? ' *' : ''}`}</label>
    }

    debounceStartLoadSuggestions = AwesomeDebouncePromise(this.props.startLoadSuggestions, 1000)

    renderSuggestion = (suggestion) => {
        return (
            <span className={(suggestion.clientRaw.userType === 'empty' || suggestion.clientRaw.userType === 'loading') ? 'not-clickable' : ''}>
                {
                    this.renderSuggestionIcon(suggestion.clientRaw.userType)
                }
                {
                    suggestion.clientRaw.userType === userTypes.facility
                        ? <>{suggestion.clientRaw.companyName}, {suggestion.clientRaw.unit}, {suggestion.clientRaw.roomNumber}</>
                        : <>{suggestion.clientRaw && suggestion.clientRaw.firstName} {suggestion.clientRaw && suggestion.clientRaw.lastName}</>
                }
            </span>
        )
    }

    renderSuggestionIcon = (userType) => {
        switch (userType) {
            case userTypes.facility:
                return (
                    <FaUsers className='users-icon'></FaUsers>
                )
            case 'loading':
                return (
                    <FaClock className='user-icon'></FaClock>
                )
            case 'empty':
                return (
                    <FaBan className='user-icon'></FaBan>
                )
            case 'new':
                return (
                    <FaUserPlus className='user-icon'></FaUserPlus>
                )
            default:
                return (
                    <FaUser className='user-icon'></FaUser>
                )
        }
    }

    renderSuggestionInput = inputProps => {
        return <>
            <label htmlFor='suggestionInput'>Choose User</label>
            <input  {...inputProps} type='text'
                id='suggestionInput' />
        </>
    }

    handleAutocompletePress = (event, { suggestion }) => {
        if (suggestion.clientRaw.userType === 'empty' || suggestion.clientRaw.userType === 'loading') {
            return
        }
        if (suggestion.userId === 'new') {
            this.setState({ isEditable: true, clientTypeSelected: true, userType: userTypes.individual })
            this.props.resetEditableProfileFormValues()
            this.props.setEditableProfileClientInfo({ userType: userTypes.individual })
        } else {
            this.setState({ isEditable: false, clientTypeSelected: true, userType: suggestion.clientRaw.userType })
            clientsService.fetchClient(suggestion.userId).then(client => {
                this.props.setEditableProfileClientInfo(client)
            })
        }
    }

    handleEditClick = (e) => {
        this.setState({ isEditable: true })
    }

    handleCancelClick = (e) => {
        this.props.resetEditableProfileFormValues()
        if (this.props.profileId === 'new') {
            this.setState({ isEditable: false, cancelClicked: true, clientTypeSelected: false })
        } else {
            this.setState({ isEditable: false })
        }
    }

    handleSaveClick = (e) => {
        this.setState({ buttonsDisabled: true })
        this.props.saveProfile(this.props.profileId, this.props.currentFormValues)
            .then(() => {
                this.setState({ buttonsDisabled: false, isEditable: false })
                this.props.history.push(`/profile/${this.props.editableProfile.profileId}`)
                this.props.resetEditableProfileFormValues()
                this.props.selectProfileInfo(this.props.editableProfile.profileId)
                    .then(() => {
                        const client = this.props.editableProfile.data.client

                        const pageTitle = client.userType === userTypes.individual
                            ? `${client.firstName} ${client.lastName}'s Profile`
                            : `${client.companyName}, ${client.unit}, ${client.roomNumber} Profile`

                        this.props.updateTitle(pageTitle)
                        this.props.snackbarRef.current.openSnackBar('Profile has been saved', { backgroundColor: '#469D7C' })
                    })
            })
            .catch((e) => {
                this.setState({ buttonsDisabled: false })
                this.props.snackbarRef.current.openSnackBar(e.message, { backgroundColor: '#a83256' })
            })
    }

    handleRemoveProfileClick = () => {
        this.setState({ showRemoveConfirm: true })
    }

    handleRemoveProfileConfirmationClick = (val) => {
        if (val) {
            this.setState({ buttonsDisabled: true })

            this.props.removeProfile(this.props.profileId)
                .then(() => {
                    this.props.history.push('/devices')
                    this.props.snackbarRef.current.openSnackBar('Profile has been removed', { backgroundColor: '#469D7C' })
                })
                .catch(() => {
                    this.props.snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                    this.setState({ buttonsDisabled: false })
                })
        }
        this.setState({ showRemoveConfirm: false })
    }

    renderButtons = (sectionName) => {
        const { editableProfile, profileId, isFormValid } = this.props
        const { buttonsDisabled } = this.state
        const role = authService.getCurrentUserRole()

        return (
            <div className='btns'>
                {
                    (role === userRole.fleetAdmin || role === userRole.fleetManager)
                        ? <>
                            {
                                profileId === 'new'
                                    ? this.state.currentSlideIndex !== 0 // 2 = number of slides - 1
                                        ? <button className='btn shadow gray' type='button' onClick={() => {
                                            if (this.state.currentSlideIndex === 1 && this.state.isEditable && editableProfile.data.client.id) {
                                                this.setState({ isEditable: false })
                                            }
                                            this.state.contentSliderRef.slickPrev()
                                        }}>
                                            Back
                                        </button>
                                        : <button className='btn shadow gray' type='button' onClick={this.handleCancelClick} disabled={buttonsDisabled}>Cancel</button>
                                    : <></>
                            }
                            {
                                profileId === 'new'
                                    ? this.state.currentSlideIndex !== 2 // 2 = number of slides - 1
                                        ? <button type='button' className='btn orange shadow' onClick={() => {
                                            if (this.state.currentSlideIndex === 0 && !this.state.isEditable) {
                                                this.setState({ isEditable: true })
                                            }
                                            this.state.contentSliderRef.slickNext()
                                        }}
                                            disabled={this.props.currentSyncErrors && this.props.currentSyncErrors[sectionName]}>
                                            Next
                                </button>
                                        : <button type='button' className='btn orange shadow' onClick={this.handleSaveClick} disabled={!isFormValid || buttonsDisabled}>Save</button>
                                    : <></>
                            }
                            {
                                profileId === 'new'
                                    ? <></>
                                    : <>
                                        {
                                            this.state.isEditable
                                                ? <button type='button' className='btn green shadow' onClick={this.handleSaveClick} disabled={!isFormValid || buttonsDisabled}>Save</button>
                                                : <button type='button' className='btn green shadow' onClick={this.handleEditClick} disabled={buttonsDisabled}>Edit</button>
                                        }
                                        <button className='btn shadow green' type='button' onClick={e => this.handleResetClientPassword(e)} disabled={this.state.isEditable || buttonsDisabled}>Reset Password</button>
                                        {
                                            role === userRole.fleetAdmin
                                                ? <>
                                                    <button className='btn shadow yellow' type='button' onClick={e => this.handleActivateDeactivateClient(e)} disabled={this.state.isEditable || buttonsDisabled}>{editableProfile.data && editableProfile.data.client.isActive ? 'Deactivate' : 'Activate'}</button>
                                                    <button className='btn shadow red' type='button' onClick={e => this.handleRemoveProfileClick(e)} disabled={this.state.isEditable || buttonsDisabled}>Remove Profile</button>
                                                </>
                                                : <></>
                                        }
                                    </>
                            }
                        </>
                        : <></>
                }
            </div>
        )
    }

    isClientCheckboxEditable = (type) => {
        if (!this.props.currentFormValues) {
            return false
        }
        const { client } = this.props.currentFormValues
        if (type === 'sms') {
            return this.state.isEditable && client && !validators.phone(client.phone) && client.phone && client.phone.length > 1
        }
        if (type === 'email') {
            return this.state.isEditable && client && !validators.email(client.email) && client.email
        }
        return false
    }

    isEmergencyCheckboxEditable = (type) => {
        if (!this.props.currentFormValues) {
            return false
        }
        const { emergency } = this.props.currentFormValues
        if (type === 'sms') {
            return this.state.isEditable && emergency && !validators.phone(emergency.cellPhone) && emergency.cellPhone && emergency.cellPhone.length > 1
        }
        if (type === 'email') {
            return this.state.isEditable && emergency && !validators.email(emergency.email) && emergency.email
        }
        return false
    }

    handleActivateDeactivateClient = (event) => {
        event.preventDefault()

        const { snackbarRef, editableProfile, setEditableProfileClientInfo } = this.props

        const snackBarText = !editableProfile.data.client.isActive
            ? 'User was activated successfully.'
            : 'User was deactivated successfully.'

        this.setState({ buttonsDisabled: true })

        usersService.updateUser({ id: editableProfile.data.client.id, isActive: !editableProfile.data.client.isActive })
            .then(client => {
                setEditableProfileClientInfo(client)
                snackbarRef.current.openSnackBar(snackBarText, { backgroundColor: '#469D7C' })
                this.setState({ buttonsDisabled: false })
            })
            .catch(() => {
                snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    handleUserTypeChange = (event) => {
        const { value } = event.target
        this.setState({ userType: value })
        this.props.resetEditableProfileFormValues()
        this.props.dispatch(change('editableProfileForm', 'client.userType', value))
    }

    handleResetClientPassword = (event) => {
        event.preventDefault()

        const { snackbarRef, editableProfile } = this.props

        this.setState({ buttonsDisabled: true })

        forgotPassword.sendForgotPasswordEmail(editableProfile.data.client.email)
            .then(() => {
                snackbarRef.current.openSnackBar('Reset password email was sent successfully.', { backgroundColor: '#469D7C' })
                this.setState({ buttonsDisabled: false })
            })
            .catch(() => {
                snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })

    }

    handleDeleteClient = (event) => {
        event.preventDefault()

        const { snackbarRef, profileId } = this.props

        this.setState({ buttonsDisabled: true })

        productsService.unassignClientFromProfile(profileId)
            .then(() => {
                this.props.history.push('/devices')
                snackbarRef.current.openSnackBar('User was unassigned successfully.', { backgroundColor: '#469D7C' })
                this.setState({ buttonsDisabled: false })
            })
            .catch(() => {
                snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    render() {
        const role = authService.getCurrentUserRole()

        if (this.state.cancelClicked ||
            (this.props.profileId === 'new' &&
                (role === userRole.globalAdmin || role === userRole.localAdmin))) {
            return <Redirect to='/devices' />
        }

        const { handleSubmit, autosuggestValue, suggestions, updateSuggestionInputValue, clearSuggestions, editableProfile, profileTypes } = this.props

        const headerSliderSettings = {
            arrows: this.props.profileId !== 'new',
            dots: true,
            speed: 0,
            slidesToShow: 1,
            infinite: false,
            slidesToScroll: 1,
            swipe: false,
            nextArrow: <CustomNextArrow />,
            prevArrow: <CustomPrevArrow />
        }

        const sliderSettings = {
            arrows: false,
            slidesToShow: 1,
            infinite: false,
            speed: 0,
            swipe: false,
            slidesToScroll: 1,
            afterChange: (i) => this.setState({ currentSlideIndex: i })
        }

        const inputProps = {
            placeholder: '',
            value: autosuggestValue,
            onChange: updateSuggestionInputValue
        }

        return (
            <div className='EditableProfileInformationForm'>
                <DeleteConfirmationDialog show={this.state.showRemoveConfirm} onClose={this.handleRemoveProfileConfirmationClick} deleteButtonText='Delete Profile'
                    warningText={<p>This action will delete the User’s Profile.<br />Any Assist attached to the product needs to be reassigned.</p>} />
                <form id='new-profile' onSubmit={handleSubmit}>
                    <div className="profile-title">
                        <div id='header-slides' className={`${this.props.profileId === 'new' ? 'disabled-dots' : ''}`}>
                            <Slider {...headerSliderSettings}
                                ref={slider => (this.headerSliderRef = slider)}
                                asNavFor={this.state.contentSliderRef}>
                                <h1>Client Information</h1>
                                <h1>Product Information</h1>
                                <h1>Custom Contact Information</h1>
                            </Slider>
                        </div>
                    </div>
                    <Slider {...sliderSettings}
                        ref={slider => (this.contentSliderRef = slider)}
                        asNavFor={this.state.headerSliderRef}>
                        <>
                            <FormSection name='client'>
                                <div className="profile-content">
                                    {
                                        this.props.profileId === 'new'
                                            ? <AutosuggestField
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={this.debounceStartLoadSuggestions}
                                                onSuggestionsClearRequested={clearSuggestions}
                                                onSuggestionSelected={this.handleAutocompletePress}
                                                getSuggestionValue={suggestion => suggestion}
                                                renderSuggestion={this.renderSuggestion}
                                                renderInputComponent={this.renderSuggestionInput}
                                                shouldRenderSuggestions={() => { return true }}
                                                focusInputOnSuggestionClick={false}
                                                inputProps={inputProps} />
                                            : <></>
                                    }
                                    {
                                        this.state.clientTypeSelected
                                            ? <>
                                                {
                                                    this.props.profileId === 'new'
                                                        ? <Field name="userType" component={renderInputRadio}
                                                            validate={validators.required}
                                                            props={{
                                                                isEditable: this.state.isEditable,
                                                                title: 'User Type',
                                                                isRequired: true,
                                                                onChange: this.handleUserTypeChange,
                                                                checked: this.state.userType
                                                            }} />
                                                        : <>
                                                            <label className="not-editable">User Type</label>
                                                            <div className='not-editable-value'>
                                                                {(editableProfile.data
                                                                    && editableProfile.data.client
                                                                    && toTitleCase(editableProfile.data.client.userType))}
                                                            </div>
                                                        </>
                                                }
                                                {
                                                    this.state.userType === userTypes.facility
                                                        ? <>
                                                            {
                                                                this.state.isEditable
                                                                    ? <Field name='companyName' component={renderInput} label='Company/Facility *' validate={validators.required} />
                                                                    : <>
                                                                        <label className="not-editable">Company/Facility</label>
                                                                        <div className='not-editable-value'>
                                                                            {editableProfile.data && editableProfile.data.client.companyName}
                                                                        </div>
                                                                    </>
                                                            }
                                                            {
                                                                this.state.isEditable || this.props.profileId === 'new'
                                                                    ? <Field name='roomNumber' component={renderInput} label='Room Number *' validate={validators.required} />
                                                                    : <>
                                                                        <label className="not-editable">Room Number</label>
                                                                        <div className='not-editable-value'>
                                                                            {editableProfile.data && editableProfile.data.client.roomNumber}
                                                                        </div>
                                                                    </>
                                                            }
                                                            {
                                                                this.state.isEditable || this.props.profileId === 'new'
                                                                    ? <Field name='unit' component={renderInput} label='Unit *' validate={validators.required} />
                                                                    : <>
                                                                        <label className="not-editable">Unit</label>
                                                                        <div className='not-editable-value'>
                                                                            {editableProfile.data && editableProfile.data.client.unit}
                                                                        </div>
                                                                    </>
                                                            }
                                                        </>
                                                        : <>
                                                            {
                                                                this.state.isEditable
                                                                    ? <Field name='firstName' component={renderInput} label={role === userRole.user ? 'First Name/Location *' : 'First Name *'} validate={validators.required} />
                                                                    : <>
                                                                        <label className="not-editable">{role === userRole.user ? 'First Name/Location' : 'First Name'}</label>
                                                                        <div className='not-editable-value'>
                                                                            {editableProfile.data && editableProfile.data.client.firstName}
                                                                        </div>
                                                                    </>
                                                            }
                                                            {
                                                                this.state.isEditable
                                                                    ? <Field name='lastName' component={renderInput} label={role === userRole.user ? 'Last Name/Facility *' : 'Last Name *'} validate={validators.required} />
                                                                    : <>
                                                                        <label className="not-editable">{role === userRole.user ? 'Last Name/Facility' : 'Last Name'}</label>
                                                                        <div className='not-editable-value'>
                                                                            {editableProfile.data && editableProfile.data.client.lastName}
                                                                        </div>
                                                                    </>
                                                            }
                                                        </>
                                                }
                                                {
                                                    this.state.isEditable
                                                        ? <Field name='streetAddress' component={renderInput} label='Street Address' />
                                                        : <>
                                                            <label className="not-editable">Street Address</label>
                                                            <div className='not-editable-value'>
                                                                {(editableProfile.data && editableProfile.data.client.streetAddress)}
                                                            </div>
                                                        </>
                                                }
                                                {
                                                    this.state.isEditable
                                                        ? <Field name='city' component={renderInput} label='City' />
                                                        : <>
                                                            <label className="not-editable">City</label>
                                                            <div className='not-editable-value'>
                                                                {(editableProfile.data && editableProfile.data.client.city)}
                                                            </div>
                                                        </>
                                                }
                                                {
                                                    this.state.isEditable
                                                        ? <Field name='state' component={renderInput} label='State' />
                                                        : <>
                                                            <label className='not-editable'>State</label>
                                                            <div className='not-editable-value'>
                                                                {(editableProfile.data && editableProfile.data.client.state)}
                                                            </div>
                                                        </>
                                                }
                                                {
                                                    this.state.isEditable
                                                        ? <Field name='country' component={renderSelect} label='Country' options={countryList.getData()}
                                                            displayFn={c => `${c.code} - ${c.name}`} getValueFn={c => `${c.code} - ${c.name}`}
                                                            renderDefaultOption={() => <CountryDefaultOption />}
                                                        />
                                                        : <>
                                                            <label className='not-editable'>Country</label>
                                                            <div className='not-editable-value'>
                                                                {(editableProfile.data && editableProfile.data.client.country)}
                                                            </div>
                                                        </>
                                                }
                                                {
                                                    this.state.isEditable
                                                        ? <Field name='zip' component={renderInput} label='Zip/Postal Code *' validate={validators.required} />
                                                        : <>
                                                            <label className="not-editable">Zip/Postal Code</label>
                                                            <div className='not-editable-value'>
                                                                {editableProfile.data && editableProfile.data.client.zip}
                                                            </div>
                                                        </>
                                                }
                                                {
                                                    this.state.isEditable && !(this.props.currentFormValues && this.props.currentFormValues.alertOptions && this.props.currentFormValues.alertOptions.clientSmsAlerts)
                                                        ? <>
                                                            <label htmlFor="phone">Cell Phone Number (Alerts)</label>
                                                            <Field name='phone' component={PhoneInput} validate={validators.phone} id='phone' />
                                                        </>
                                                        : <Tooltip title={this.state.isEditable ? 'Please uncheck Client SMS Alerts to edit this field' : null} disabled={!this.state.isEditable}>
                                                            <label className="not-editable">Cell Phone Number (Alerts)</label>
                                                            <div className='not-editable-value'>
                                                                {(this.props.currentFormValues && this.props.currentFormValues.client.phone)}
                                                            </div>
                                                        </Tooltip>
                                                }
                                                {
                                                    this.state.isEditable && !(this.props.currentFormValues && this.props.currentFormValues.alertOptions && this.props.currentFormValues.alertOptions.clientSmsAlerts)
                                                        ? <>
                                                            <label htmlFor="mobilePhone">Primary Phone Number</label>
                                                            <Field name='mobilePhone' component={PhoneInput} validate={validators.phone} id='mobilePhone' />
                                                        </>
                                                        : <Tooltip title={this.state.isEditable ? 'Please uncheck Client SMS Alerts to edit this field' : null} disabled={!this.state.isEditable}>
                                                            <label className="not-editable">Primary Phone Number</label>
                                                            <div className='not-editable-value'>
                                                                {(this.props.currentFormValues && this.props.currentFormValues.client.mobilePhone)}
                                                            </div>
                                                        </Tooltip>
                                                }
                                                {
                                                    this.state.userType === userTypes.facility
                                                        ? <></>
                                                        : this.state.isEditable && !(this.props.currentFormValues && this.props.currentFormValues.alertOptions && this.props.currentFormValues.alertOptions.clientEmailAlerts)
                                                            ? <Field name='email' component={renderInput} label='Email Address *' validate={[validators.required, validators.email]} />
                                                            : <Tooltip title={this.state.isEditable ? 'Please uncheck Client Email Alerts to edit this field' : null} disabled={!this.state.isEditable}>
                                                                <label className="not-editable">Email Address</label>
                                                                <div className='not-editable-value'>
                                                                    {(this.props.currentFormValues && this.props.currentFormValues.client.email)}
                                                                </div>
                                                            </Tooltip>
                                                }
                                                {
                                                    this.state.isEditable
                                                        ? <><br /><label className="not-editable">(*) Required Fields</label></>
                                                        : <>
                                                            <label className='not-editable'>Fleet Admin Contact Information</label>
                                                            <div className='not-editable-value'>
                                                                {(editableProfile.data && editableProfile.data.client.fleetAdminEmail)}
                                                            </div>
                                                        </>
                                                }
                                            </>
                                            : <></>
                                    }
                                </div>
                            </FormSection>
                            {
                                this.state.clientTypeSelected
                                    ? this.renderButtons('client')
                                    : <></>
                            }
                        </>
                        <>
                            <FormSection name='profile'>
                                <div className="profile-content">
                                    {
                                        this.state.isEditable
                                            ? <Field name='profileTypeId'
                                                component={renderSelect}
                                                label='Device Type *'
                                                validate={validators.required}
                                                onChange={(e, n, p) => {
                                                    this.props.dispatch(change('editableProfileForm', 'profile.modelNumber', editableProfile.modelNumbers.find(mn => mn.profileTypeId === +n).name))
                                                }}
                                                options={profileTypes.filter(t => t.title !== 'Floor Lift')}
                                                displayFn={pt => pt.title}
                                                getValueFn={pt => pt.profileTypeId} />
                                            : <>
                                                <label className="not-editable">Device Type</label>
                                                <div className='not-editable-value'>
                                                    {(editableProfile.data && editableProfile.data.profile.profileTypeId && profileTypes.length > 0)
                                                        ? profileTypes.find(pt => pt.profileTypeId === +editableProfile.data.profile.profileTypeId).title : ''}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='modelNumber'
                                                component={renderSelect}
                                                label='Model Name *'
                                                validate={validators.required}
                                                options={editableProfile.modelNumbers
                                                    .filter(mn => {
                                                        return this.props.currentFormValues && (mn.profileTypeId === +this.props.currentFormValues.profile.profileTypeId)
                                                    })}
                                                displayFn={mn => mn.name} getValueFn={mn => mn.name} />
                                            : <>
                                                <label className="not-editable">Model Name</label>
                                                <div className='not-editable-value'>
                                                    {editableProfile.data && editableProfile.data.profile.modelNumber}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='serialNumber' component={renderInput} label='Serial Number *' validate={[validators.required, validators.serial]} />
                                            : <>
                                                <label className="not-editable">Serial Number</label>
                                                <div className='not-editable-value'>
                                                    {editableProfile.data && editableProfile.data.profile.serialNumber}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='productionDate' component={renderInput} type='date' label='Production Date' />
                                            : <>
                                                <label className="not-editable">Production Date</label>
                                                <div className='not-editable-value'>
                                                    {editableProfile.data && editableProfile.data.profile.productionDate
                                                        ? moment(editableProfile.data.profile.productionDate).format('MMM DD, YYYY')
                                                        : ''}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='installationDate' component={renderInput} type='date' label='Install Date' />
                                            : <>
                                                <label className="not-editable">Install Date</label>
                                                <div className='not-editable-value'>
                                                    {editableProfile.data && editableProfile.data.profile.installationDate
                                                        ? moment(editableProfile.data.profile.installationDate).format('MMM DD, YYYY')
                                                        : ''}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='nextServiceDate' component={renderInput} type='date' label='Next Service Date' />
                                            : <>
                                                <label className="not-editable">Next Service Date</label>
                                                <div className='not-editable-value'>
                                                    {editableProfile.data && editableProfile.data.profile.nextServiceDate
                                                        ? moment(editableProfile.data.profile.nextServiceDate).format('MMM DD, YYYY')
                                                        : ''}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='warrantyCoverage' component={renderSelect} label='Warranty Coverage' options={warrantyCoverageOptions}
                                                displayFn={wco => wco.title} getValueFn={wco => wco.value} />
                                            : <>
                                                <label className="not-editable">Warranty Coverage</label>
                                                <div className='not-editable-value'>
                                                    {(editableProfile.data && editableProfile.data.profile.warrantyCoverage)}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='warrantyEndDate' component={renderInput} type='date' label='Warranty End Date' />
                                            : <>
                                                <label className="not-editable">Warranty End Date</label>
                                                <div className='not-editable-value'>
                                                    {editableProfile.data && editableProfile.data.profile.warrantyEndDate
                                                        ? moment(editableProfile.data.profile.warrantyEndDate).format('MMM DD, YYYY')
                                                        : ''}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <><br /><br /><label className="not-editable">(*) Required Fields</label></>
                                            : <></>
                                    }
                                </div>
                            </FormSection>
                            {this.renderButtons('profile')}
                        </>
                        <>
                            <FormSection name='emergency'>
                                <div className="profile-content">
                                    {
                                        this.state.isEditable
                                            ? <Field name='firstName' component={renderInput} label='First Name' />
                                            : <>
                                                <label className="not-editable">First Name</label>
                                                <div className='not-editable-value'>
                                                    {(editableProfile.data && editableProfile.data.emergency.firstName)}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='lastName' component={renderInput} label='Last Name' />
                                            : <>
                                                <label className="not-editable">Last Name</label>
                                                <div className='not-editable-value'>
                                                    {(editableProfile.data && editableProfile.data.emergency.lastName)}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <Field name='relation' component={renderInput} label='Relation' />
                                            : <>
                                                <label className="not-editable">Relation</label>
                                                <div className='not-editable-value'>
                                                    {(editableProfile.data && editableProfile.data.emergency.relation)}
                                                </div>
                                            </>
                                    }
                                    {
                                        this.state.isEditable && !(this.props.currentFormValues && this.props.currentFormValues.alertOptions && this.props.currentFormValues.alertOptions.emergencySmsAlerts)
                                            ? <>
                                                <label htmlFor="cellPhone" className={this.state.isEditable ? '' : 'not-editable'}>Cell Phone Number (Used for Alerts)</label>
                                                <Field name='cellPhone' component={PhoneInput} validate={validators.phone} id='cellPhone' />
                                            </>
                                            : <Tooltip title={this.state.isEditable ? 'Please uncheck Custom Contact SMS Alerts to edit this field' : null} disabled={!this.state.isEditable}>
                                                <label className="not-editable">Cell Phone Number (Used for Alerts)</label>
                                                <div className='not-editable-value'>
                                                    {(this.props.currentFormValues && this.props.currentFormValues.emergency.cellPhone)}
                                                </div>
                                            </Tooltip>
                                    }
                                    {
                                        this.state.isEditable && !(this.props.currentFormValues && this.props.currentFormValues.alertOptions && this.props.currentFormValues.alertOptions.emergencyEmailAlerts)
                                            ? <Field name='email' component={renderInput} label='Email Address' validate={validators.email} />
                                            : <Tooltip title={this.state.isEditable ? 'Please uncheck Custom Contact Email Alerts to edit this field' : null} disabled={!this.state.isEditable}>
                                                <label className="not-editable">Email Adress</label>
                                                <div className='not-editable-value'>
                                                    {(this.props.currentFormValues && this.props.currentFormValues.emergency.email)}
                                                </div>
                                            </Tooltip>
                                    }
                                    {
                                        this.state.isEditable
                                            ? <><br /><br /><label className="not-editable">(*) Required Fields</label></>
                                            : <></>
                                    }
                                </div>
                            </FormSection>

                            <div className="profile-title">
                                <p className="h1">Alerting Options</p>
                            </div>
                            <FormSection name='alertOptions'>
                                <div className="profile-content">
                                    <div className="options">
                                        <div>
                                            <label htmlFor="clientSmsAlerts" className={!this.isClientCheckboxEditable('sms') ? 'not-editable-alerts' : ''}>
                                                <Field props={{ disabled: !this.isClientCheckboxEditable('sms') }} component='input' type="checkbox" name="clientSmsAlerts" id="clientSmsAlerts" />
                                                <span className="box"></span>
                                                Client SMS Alerts
                                            </label>
                                        </div>

                                        <div>
                                            <label htmlFor="clientEmailAlerts" className={!this.isClientCheckboxEditable('email') ? 'not-editable-alerts' : ''}>
                                                <Field props={{ disabled: !this.isClientCheckboxEditable('email') }} component='input' type="checkbox" name="clientEmailAlerts" id="clientEmailAlerts" />
                                                <span className="box"></span>
                                                Client Email Alerts
                                            </label>
                                        </div>

                                        <div>
                                            <label htmlFor="fleetAdminSmsAlerts" className={!this.state.isEditable ? 'not-editable-alerts' : ''}>
                                                <Field props={{ disabled: !this.state.isEditable }} component='input' type="checkbox" name="fleetAdminSmsAlerts" id="fleetAdminSmsAlerts" />
                                                <span className="box"></span>
                                                Fleet Admin SMS Alerts
                                            </label>
                                        </div>

                                        <div>
                                            <label htmlFor="fleetAdminEmailAlerts" className={!this.state.isEditable ? 'not-editable-alerts' : ''}>
                                                <Field props={{ disabled: !this.state.isEditable }} component='input' type="checkbox" name="fleetAdminEmailAlerts" id="fleetAdminEmailAlerts" />
                                                <span className="box"></span>
                                                Fleet Admin Email Alerts
                                            </label>
                                        </div>

                                        <div>
                                            <label htmlFor="emergencySmsAlerts" className={!this.isEmergencyCheckboxEditable('sms') ? 'not-editable-alerts' : ''}>
                                                <Field props={{ disabled: !this.isEmergencyCheckboxEditable('sms') }} component='input' type="checkbox" name="emergencySmsAlerts" id="emergencySmsAlerts" />
                                                <span className="box"></span>
                                                Custom Contact SMS Alerts
                                            </label>
                                        </div>

                                        <div>
                                            <label htmlFor="emergencyEmailAlerts" className={!this.isEmergencyCheckboxEditable('email') ? 'not-editable-alerts' : ''}>
                                                <Field props={{ disabled: !this.isEmergencyCheckboxEditable('email') }} component='input' type="checkbox" name="emergencyEmailAlerts" id="emergencyEmailAlerts" />
                                                <span className="box"></span>
                                                Custom Contact Email Alerts
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </FormSection>
                            {this.renderButtons()}
                        </>
                    </Slider>
                </form>
            </div>
        )
    }
}

EditableProfileInformationForm = reduxForm({
    form: 'editableProfileForm',
    enableReinitialize: true
})(EditableProfileInformationForm)

EditableProfileInformationForm = connect(stateToProps, dispatchToProps)(EditableProfileInformationForm)

export default withRouter(EditableProfileInformationForm)

// CUSTOM ARROWS FOR SLIDER
const CustomPrevArrow = (props) => {
    const { className, onClick } = props
    return (
        <div
            className={className}
            style={{
                borderWidth: '13px 11px 13px 7px',
                borderColor: 'transparent rgb(255, 255, 255) transparent transparent',
                borderStyle: 'solid',
                borderImage: 'initial',
                display: 'block',
                width: 0,
                height: 0
            }}
            onClick={onClick}
        />
    )
}

const CustomNextArrow = (props) => {
    const { className, onClick } = props
    return (
        <div
            className={className}
            style={{
                borderWidth: '13px 7px 13px 11px',
                borderColor: 'transparent transparent transparent rgb(255, 255, 255)',
                borderStyle: 'solid',
                borderImage: 'initial',
                display: 'block',
                width: 0,
                height: 0
            }}
            onClick={onClick}
        />
    )
}

const renderInputRadio = (props) => {
    const hasError = props.meta.touched && props.meta.error
    const {
        isEditable,
        isRequired,
        onChange,
        checked,
        input,
        meta
    } = props

    return <>
        <label className="radio-label">{props.title}{isRequired && isEditable ? ' *' : ''}</label>
        {
            isEditable
                ? <>
                    {hasError && <div className='validation-message'>{meta.error}</div>}
                    <div className='rows-1 user-type-radios'>
                        <input type='radio'
                            {...input}
                            id='individual-radio-button'
                            onChange={onChange} value={userTypes.individual}
                            checked={checked === userTypes.individual} />
                        <label htmlFor='individual-radio-button'>Individual</label>
                        <input type='radio'
                            {...input}
                            id='facility-radio-button'
                            onChange={onChange} value={userTypes.facility}
                            checked={checked === userTypes.facility} />
                        <label htmlFor='facility-radio-button'>Facility</label>
                    </div>
                </>
                : <div className='not-editable-value'>
                    {toTitleCase(input.value)}
                </div>
        }
    </>
}