import React, { useState } from 'react'
import commonActions from '../../store/actions/common'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import './styles.scss'
import authService from '../../services/auth'
import productsService from '../../services/productsService'
// import c2dSvc from '../../services/c2dService'
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog/deleteConfirmationDialog'
import { FETCH_SELECTED_PROFILE_DATA_SUCCESS } from '../../store/types/selectedProfile'
import { CLEAR_QUESTION_COOLDOWNS } from '../../store/types/questions'
import streamingActions from '../../store/actions/streaming'

const stateToProps = (state) => ({
  menuOpen: state.common.deviceLogoMenuOpen,
  snackbarRef: state.common.snackbarReference,
  profile: state.selectedProfile.profile
})

const DeviceToolBarLogo = (props) => {
  const { imgSrc, serialNumber } = props
  const [showNewAssistConfirmation, setShowNewAssistConfirmation] = useState(false)
  const toggleMenuState = (state) => {
    props.dispatch(commonActions.setDeviceLogoMenuOpenState(state))
  }

  const classes = props.menuOpen ? 'open' : ''

  const onCloseDeleteConfirmationDialog = (value) => {
    if (value) {
      const {
        profile,
        history
      } = props

      if (profile) {
        // props.dispatch(streamingActions.sendAssistData(profile.assistId, { assignmentRequest: 'unassign' }))
        props.dispatch(streamingActions.closeStreamingSocket(profile.assistId))
          .then(() => {
            // props.dispatch({ type: CLEAR_SELECTED_PROFILE })
            props.dispatch({ type: CLEAR_QUESTION_COOLDOWNS })
            return productsService.unassignAssistFromProfile(profile.profileId)
            // return props.dispatch(streamingActions.sendAssistData(profile.assistId, { assignmentRequest: 'unassign' }))
          })
          .then(() => {
            // props.dispatch({ type: FETCH_SELECTED_PROFILE_DATA_SUCCESS, profile: data })
            // return productsService.unassignAssistFromProfile(props.profile.profileId)
            return props.dispatch(streamingActions.sendAssistData(profile.assistId, { assignmentRequest: 'unassign' }))
          })
          .then(() => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                productsService.fetchProfile(profile.profileId)
                  .then(data => {
                    console.log(data)
                    resolve(data)
                  })
              }, 1.5 * 1000)
            })
          })
          .then((data) => {
            props.dispatch({ type: FETCH_SELECTED_PROFILE_DATA_SUCCESS, profile: data })
            history.push(`/devices/${profile.profileId}`)
          })
          .catch(e => {
            props.snackbarRef.current.openSnackBar(e.message, { backgroundColor: '#a83256' })
          })
      }
    }

    setShowNewAssistConfirmation(false)
  }

  return (
    <>
      <DeleteConfirmationDialog show={showNewAssistConfirmation}
        onClose={onCloseDeleteConfirmationDialog}
        warningText={<p>The Device Feed will not be accessible once the Assist is unassigned.<br />Are you sure you want to unassign the Assist?</p>}
        deleteButtonText='Unassign' />
      <div id='device-toolbar-logo' className={classes} onClick={() => toggleMenuState(!props.menuOpen)}>
        {/* <!-- для открытии меню устройстка (div c классом device) или меню в шестиренке справа вверху (класс settings) к этим блокам надо добавить класс "open", например, чтобы открыть меню устройства надо добавить, как писалось выше, к body класс "overlay" а к блоку с классом "device" еще класс "open" (<div class="device open">)--> */}
        {/* <!-- Путь к картинке меняем в стилях самого слоя --> */}
        <div className={`device-img ${props.disconnected ? 'disconnected' : ''}`} style={{ backgroundImage: `url(${imgSrc})` }} />
        <p className='device-serial' style={{ color: props.disconnected ? '#FFDE00' : null }}>{serialNumber}</p>
        <div className='device-ticket-menu'>
          <ul>
            <li>
              {
                authService.getSession().profile
                  ? <span className='disconnect-label'>Disconnect Device</span>
                  : <Link className='disconnect-label' to='/devices'>Disconnect Device</Link>
              }
            </li>
            {
              props.profile && props.profile.assistId
                ? <li><span className='disconnect-label' onClick={() => setShowNewAssistConfirmation(true)}>Assign New Assist</span></li>
                : null
            }
          </ul>
        </div>
      </div>
    </>
  )
}

export default connect(stateToProps)(withRouter(DeviceToolBarLogo))
