import { createSelector } from 'reselect'

const getIsSelectedProfileConnected = state => state.selectedProfile.isConnected

const getIsStreamingMessageReceived = state => state.selectedProfile.messageReceived

const getLiftOperationState = state => state.selectedProfile.liftOperationState

const getIsLiftOperationStateMessageReceived = state => state.selectedProfile.liftOperationStateMessageReceived

export const chargeMessage = createSelector(
  [
    getIsSelectedProfileConnected,
    getIsStreamingMessageReceived,
    getLiftOperationState,
    getIsLiftOperationStateMessageReceived
  ],
  (isConnected, messageReceived, liftOperationState, liftOperationStateMessageReceived) => {
    return isConnected
      ? messageReceived
        ? liftOperationStateMessageReceived
          ? liftOperationState.subHeading
          : 'Connected'
        : 'Trying To Connect'
      : 'Disconnected'
  }
)
