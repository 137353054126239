import http from './http'

const createServiceReport = async (serviceReport) => {
  try {
    const response = await http.post('serviceReport', serviceReport)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const updateServiceReport = async (serviceReport) => {
  try {
    const response = await http.put('serviceReport', serviceReport)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchServiceReport = async (serviceReportId) => {
  try {
    const response = await http.get(`serviceReport/${serviceReportId}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchServiceReportByProfileId = async (profileId) => {
  try {
    const response = await http.get(`serviceReport/byprofile/${profileId}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchRepairTypes = async () => {
  try {
    const response = await http.get('repairTypes')
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export default {
  createServiceReport,
  updateServiceReport,
  fetchServiceReport,
  fetchServiceReportByProfileId,
  fetchRepairTypes
}
