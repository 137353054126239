import React from 'react'
import './styles.scss'

const Intro = (props) => {
    return (
        <div className='Intro'>
            <div className='description'>
                Click "Next" and scan the Assist QR code to complete the assignment process.
                <br />
                <br />
                Note: The scanning process may require you to allow camera permissions in your browser.
            </div>
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onCancel}>Cancel</button>
                <button className='btn orange shadow' onClick={props.onNext}>Next</button>
            </div>
        </div>
    )
}

export default Intro