import React, { Component } from 'react'
import './styles.scss'
import Autosuggest from 'react-autosuggest'
import { MdSearch } from 'react-icons/md'

export default class AutosuggestField extends Component {

    render() {
        return (
            <div className='AutosuggestField'>
                <MdSearch viewBox='0 0 20 20' className='search-icon'></MdSearch>
                <Autosuggest {...this.props} />
            </div>
        )
    }
}