export const FETCH_DASHBOARD_CATEGORY_AMOUNTS = 'FETCH_DASHBOARD_CATEGORY_AMOUNTS'
export const FETCH_DASHBOARD_CATEGORY_AMOUNTS_SUCCESS = 'FETCH_DASHBOARD_CATEGORY_AMOUNTS_SUCCESS'
export const FETCH_DASHBOARD_CATEGORY_AMOUNTS_ERROR = 'FETCH_DASHBOARD_CATEGORY_AMOUNTS_ERROR'

export const SET_DASHBOARD_CATEGORY_AMOUNTS = 'SET_DASHBOARD_CATEGORY_AMOUNTS'

export const SET_DASHBOARD_CATEGORY = 'SET_DASHBOARD_CATEGORY'

export const FETCH_DASHBOARD_ITEMS = 'FETCH_DASHBOARD_ITEMS'
export const FETCH_DASHBOARD_ITEMS_SUCCESS = 'FETCH_DASHBOARD_ITEMS_SUCCESS'
export const FETCH_DASHBOARD_ITEMS_ERROR = 'FETCH_DASHBOARD_ITEMS_ERROR'

export const SET_DASHBOARD_SEARCHVALUE = 'SET_DASHBOARD_SEARCHVALUE'

export const RESET_DASHBOARD_SEARCHED = 'RESET_DASHBOARD_SEARCHED'
export const RESET_DASHBOARD_CATEGORY = 'RESET_DASHBOARD_CATEGORY'

export const CLEAR_DASHBOARD_ITEMS = 'CLEAR_DASHBOARD_ITEMS'