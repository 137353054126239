import {
  CONNECTION_TO_STREAMING_SOCKET_ESTABLISHED,
  CONNECT_TO_STREAMING_SOCKET,
  CLOSE_STREAMING_SOCKET,
  STREAMING_ERROR
} from '../types/streaming'

const initialState = {
  socket: null,
  error: null,
  isLoading: false
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case CONNECT_TO_STREAMING_SOCKET:
      return { ...state, isLoading: true }
    case CONNECTION_TO_STREAMING_SOCKET_ESTABLISHED:
      return { ...state, isLoading: false, socket: payload.socket }
    case STREAMING_ERROR:
      return { ...state, error: payload.error }
    case CLOSE_STREAMING_SOCKET:
      return initialState
    default:
      return state
  }
}
