import {
  CONNECTION_TO_STREAMING_SOCKET_ESTABLISHED,
  CONNECT_TO_STREAMING_SOCKET,
  CLOSE_STREAMING_SOCKET,
  STREAMING_ERROR,
  MESSAGE_RECEIVED,

  SET_BATTERY_STATE_DATA,
  SET_DEVICE_FEED_DATA,
  SET_DIAGNOSTICS_LOG_DATA,
  SET_ERROR_LOG_DATA,
  SET_LIFT_OPERATION_STATE_DATA,

  C2D_CONNECTED_MESSAGE,

  SET_DISCONNECTED_TIMEOUT,
  SET_APP_CONNECTED_INTERVAL,
  SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING
} from '../types/streaming'

import {
  FETCH_SELECTED_PROFILE_DATA_SUCCESS,
} from '../types/selectedProfile'

import c2dService from '../../services/c2dService'
import selectedProfileActions from './selectedProfile'
import questionActions from './questions'
import profileService from '../../services/productsService'

const fetchProfileInfo = (profileId, dispatch) => new Promise((resolve, reject) => {
  dispatch(selectedProfileActions.fetchSelectedProfileData(profileId))
  resolve()
})

const connectToStreamingSocket = (assistId, version, token) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONNECT_TO_STREAMING_SOCKET })
    const socket = new WebSocket(`wss://streaming.brash-iot.com?assistId=${assistId}&version=${version}&authorization=${token}`)
    socket.onopen = () => {

      dispatch({ type: CONNECTION_TO_STREAMING_SOCKET_ESTABLISHED, socket })

      dispatch({
        type: SET_DISCONNECTED_TIMEOUT,
        disconnectedTimeout: setTimeout(() => {
          dispatch({ type: SET_DISCONNECTED_TIMEOUT, disconnectedTimeout: null })
        }, 60 * 1000) // 60 * 1000 = 1 min
      })
    }

    socket.onmessage = async (response) => {
      try {
        const st = getState()
        const messageData = JSON.parse(response.data)
        console.log('message from socket received', new Date())
        dispatch({ type: MESSAGE_RECEIVED })
        messageData.body.actions.forEach(action => {
          switch (action.type) {
            case 'liveView':
              dispatch({ type: SET_DEVICE_FEED_DATA, data: action.data })
              break
            case 'liftOperationState':
              dispatch({ type: SET_LIFT_OPERATION_STATE_DATA, data: action.data })
              break
            case 'batteryState':
              dispatch({ type: SET_BATTERY_STATE_DATA, data: action.data })
              break
            case 'advancedDiagnosticsData':
              if (action.data.readStatus
                && (action.data.readStatus.toLowerCase() === 'failed'
                  || action.data.readStatus.toLowerCase() === 'stopped')) {
                dispatch({ type: SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING, value: false })
              }
              dispatch({ type: SET_DIAGNOSTICS_LOG_DATA, data: action.data })
              break
            case 'errorLog':
              dispatch({ type: SET_ERROR_LOG_DATA, data: action.data })
              break
            case 'statisticUpdate':
              const { profileId } = st.selectedProfile.profile
              dispatch(selectedProfileActions.fetchLiftCycleHistory(profileId))
              profileService.fetchProfile(profileId).then(profile => {
                dispatch({ type: FETCH_SELECTED_PROFILE_DATA_SUCCESS, profile })
              })
              break
            default:
              console.log('Hello from default')
          }
        })

        const profileId = st.selectedProfile.profile.profileId

        fetchProfileInfo(profileId, dispatch).then(() => {
          dispatch(questionActions.fetchDiagnosticQuestion(profileId))
        })


        // review this part =======================
        // function pingConnectedMessage() {
        //   const selectedProfile = getState().selectedProfile

        //   if (selectedProfile.messageCounter < 5) {
        //     console.log('send c2d connected message')
        //     c2dService.sendConnecttionMessage(selectedProfile.profile.assistId)
        //       .then(() => {
        //         dispatch({ type: C2D_CONNECTED_MESSAGE })
        //       })
        //   } else {
        //     dispatch({ type: SET_APP_CONNECTED_INTERVAL, appConnectedInterval: null })
        //   }
        // }
        // pingConnectedMessage()

        // let appConnectedInterval = setInterval(pingConnectedMessage, 30 * 1000)
        // dispatch({ type: SET_APP_CONNECTED_INTERVAL, appConnectedInterval })
        // =========================================

        dispatch({
          type: SET_DISCONNECTED_TIMEOUT,
          disconnectedTimeout: setTimeout(() => {

            dispatch({ type: SET_DISCONNECTED_TIMEOUT, disconnectedTimeout: null })
          }, 15 * 1000)
        })

      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = (error) => {
      dispatch({ type: STREAMING_ERROR, error })
    }
  } catch (error) {
    dispatch({ type: STREAMING_ERROR, error })
  }
}

const closeStreamingSocket = (assistId) => async (dispatch, getState) => {
  const state = getState()

  if (state.streaming.socket) {
    state.streaming.socket.close()
    c2dService.sendDisconnectionMessage(assistId)
    dispatch({ type: CLOSE_STREAMING_SOCKET })
  }
}

const startSendingConnectedMessage = () => async (dispatch, getState) => {
  function pingConnectedMessage() {
    const selectedProfile = getState().selectedProfile
    if (selectedProfile.messageCounter < 5) {
      console.log('send c2d connected message')
      c2dService.sendConnecttionMessage(selectedProfile.profile.assistId).then(() => {
        dispatch({ type: C2D_CONNECTED_MESSAGE })
      })
    } else {
      dispatch({ type: SET_APP_CONNECTED_INTERVAL, appConnectedInterval: null })
    }
  }
  pingConnectedMessage()
  let appConnectedInterval = setInterval(pingConnectedMessage, 30 * 1000)
  dispatch({ type: SET_APP_CONNECTED_INTERVAL, appConnectedInterval })
}

const sendRenewAdvancedDiagnosticsData = (assistId) => async dispatch => {
  try {
    await c2dService.sendRenewMessage(assistId, 'advancedDiagnosticsData')
    dispatch({ type: SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING, value: true })
  } catch (error) {
    dispatch({ type: SET_ERROR_LOG_DATA, data: {} })
    dispatch({ type: SET_DIAGNOSTICS_LOG_DATA, data: {} })
    dispatch({ type: SET_ADVANCED_DIAGNOSTIC_DATA_AWAITING, value: false })
  }
}

const sendAssistData = (assistId, data) => async (dispatch, getState) => {
  try {
    const result = await c2dService.sendData(assistId, data)
    return result
  } catch (error) {
    console.error(error)
  }
}

export default {
  connectToStreamingSocket,
  closeStreamingSocket,
  startSendingConnectedMessage,
  sendRenewAdvancedDiagnosticsData,
  sendAssistData
}
