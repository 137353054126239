import axios from 'axios'
import authService from './auth'

const updatePassword = async (password) => {
    try {
        if (authService.getSession()) {
            await axios.patch(
                'password',
                {
                    password
                },
                {
                    baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL,
                    headers: {
                        Authorization: `Bearer ${authService.getSession().token.accessToken}`
                    }
                })
        }
    } catch (error) {
        throw error
    }
}

const sendForgotPasswordEmail = async (email) => {
    try {
        await axios.post('password/forgot', { email }, { baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL })
    } catch (error) {
        throw error
    }
}

const resetPassword = async (passCode, password) => {
    const userResponse = await axios.post('password/reset', { passCode, password }, { baseURL: process.env.REACT_APP_HANDICARE_AUTH_BASE_URL })
    return userResponse.data
}

export default {
    updatePassword,
    sendForgotPasswordEmail,
    resetPassword
}