import {
    FETCH_EDITABLE_FLEET_ADMIN_ERROR,
    FETCH_EDITABLE_FLEET_ADMIN_INFO,
    FETCH_EDITABLE_FLEET_ADMIN_INFO_SUCCESS,
    CLEAR_EDITABLE_FLEET_ADMIN,
    SAVE_EDITABLE_FLEET_ADMIN,
    SET_EDITABLE_FLEET_ADMIN_INFO
} from '../types/editableFleetAdmin'
import usersService from '../../services/usersService'
import { reset } from 'redux-form'
import userRole from '../../constants/userRole'
import diff from 'object-diff'
import { objectPropsEmptyToNull } from '../../utils/objectPropsEmptyToNull'

const saveFleetAdmin = (fleetAdminId, fleetAdminData) => async dispatch => {
    try {
        let fleetAdmin = null

        if (fleetAdminId === 'new') {
            const isSignUpCodeExisting = await usersService.checkSignUpCode(fleetAdminData.signUpCode)

            if (isSignUpCodeExisting) {
                throw new Error('User with the same sign up code has already existed.')
            }

            const newFleetAdmin = {
                role: userRole.fleetAdmin,
                isActive: true,
                ...fleetAdminData,
                confirmPassword: undefined // clear field from fleetAdminData
            }

            newFleetAdmin.mobilePhone === '+' && delete newFleetAdmin.mobilePhone
            newFleetAdmin.phone === '+' && delete newFleetAdmin.phone

            fleetAdmin = await usersService.createUser(newFleetAdmin)
        } else {
            const existingFleetAdmin = await usersService.getUser(fleetAdminId)

            delete fleetAdminData.localAdminEmail

            objectPropsEmptyToNull(fleetAdminData)
            fleetAdminData.phone = fleetAdminData.phone === '+' ? null : fleetAdminData.phone
            fleetAdminData.mobilePhone = fleetAdminData.mobilePhone === '+' ? null : fleetAdminData.mobilePhone
            
            const editedFleetAdmin = {
                id: fleetAdminId,
                ...diff(existingFleetAdmin, fleetAdminData)
            }

            fleetAdmin = await usersService.updateUser(editedFleetAdmin)
        }

        dispatch({ type: SAVE_EDITABLE_FLEET_ADMIN, fleetAdmin })
    } catch (error) {
        dispatch({ type: FETCH_EDITABLE_FLEET_ADMIN_ERROR, error })
        throw error
    }
}

const selectFleetAdminInfo = (fleetAdminId) => async dispatch => {
    try {
        dispatch({ type: FETCH_EDITABLE_FLEET_ADMIN_INFO })
        const user = await usersService.getUser(fleetAdminId)

        await setLocalAdminEmail(user)

        if (user.role !== userRole.fleetAdmin) {
            throw new Error('Selected user is not a Fleet Admin.')
        }

        dispatch({ type: FETCH_EDITABLE_FLEET_ADMIN_INFO_SUCCESS, fleetAdmin: user })
    } catch (error) {
        dispatch({ type: FETCH_EDITABLE_FLEET_ADMIN_ERROR, error })
        throw error
    }
}

const clearFleetAdminInfo = () => async dispatch => {
    dispatch({ type: CLEAR_EDITABLE_FLEET_ADMIN })
}

const resetEditableFleetAdminFormValues = () => async dispatch => {
    dispatch(reset('editableFleetAdminInformationForm'))
}

const setEditableFleetAdminInfo = (data) => async dispatch => {
    await setLocalAdminEmail(data)

    dispatch({
        type: SET_EDITABLE_FLEET_ADMIN_INFO,
        data
    })
}

export default {
    saveFleetAdmin,
    selectFleetAdminInfo,
    clearFleetAdminInfo,
    resetEditableFleetAdminFormValues,
    setEditableFleetAdminInfo
}

const setLocalAdminEmail = async (user) => {
    let localAdminEmail = 'No Regional Admin'

    try {
        if (user.localAdminId) {
            const localAdmin = await usersService.getUser(user.localAdminId)
            localAdminEmail = localAdmin.email
        }
    } catch (error) {
        localAdminEmail = "Regional admin wasn't found"
    }

    user.localAdminEmail = localAdminEmail
}