import http from './http'

const fetchQuestions = async (profileId) => {
  try {
    const response = await http.get(`diagnosticsQuestions/${profileId}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export default {
  fetchQuestions
}
