import http from './http'

const sendConnecttionMessage = async (assistId) => {
  try {
    await http.post(`c2d/${assistId}/connected`)
  } catch (error) {
    console.log(error)
  }
}

const sendDisconnectionMessage = async (assistId) => {
  try {
    await http.post(`c2d/${assistId}/disconnected`)
  } catch (error) {
    console.log(error)
  }
}

const sendRenewMessage = async (assistId, field) => {
  await http.post(`c2d/${assistId}/renew/${field}`)
}

const sendData = async (assistId, data) => {
  const response = await http.post(`c2d/${assistId}/send`, data)
  return response.data
}

// {"assignmentRequest":"unassign"}

export default {
  sendConnecttionMessage,
  sendDisconnectionMessage,
  sendRenewMessage,
  sendData
}
