import React from 'react'
import './styles.scss'
import categories from '../../constants/dashboardCategories'
import authService, { rolePriorities } from '../../services/auth'

const names = {
    fleetAdmins: 'Fleet Admins',
    users: 'Users',
    localAdmins: 'Regional Admins',
    fleetManagers: 'Fleet Managers'
}

const colors = {
    fleetAdmins: 'yellow',
    users: 'green',
    localAdmins: 'purple',
    fleetManagers: 'blue'
}

const initials = {
    fleetAdmins: 'FA',
    users: 'U',
    localAdmins: 'RA',
    fleetManagers: 'FM'
}

class DashboardCategorySelect extends React.Component {

    state = {
        isOpen: false
    }

    handleButtonClick = () => {
        this.setState((state) => ({
            isOpen: !state.isOpen
        }))
    }

    handleCategoryClick = (category) => {
        this.setState({ isOpen: false })
        this.props.onSelect(category)
    }

    render() {
        const { isOpen } = this.state
        const { selectedCategory } = this.props

        return (
            <div className='DashboardCategorySelect'>
                <div className={`circle-btn ${colors[selectedCategory]}`} onClick={() => this.handleButtonClick()}>
                    <div className='title'>
                        {initials[selectedCategory]}
                    </div>
                </div>
                <div className={`category-options ${isOpen ? 'show' : ''}`}>
                    {
                        rolePriorities[authService.getCurrentUserRole()] >= rolePriorities.fleetManager
                            ? <div className='category-options-item' onClick={() => this.handleCategoryClick(categories.users)}>
                                <div className='circle-icon green'>{initials.users}</div>
                                <div className='category-name'>{names.users}</div>
                            </div>
                            : <></>
                    }
                    {
                        rolePriorities[authService.getCurrentUserRole()] >= rolePriorities.fleetAdmin
                            ? <div className='category-options-item' onClick={() => this.handleCategoryClick(categories.fleetManagers)}>
                                <div className='circle-icon blue'>{initials.fleetManagers}</div>
                                <div className='category-name'>{names.fleetManagers}</div>
                            </div>
                            : <></>
                    }
                    {
                        rolePriorities[authService.getCurrentUserRole()] >= rolePriorities.localAdmin
                            ? <div className='category-options-item' onClick={() => this.handleCategoryClick(categories.fleetAdmins)}>
                                <div className='circle-icon yellow'>{initials.fleetAdmins}</div>
                                <div className='category-name'>{names.fleetAdmins}</div>
                            </div>
                            : <></>
                    }
                    {
                        rolePriorities[authService.getCurrentUserRole()] >= rolePriorities.globalAdmin
                            ? <div className='category-options-item' onClick={() => this.handleCategoryClick(categories.localAdmins)}>
                                <div className='circle-icon purple'>{initials.localAdmins}</div>
                                <div className='category-name'>{names.localAdmins}</div>
                            </div>
                            : <></>
                    }
                </div>
            </div>
        )
    }
}

export default DashboardCategorySelect