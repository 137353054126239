import React, { useState } from 'react'
import './styles.scss'

const EnterLoginEmail = (props) => {

    const [loginEmail, setloginEmail] = useState('')

    const { onSubmit, onCancel } = props

    const handleSubmit = (event) => {
        event.preventDefault()
        onSubmit(loginEmail)
    }

    const handleCancel = (event) => {
        event.preventDefault()
        onCancel()
    }

    return (
        <div className='EnterLoginEmail'>
            <form onSubmit={handleSubmit}>
                <h4>Enter Login Email</h4>
                <input onChange={e => setloginEmail(e.target.value)} value={loginEmail} type='text' />
                <div className='help-label'>
                    Enter the email used for your account. A temporary password will be sent to your email.
                </div>
                <input type='submit' disabled={!loginEmail} value='Enter' className={`btn yellow big-radius shadow`} />
                <input type='button' value='Cancel' className={`btn gray-m big-radius shadow`} onClick={handleCancel} />
            </form>
        </div>
    )
}

export default EnterLoginEmail
