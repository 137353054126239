import {
  SET_LOGOUT_MENU_OPEN_STATE,
  SET_DEVICE_LOGO_MENU_OPEN_STATE,
  SET_DEVICE_FEED_HEADER_SLIDER,
  SET_DEVICE_FEED_CONTENT_SLIDER,
  SET_SNACKBAR_REF,
  CHECK_SIGN_UP_CODE_EXISTENCE,
  SET_SIGN_UP_CODE_VALIDATION,
  SET_SHOW_REGISTRATION_APPROVE_POP_UP
} from '../types/common'
import usersService from '../../services/usersService'
import history from '../../history'
import openProfileService from '../../services/openProfileService'

const setLogoutMenuOpenState = (state) => async dispatch => {
  dispatch({ type: SET_LOGOUT_MENU_OPEN_STATE, logoutMenuOpen: state })
  dispatch({ type: SET_DEVICE_LOGO_MENU_OPEN_STATE, deviceLogoMenuOpen: false })
}

const setDeviceLogoMenuOpenState = (state) => async dispatch => {
  dispatch({ type: SET_DEVICE_LOGO_MENU_OPEN_STATE, deviceLogoMenuOpen: state })
  dispatch({ type: SET_LOGOUT_MENU_OPEN_STATE, logoutMenuOpen: false })
}

const setDeviceFeedHeaderSlider = (slider) => async dispatch => {
  dispatch({ type: SET_DEVICE_FEED_HEADER_SLIDER, slider })
}

const setDeviceFeedContentSlider = (slider) => async dispatch => {
  dispatch({ type: SET_DEVICE_FEED_CONTENT_SLIDER, slider })
}

const setSnackbarRef = (snackbarRef) => async dispatch => {
  dispatch({ type: SET_SNACKBAR_REF, snackbarRef })
}

const submitSignUpCode = (value) => async dispatch => {
  try {
    dispatch({ type: CHECK_SIGN_UP_CODE_EXISTENCE })
    const isValidCode = await usersService.checkSignUpCode(value)

    let isValidSerial = false

    if (!isValidCode) {
      isValidSerial = !!(await openProfileService.getOpenProfileBySerialNumber(value)).profileId
    }

    dispatch({ type: SET_SIGN_UP_CODE_VALIDATION, isValid: isValidCode || isValidSerial })
    if (isValidCode) {
      history.push('/fleetManager/new', { signUpCode: value })
      return
    }

    if (isValidSerial) {
      history.push('/sign-up-user', { serialNumber: value })
      return
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: SET_SIGN_UP_CODE_VALIDATION, isValid: false }) // if error we ignore and set always valid to false
  }
}

const resetSignUpCodeLabel = () => async dispatch => {
  dispatch({ type: SET_SIGN_UP_CODE_VALIDATION, isValid: true })
}

const setShowRegistrationApprovePopUp = (value, text = '') => async dispatch => {
  dispatch({
    type: SET_SHOW_REGISTRATION_APPROVE_POP_UP,
    value,
    text
  })
}

export default {
  setLogoutMenuOpenState,
  setDeviceLogoMenuOpenState,
  setDeviceFeedHeaderSlider,
  setDeviceFeedContentSlider,
  setSnackbarRef,
  submitSignUpCode,
  resetSignUpCodeLabel,
  setShowRegistrationApprovePopUp
}
