import React, { useState } from 'react'
import './styles.scss'
import CheckSwitcher from '../../CheckSwitcher/CheckSwitcher'

const EditableResetPMForm = (props) => {

    const [nextServiceDate, setNextServiceDate] = useState('')
    const [strapReplacement, setStrapReplacement] = useState(false)
    const [batteryReplacement, setBatteryReplacement] = useState(false)

    const onNextHandler = () => {
        const data = {
            nextServiceDate,
            strapReplacement,
            batteryReplacement
        }

        props.onNext(data)
    }

    const getNextButtonDisabled = () => {
        return !nextServiceDate
    }

    return (
        <div className='EditableResetPMForm'>
            <label htmlFor='reset-pm-next-service-date'>Next Service Date:</label>
            <input id='reset-pm-next-service-date' type='date' value={nextServiceDate} onChange={e => setNextServiceDate(e.target.value)}></input>
            <label htmlFor='reset-pm-strap-replacement'>Strap Replacement:</label>
            <CheckSwitcher id='reset-pm-strap-replacement' value={strapReplacement} onChange={e => setStrapReplacement(e.target.checked)} />
            <label htmlFor='reset-pm-battery-replacement'>Battery Replacement:</label>
            <CheckSwitcher id='reset-pm-battery-replacement' value={batteryReplacement} onChange={e => setBatteryReplacement(e.target.checked)} />
            <div className='btns-container'>
                <button className='btn orange shadow' onClick={props.onBack}>Back</button>
                <button className='btn orange shadow' onClick={onNextHandler} disabled={getNextButtonDisabled()}>Next</button>
            </div>
        </div>
    )
}

export default EditableResetPMForm