import React from 'react'
import './styles.scss'

const GREEN = '#30D181'
const YELLOW = '#FFDE00'
const RED = '#f00'

const BatteryIndicator = (props) => {
  const { batteryState, messageReceived } = props
  const color = batteryState == null
    ? null
    : (batteryState.chargeLevel > 60
      ? GREEN
      : batteryState.chargeLevel > 30
        ? YELLOW
        : RED)

  return (
    <div id='charge'>
      <div className='battery'>
        <div className='cover' />
        <div className='progressbar' style={{ width: messageReceived && batteryState && batteryState.chargeLevel ? `${batteryState.chargeLevel}%` : 0, backgroundColor: color }} />
      </div>
      <div className='charge-value'>{messageReceived && batteryState && batteryState.chargeStateText ? batteryState.chargeStateText : ''}</div>
      {
        batteryState && batteryState.isCharging && messageReceived && batteryState.chargeLevel
          ? <span className='icon-flash' />
          : batteryState && messageReceived && batteryState.chargeLevel
            ? ''
            : '?'
      }
    </div>
  )
}

export default BatteryIndicator
