import http from './http'

const checkPassword = async (profileId, password) => {
    const response = await http.post(`actions/profile/${profileId}/password`, { password })
    return response.data
}

const resetPM = async (profileId, resetPMData) => {
    const response = await http.post(`actions/profile/${profileId}/resetpm`, resetPMData)
    return response.data
}

export default {
    checkPassword,
    resetPM
}
