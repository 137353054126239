import React, { useState } from 'react'
import './styles.scss'
import { Link, withRouter, Redirect } from 'react-router-dom'
import EditableFleetManagerForm from './EditableFleetManagerForm/EditableFleetManagerForm'
import history from '../../history'

const EditableFleetManagerInformation = props => {

    const [title, setTitle] = useState('Loading...')
    // add check for userId in condition
    if (!props.match.params.userId
        && !(history.location
            && history.location.state
            && history.location.state.signUpCode)) {
        return <Redirect to='/login' />
    }

    return (
        <div className='EditableFleetManagerInformation'>
            <div className="back">
                <Link to='/login' />
                <div className="wide-container">
                    <div className="h1">
                        {
                            !props.match.params.userId
                                ? 'New Fleet Manager Profile'
                                : title
                        }
                    </div>
                </div>
            </div>
            <div className="wide-container profile-block">
                <EditableFleetManagerForm userId={props.match.params.userId}
                    updateTitle={setTitle}
                    signUpCode={(history.location
                        && history.location.state
                        && history.location.state.signUpCode) || null} />
            </div>
        </div>
    )
}

export default withRouter(EditableFleetManagerInformation)
