import React from 'react'
import './styles.scss'
import { Link, withRouter } from 'react-router-dom'
import BriefServiceHistoryItem from '../../components/BriefServiceHistoryItem/briefServiceHistoryItem'
import LoadingSpinner from '../../components/LoadingSpinner/loadingSpinner'

const BriefServiceHistory = (props) => {
  const { reports, repairTypes, loading } = props

  return (
    <div id='brief-service-history'>
      <div className='col'>
        <div className='add-container'>
          <Link to={`${props.match.url}/service-history/new`}>
            <button className='btn orange small'>
              <span className='plus'>New Report</span>
            </button>
          </Link>
        </div>
        {
          loading
            ? <LoadingSpinner />
            : <div className='history-list'>
              {
                reports.length
                  ? reports.slice(0, 3).map((report, i) =>
                    <BriefServiceHistoryItem key={report.serviceReportId}
                      report={report}
                      number={reports.length - i}
                      repairTypes={repairTypes} />)
                  : <h1 className='no-info'>No info...</h1>
              }
            </div>
        }
      </div>
    </div>
  )
}

export default withRouter(BriefServiceHistory)
