import {
  FETCH_SELCETED_REPORT,
  FETCH_SELECTED_REPORT_SUCCESS,
  SAVE_SELECTED_REPORT,
  SELECTED_REPORT_ERROR
} from '../types/serviceHistoryItem'
import serviceReportsService from '../../services/serviceReportsService'

const selectReport = (reportId) => async dispatch => {
  try {
    dispatch({ type: FETCH_SELCETED_REPORT })
    let report = null
    const repairTypes = await serviceReportsService.fetchRepairTypes()
    if (reportId === 'new') {
      report = {
        reportDate: new Date(),
        repairs: { ids: [] }
      }
    } else {
      if (!reportId) {
        throw new Error('reportId cannot be null or undefined')
      }

      report = await serviceReportsService.fetchServiceReport(reportId)
    }
    dispatch({ type: FETCH_SELECTED_REPORT_SUCCESS, report, repairTypes })
  } catch (error) {
    dispatch({ type: SELECTED_REPORT_ERROR, error })
  }
}

const saveReport = (reportId, report) => async dispatch => {
  try {
    let newReport = null
    if (reportId === 'new') {
      newReport = await serviceReportsService.createServiceReport(report)
    } else {
      newReport = await serviceReportsService.updateServiceReport(report)
    }
    dispatch({ type: SAVE_SELECTED_REPORT, report: newReport })
  } catch (error) {
    dispatch({ type: SELECTED_REPORT_ERROR, error })
  }
}

export default {
  selectReport,
  saveReport
}
