import authService from '../../services/auth'
import {
  LOGOUT,
  UPDATE_SESSION
} from '../types/auth'
import {
  SET_DEVICE_LOGO_MENU_OPEN_STATE,
  SET_LOGOUT_MENU_OPEN_STATE
} from '../types/common'
import {
  CLEAR_DASHBOARD_ITEMS
} from '../types/dashboard'

const RENEW_LIMIT = 5 // Limit to refresh token in minutes

const requestMiddleware = ({ dispatch, getState }) => next => async action => {
  if (typeof action === 'function') {
    const state = getState()
    const stateSession = state.auth.session
    const sessionToRefresh = stateSession || authService.getSession()

    if (sessionToRefresh && sessionToRefresh.expirationDateTime) {
      const expDate = new Date(sessionToRefresh.expirationDateTime)
      const currentDate = new Date()

      if (expDate <= currentDate || (expDate - currentDate) / 1000 <= RENEW_LIMIT) {
        try {
          const newSession = await authService.refreshAuth()
          dispatch({ type: UPDATE_SESSION, session: newSession })
        } catch (error) {
          console.error(error)
          authService.logout()
          dispatch({ type: LOGOUT })
          dispatch({ type: SET_DEVICE_LOGO_MENU_OPEN_STATE, deviceLogoMenuOpen: false })
          dispatch({ type: SET_LOGOUT_MENU_OPEN_STATE, logoutMenuOpen: false })
          dispatch({ type: CLEAR_DASHBOARD_ITEMS })
        }
      }
    }
  }
  return next(action)
}

export default requestMiddleware
