import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT
} from '../types/auth'
import {
  SET_DEVICE_LOGO_MENU_OPEN_STATE,
  SET_LOGOUT_MENU_OPEN_STATE,
} from '../types/common'
import auth from '../../services/auth'
import usersService from '../../services/usersService'
import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
} from '../types/users'
import {
  SET_DASHBOARD_CATEGORY_AMOUNTS,
  SET_DASHBOARD_SEARCHVALUE,
  CLEAR_DASHBOARD_ITEMS,
  RESET_DASHBOARD_SEARCHED,
  RESET_DASHBOARD_CATEGORY
} from '../types/dashboard'

const login = (username, password) => async dispatch => {
  try {
    dispatch({ type: LOGIN, username, password })
    const session = await auth.login(username, password)
    dispatch({ type: LOGIN_SUCCESS, session })
  } catch (error) {
    dispatch({ type: LOGIN_ERROR })
    if (!(error instanceof Error)) {
      throw new Error(error.message)
    } else {
      throw new Error('Some errors occured. Please Try again.')
    }
  }
}

const logout = () => async dispatch => {
  auth.logout()
  dispatch({ type: LOGOUT })
  dispatch({ type: SET_DEVICE_LOGO_MENU_OPEN_STATE, deviceLogoMenuOpen: false })
  dispatch({ type: SET_LOGOUT_MENU_OPEN_STATE, logoutMenuOpen: false })
  dispatch({ type: SET_DASHBOARD_SEARCHVALUE, value: '' })
  dispatch({ type: SET_DASHBOARD_CATEGORY_AMOUNTS, categoryAmounts: {} })
  dispatch({ type: CLEAR_DASHBOARD_ITEMS })
  dispatch({ type: RESET_DASHBOARD_SEARCHED })
  dispatch({ type: RESET_DASHBOARD_CATEGORY })
}
const signUp = (accountData) => async dispatch => {
  try {
    dispatch({ type: CREATE_USER })

    const newUser = {
      mobilePhone: accountData.contactPhoneNumber,
      firstName: accountData.firstName,
      lastName: accountData.lastName,
      isActive: false,
      signUpCode: accountData.signUpCode,
      phone: accountData.alertPhoneNumber,
      email: accountData.email,
      role: 'fleetManager',
      password: accountData.password
    }

    await usersService.createUser(newUser)

    dispatch({ type: CREATE_USER_SUCCESS })
  } catch (error) {
    throw new Error(error.message)
  }
}

export default {
  login,
  logout,
  signUp
}
