import React from 'react'
import './styles.scss'
import moment from 'moment'

const BriefServiceHistoryItem = (props) => {
  const { report, repairTypes, number } = props

  return (
    <div className='brief-service-history-item'>
      <div className='work-types'>
        {
          report.repairs.ids.length > 1
            ? <>
              {
                report.repairs.ids.slice(0, 1).map(repairId => <span key={`${report.serviceReportId}-${repairId}`} className='work-type'>{repairTypes.find(t => t.repairTypeId === repairId).name}</span>)
              }
              <span className='work-type-more'>+MORE</span>
            </>
            : report.repairs.ids.map(repairId => <span key={`${report.serviceReportId}-${repairId}`} className='work-type'>{repairTypes.find(t => t.repairTypeId === repairId).name}</span>)
        }
      </div>
      <div className='info'>
        <span className='number'>{`${number}.`}</span>
        <span className='date'>{moment(report.reportDate).format('LL')}</span>
      </div>
    </div>
  )
}

export default BriefServiceHistoryItem
