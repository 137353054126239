import React, { Component } from 'react'
import './styles.scss'
import BatteryIndicator from '../../components/BatteryIndicator/batteryIndicator'
import DeviceToolBarLogo from '../DeviceToolBarLogo/deviceToolBarLogo'
import authActions from '../../store/actions/auth'
import commonActions from '../../store/actions/common'
import { connect } from 'react-redux'
import ConfirmationDialog from '../../components/ConfirmationDialog/confirmationDialog'
import { chargeMessage } from '../../store/selectors/index'

const stateToProps = (state) => ({
  logoutMenuOpen: state.common.logoutMenuOpen,
  auth: state.auth,
  selectedProfile: state.selectedProfile,
  chargeMessage: chargeMessage(state)
})

const dispatchToProps = {
  setLogoutMenuOpenState: commonActions.setLogoutMenuOpenState,
  logout: authActions.logout
}

const YELLOW = '#FFDE00'
const RED = '#f00'

class ToolBarHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogoutConfirmation: false
    }
  }

  handleCloseLogoutConfirmation = (val) => {
    if (val) {
      this.props.logout()
    }
    this.setState({ showLogoutConfirmation: false })
  }

  render() {
    const { logo, header, onGearClick, selectedProfile } = this.props

    const classes = `settings ${this.props.logoutMenuOpen ? 'open' : ''}`

    const messageColor = !selectedProfile.isConnected || !selectedProfile.messageReceived // trying to connect
      || (selectedProfile.liftOperationStateMessageReceived && selectedProfile.liftOperationState.v === 'WAIT')
      ? YELLOW
      : (selectedProfile.liftOperationStateMessageReceived && selectedProfile.liftOperationState.v === 'ERR')
        ? RED
        : null

    return (
      <>
        <ConfirmationDialog title='Are you sure you would like to Log Out?'
          show={this.state.showLogoutConfirmation}
          onOk={() => this.handleCloseLogoutConfirmation(true)}
          onCancel={() => this.handleCloseLogoutConfirmation(false)} />
        <header id='toolbar-header' className='shadow'>
          <div className='container'>
            {/* <!-- для открытии меню устройстка (div c классом device) или меню в шестиренке справа вверху (класс settings) к этим блокам надо добавить класс "open", например, чтобы открыть меню устройства надо добавить, как писалось выше, к body класс "overlay" а к блоку с классом "device" еще класс "open" (<div class="device open">)--> */}
            <DeviceToolBarLogo disconnected={!selectedProfile.isConnected
              || !selectedProfile.messageReceived
              || (selectedProfile.liftOperationStateMessageReceived
                && selectedProfile.liftOperationState.v === 'WAIT')}
              imgSrc={logo} serialNumber={header} />
            <div className='toolbar'>
              <div className='text' style={{ color: messageColor }}>
                {
                  this.props.chargeMessage
                }
              </div>
              <BatteryIndicator batteryState={selectedProfile.batteryState}
                messageReceived={selectedProfile.messageReceived} />
              <div className={classes} onClick={() => this.props.setLogoutMenuOpenState(!this.props.logoutMenuOpen)}>
                <span className='icon-gear' onClick={onGearClick} />
                <div className='settings-menu'>
                  <ul>
                    {
                      // eslint-disable-next-line
                    }<li><a onClick={() => this.setState({ showLogoutConfirmation: true })}>Log Out</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(ToolBarHeader)
