export default [
    {
        key: 1,
        value: 'No',
        title: 'No'
    },
    {
        key: 2,
        value: 'Parts Only',
        title: 'Parts Only'
    },
    {
        key: 3,
        value: 'Labour Only',
        title: 'Labour Only'
    },
    {
        key: 4,
        value: 'Parts + Labour',
        title: 'Parts + Labour'
    }
]
