import {
  FETCH_EDITABLE_PROFILE_INFO,
  FETCH_EDITABLE_PROFILE_INFO_SUCCESS,
  EDITABLE_PROFILE_ERROR,
  SAVE_EDITABLE_PROFILE,
  CLEAR_EDITABLE_PROFILE,
  FETCH_MODEL_NUMBERS_SUCCESS,
  SET_EDITABLE_PROFILE_CLIENT_INFO,
  CHANGE_SUGGESTION_INPUT_VALUE,
  CLEAR_AUTOSUGGEST,
  FETCH_EDITABLE_PROFILES_SUGGESTIONS_SUCCESS,
  FETCH_EDITABLE_PROFILES_SUGGESTIONS
} from '../types/editableProfile'
import moment from 'moment'
import clientService from '../../services/clientsService'
import emergencyContactsService from '../../services/emergencyContactsService'
import profileService from '../../services/productsService'
import { reset } from 'redux-form'
import modelNumbersService from '../../services/modelNumbers'
import usersService from '../../services/usersService'
import dashboardService from '../../services/dashboard'
import { initialUserSuggestions } from '../reducers/editableProfile'
import { objectPropsEmptyToNull } from '../../utils/objectPropsEmptyToNull'

const selectProfileInfo = (profileId) => async dispatch => {
  try {
    dispatch({ type: FETCH_EDITABLE_PROFILE_INFO })

    const modelNumbers = await modelNumbersService.getModelNumbers()
    dispatch({ type: FETCH_MODEL_NUMBERS_SUCCESS, modelNumbers })

    let data = null
    if (profileId === 'new') {
      data = {
        profile: {
          warrantyCoverage: 'No',
          profileTypeId: modelNumbers[0].profileTypeId,
          modelNumber: modelNumbers[0].name
        },
        client: {},
        emergency: {},
        alertOptions: {}
      }
    } else {
      const profile = await profileService.fetchProfile(profileId)

      const result = await Promise.all([
        clientService.fetchClient(profile.userId),
        emergencyContactsService.fetchEmergencyContact(profile.emergencyContactId)
      ])
      const client = result[0]
      const emergency = result[1]

      await setFleetAdminEmail(client)

      data = {
        profile: {
          ...profile,
          warrantyCoverage: profile.warrantyCoverage,
          warrantyEndDate: profile.warrantyEndDate ? moment(profile.warrantyEndDate).format('YYYY-MM-DD') : null,
          installationDate: profile.installationDate ? moment(profile.installationDate).format('YYYY-MM-DD') : null,
          productionDate: profile.productionDate ? moment(profile.productionDate).format('YYYY-MM-DD') : null,
          nextServiceDate: profile.nextServiceDate ? moment(profile.nextServiceDate).format('YYYY-MM-DD') : null
        },
        client,
        emergency,
        alertOptions: {
          clientSmsAlerts: profile.alertOptions.sms && profile.alertOptions.sms.includes('client'),
          fleetAdminSmsAlerts: profile.alertOptions.sms && profile.alertOptions.sms.includes('dealer'),
          emergencySmsAlerts: profile.alertOptions.sms && profile.alertOptions.sms.includes('emergency'),
          clientEmailAlerts: profile.alertOptions.email && profile.alertOptions.email.includes('client'),
          fleetAdminEmailAlerts: profile.alertOptions.email && profile.alertOptions.email.includes('dealer'),
          emergencyEmailAlerts: profile.alertOptions.email && profile.alertOptions.email.includes('emergency')
        }
      }
    }
    dispatch({ type: FETCH_EDITABLE_PROFILE_INFO_SUCCESS, data })
  } catch (error) {
    dispatch({ type: EDITABLE_PROFILE_ERROR, error })
  }
}

const saveProfile = (profileId, profileData) => async (dispatch) => {
  try {
    // if profile with same serial number existed
    const profile = await profileService.fetchProfileBySerialNumber(profileData.profile.serialNumber)
    if (profileId === 'new' && profile) {
      throw new Error('Profile with the same serial number has already existed')
    }

    if (profileId === 'new') {
      const newClient = {
        ...profileData.client,
        clientTypeId: 1,
        phone: profileData.client.phone === '+' ? null : profileData.client.phone,
        mobilePhone: profileData.client.mobilePhone === '+' ? null : profileData.client.mobilePhone
      }

      let newClientPromise

      if (newClient.userType === 'facility') {
        delete newClient.email
        delete newClient.id
        newClientPromise = clientService.createClient(newClient)
      } else {
        newClientPromise = new Promise((resolve, reject) => resolve(newClient))
      }

      const newEmergencyContact = {
        ...profileData.emergency,
        cellPhone: profileData.emergency.cellPhone === '+' ? null : profileData.emergency.cellPhone
      }

      const newEmergencyContactPromise = emergencyContactsService.createEmergencyContact(newEmergencyContact)

      const newClientAndEmergency = await Promise.all([
        newClientPromise,
        newEmergencyContactPromise
      ])

      const newSmsAlertOptions = []
      profileData.alertOptions && profileData.alertOptions.clientSmsAlerts && newSmsAlertOptions.push('client')
      profileData.alertOptions && profileData.alertOptions.fleetAdminSmsAlerts && newSmsAlertOptions.push('dealer')
      profileData.alertOptions && profileData.alertOptions.emergencySmsAlerts && newSmsAlertOptions.push('emergency')

      const newEmailAlertOptions = []
      profileData.alertOptions && profileData.alertOptions.clientEmailAlerts && newEmailAlertOptions.push('client')
      profileData.alertOptions && profileData.alertOptions.fleetAdminEmailAlerts && newEmailAlertOptions.push('dealer')
      profileData.alertOptions && profileData.alertOptions.emergencyEmailAlerts && newEmailAlertOptions.push('emergency')

      const newProfile = {
        ...profileData.profile,
        profileTypeId: +profileData.profile.profileTypeId,
        userId: newClientAndEmergency[0].id,
        emergencyContactId: newClientAndEmergency[1].emergencyContactId,
        warrantyCoverage: profileData.profile.warrantyCoverage,
        warrantyEndDate: profileData.profile.warrantyEndDate ? new Date(profileData.profile.warrantyEndDate) : null,
        installationDate: profileData.profile.installationDate ? new Date(profileData.profile.installationDate) : null,
        productionDate: profileData.profile.productionDate ? new Date(profileData.profile.productionDate) : null,
        nextServiceDate: profileData.profile.nextServiceDate ? new Date(profileData.profile.nextServiceDate) : null,
        alertOptions: {
          sms: newSmsAlertOptions,
          email: newEmailAlertOptions
        }
      }

      const profile = await profileService.createProfile(newProfile)

      dispatch({ type: SAVE_EDITABLE_PROFILE, data: { profile, client: newClientAndEmergency[0], emergency: newClientAndEmergency[1] } })
    } else {
      const profile = await profileService.fetchProfile(profileId)

      delete profileData.client.fleetAdminEmail

      objectPropsEmptyToNull(profileData.client)

      const newClient = {
        ...profileData.client,
        clientTypeId: 1,
        id: profile.userId,
        phone: profileData.client.phone === '+' ? null : profileData.client.phone,
        mobilePhone: profileData.client.mobilePhone === '+' ? null : profileData.client.mobilePhone
      }

      const clientPromise = clientService.updateClient(newClient)

      objectPropsEmptyToNull(profileData.emergency)

      const newEmergencyContact = {
        ...profileData.emergency,
        emergencyContactId: profile.emergencyContactId,
        cellPhone: profileData.emergency.cellPhone === '+' ? null : profileData.emergency.cellPhone
      }

      const emergencyContactPromise = emergencyContactsService.updateEmergencyContact(newEmergencyContact)

      const newSmsAlertOptions = []
      profileData.alertOptions.clientSmsAlerts && newSmsAlertOptions.push('client')
      profileData.alertOptions.fleetAdminSmsAlerts && newSmsAlertOptions.push('dealer')
      profileData.alertOptions.emergencySmsAlerts && newSmsAlertOptions.push('emergency')

      const newEmailAlertOptions = []
      profileData.alertOptions.clientEmailAlerts && newEmailAlertOptions.push('client')
      profileData.alertOptions.fleetAdminEmailAlerts && newEmailAlertOptions.push('dealer')
      profileData.alertOptions.emergencyEmailAlerts && newEmailAlertOptions.push('emergency')

      const newProfile = {
        ...profileData.profile,
        profileTypeId: +profileData.profile.profileTypeId,
        profileId: +profileId,
        userId: profile.userId,
        warrantyCoverage: profileData.profile.warrantyCoverage,
        warrantyEndDate: profileData.profile.warrantyEndDate ? new Date(profileData.profile.warrantyEndDate) : null,
        installationDate: profileData.profile.installationDate ? new Date(profileData.profile.installationDate) : null,
        productionDate: profileData.profile.productionDate ? new Date(profileData.profile.productionDate) : null,
        nextServiceDate: profileData.profile.nextServiceDate ? new Date(profileData.profile.nextServiceDate) : null,
        emergencyContactId: profile.emergencyContactId,
        alertOptions: {
          sms: newSmsAlertOptions,
          email: newEmailAlertOptions
        }
      }

      // temp fix
      delete newProfile.totalLiftsLastWeek

      const profilePromise = profileService.updateProfile(newProfile)

      const result = await Promise.all([
        clientPromise,
        emergencyContactPromise,
        profilePromise
      ])

      dispatch({ type: SAVE_EDITABLE_PROFILE, data: { profile: result[2], client: result[0], emergency: result[1] } })
    }
  } catch (error) {
    dispatch({ type: EDITABLE_PROFILE_ERROR, error })
    throw error
  }
}

const resetEditableProfileFormValues = () => async dispatch => {
  dispatch(reset('editableProfileForm'))
}

const removeProfile = (profileId) => async dispatch => {
  await profileService.removeProfile(profileId)
}

const clearEditableProfile = () => async dispatch => {
  dispatch({ type: CLEAR_EDITABLE_PROFILE })
}

const setEditableProfileClientInfo = (client) => async dispatch => {
  await setFleetAdminEmail(client)

  dispatch({
    type: SET_EDITABLE_PROFILE_CLIENT_INFO,
    client
  })
}

const startLoadSuggestions = ({ value }) => async dispatch => {
  try {
    if (!value) {
      return
    }

    dispatch({
      type: FETCH_EDITABLE_PROFILES_SUGGESTIONS,
      suggestions: [...initialUserSuggestions, {
        clientRaw: {
          firstName: 'Loading...',
          userType: 'loading'
        }
      }]
    })

    let users = await dashboardService.getUsers(value)

    if (users.length === 0) {
      users = [{
        clientRaw: {
          firstName: 'No user with such parameter',
          userType: 'empty'
        }
      }]
    }

    dispatch({
      type: FETCH_EDITABLE_PROFILES_SUGGESTIONS_SUCCESS,
      suggestions: [...initialUserSuggestions, ...users],
      value
    })

  } catch (error) {
    console.error(error)
  }
}

const updateSuggestionInputValue = (event, { newValue }) => async dispatch => {
  dispatch({
    type: CHANGE_SUGGESTION_INPUT_VALUE,
    value: newValue
  })
}

const clearSuggestions = () => async dispatch => {
  dispatch({ type: CLEAR_AUTOSUGGEST })
}

export default {
  selectProfileInfo,
  clearEditableProfile,
  resetEditableProfileFormValues,
  removeProfile,
  saveProfile,
  setEditableProfileClientInfo,
  updateSuggestionInputValue,
  clearSuggestions,
  startLoadSuggestions
}

const setFleetAdminEmail = async (client) => {
  let fleetAdminEmail = 'No Fleet Admin'

  try {
    if (client.fleetAdminId) {
      const fleetAdmin = await usersService.getUser(client.fleetAdminId)
      fleetAdminEmail = fleetAdmin.email
    }
  } catch (error) {
    fleetAdminEmail = "Fleet admin wasn't found"
  }

  client.fleetAdminEmail = fleetAdminEmail
}
