import http from './http'

const createEmergencyContact = async (emergencyContact) => {
  try {
    const response = await http.post('emergencyContact', emergencyContact)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const updateEmergencyContact = async (emergencyContact) => {
  try {
    const response = await http.put('emergencyContact', emergencyContact)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const fetchEmergencyContact = async (emergencyContactId) => {
  try {
    const response = await http.get(`emergencyContact/${emergencyContactId}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export default {
  createEmergencyContact,
  updateEmergencyContact,
  fetchEmergencyContact
}
