import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import products from './products'
import common from './common'
import serviceHistory from './serviceHistroy'
import serviceHistoryItem from './serviceHistoryItem'
import editableProfile from './editableProfile'
import selectedProfile from './selectedProfile'
import streaming from './streaming'
import questions from './questions'
import editableFleetAdmin from './editableFleetAdmin'
import editableUser from './editableUser'
import users from './users'
import confirmUser from './confirmUser'
import dashboard from './dashboard'
import editableFleetManager from './editableFleetManager'
import editableLocalAdmin from './editableLocalAdmin'
import resetPM from './resetPM'

export default combineReducers({
  auth,
  products,
  common,
  serviceHistory,
  serviceHistoryItem,
  editableProfile,
  selectedProfile,
  streaming,
  editableFleetAdmin,
  editableUser,
  confirmUser,
  users,
  form: formReducer,
  questions,
  dashboard,
  editableFleetManager,
  editableLocalAdmin,
  resetPM
})
