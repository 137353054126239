import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

const stateToProps = (state) => ({
  auth: state.auth
})

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = rest.auth

  const renderRoute = (props) => {
    return auth.session
      ? <Component {...rest} {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  }
  return <Route {...rest} render={props => renderRoute(props)} />
}

export default connect(stateToProps)(PrivateRoute)
