import React, { Component } from 'react'
import './styles.scss'
import authService from '../../services/auth'
import { FaPlusCircle } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import userRole from '../../constants/userRole'
import DashboardAddButton from '../DashboardAddButton/DashboardAddButton'
import DashboardCategorySelect from '../DashboardCategorySelect/DashboardCategorySelect'
import dashboardCategories from '../../constants/dashboardCategories'

class SearchDeviceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: ''
    }
  }

  handleSearchValueChange = (event) => {
    this.setState({ searchValue: event.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit(this.state.searchValue)
  }

  isAddButtonVisible = () => {
    const currentUserRole = authService.getCurrentUserRole()
    const idx = [userRole.fleetAdmin, userRole.globalAdmin, userRole.localAdmin, userRole.fleetManager]
      .indexOf(currentUserRole)
    return idx !== -1
  }

  handleCrossCLick = () => {
    this.props.resetSearched()
    this.props.setSearchValue('')
    this.setState({ searchValue: '' })
  }

  handleCategorySelect = (category) => {
    this.props.onSelectCategory(category)
    this.setState({ searchValue: '' })
  }

  renderSearchMessage = (category) => {
    let message = ''
    switch (category) {
      case dashboardCategories.users:
        message = 'Enter customer Last Name, First Name, Email Address or Postal Code to locate profile.'
        break
      default:
        message = 'Enter customer Last Name, First Name or Email Address to locate profile.'
        break
    }
    return message
  }

  render() {

    return (
      <div className='SearchDeviceForm'>
        <div className='search-row'>
          <form onSubmit={this.onSubmit}>
            <DashboardCategorySelect onSelect={cat => this.handleCategorySelect(cat)}
              selectedCategory={this.props.selectedCategory} />
            <input type="search"
              name="search"
              id="search"
              className="big-radius"
              placeholder='Search Profile'
              value={this.state.searchValue}
              onChange={e => this.handleSearchValueChange(e)}
              autoFocus
              autoComplete='off' />
            {
              this.props.searched
                ? <FaPlusCircle className='remove-chip' onClick={() => this.handleCrossCLick()} />
                : <button type="submit" className="btn orange">
                  <span className="icon-search"></span>
                </button>
            }
          </form>
          {
            this.isAddButtonVisible()
              ? <DashboardAddButton />
              : <></>
          }
        </div>
        <p className='message'>
          {
            this.renderSearchMessage(this.props.selectedCategory)
          }
        </p>
      </div>
    )
  }
}

export default withRouter(SearchDeviceForm)
