import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import loginActions from '../../store/actions/auth'
import commonActions from '../../store/actions/common'
import { Redirect } from 'react-router-dom'
import history from '../../history'

import LoginForm from '../../components/LoginForm/loginForm'
import ConfirmationDialog from '../../components/ConfirmationDialog/confirmationDialog'
import LargeLogo from '../../components/LargeLogo/largeLogo'
import FlatPopUp from '../../components/FlatPopUp/flatPopUp'
import EnterSignUpCode from '../../components/FlatPopUp/EnterSignUpCode/EnterSignUpCode'
import './styles.scss'
import EnterLoginEmail from '../../components/FlatPopUp/EnterLoginEmail/EnterLoginEmail'
import passwordActions from '../../store/actions/forgotPassword'

const stateToProps = (state) => ({
    auth: state.auth,
    isSignUpCodeValid: state.common.isSignUpCodeValid,
    isSignUpCodeValidating: state.common.isSignUpCodeValidating,
    snackbarRef: state.common.snackbarReference,
    registrationApprovePopUpText: state.common.registrationApprovePopUpText,
    showRegistrationApprovePopUp: state.common.showRegistrationApprovePopUp
})

const dispatchToProps = {
    login: loginActions.login,
    submitSignUpCode: commonActions.submitSignUpCode,
    resetSignUpCodeLabel: commonActions.resetSignUpCodeLabel,
    setShowRegistrationApprovePopUp: commonActions.setShowRegistrationApprovePopUp,
    ...passwordActions
}

const LoginPage = props => {

    const [showSignUpPopUp, setShowSignUpPopUp] = useState(false)
    const [showForgotPasswordPopUp, setShowForgotPasswordPopUp] = useState(false)

    const onLogin = async (username, password) => {
        return await props.login(username, password)
    }

    const onSignUpCodeSubmit = (code) => {
        props.submitSignUpCode(code)
    }

    const onSignUpCodeCancel = () => {
        setShowSignUpPopUp(false)
        props.resetSignUpCodeLabel()
    }

    const onForgotPasswordEmailSubmit = (email) => {
        props.sendForgotPasswordEmail(email)
            .then(() => {
                setShowForgotPasswordPopUp(false)
                props.snackbarRef.current.openSnackBar('Link to reset password was sent')
            })
    }

    const onForgotPasswordCancel = () => {
        setShowForgotPasswordPopUp(false)
    }

    const { session } = props.auth
    const from = (history.location
        && history.location.state
        && history.location.state.from)
        || { pathname: "/devices" }// path to home component

    if (session) {
        return <Redirect to={from} />
    }

    return (
        <div id='login-view'>
            <ConfirmationDialog submitOnly
                show={props.showRegistrationApprovePopUp}
                onOk={() => props.setShowRegistrationApprovePopUp(false)}
                title={props.registrationApprovePopUpText} />
            {
                showSignUpPopUp &&
                <FlatPopUp>
                    <EnterSignUpCode
                        onSubmit={onSignUpCodeSubmit}
                        onCancel={onSignUpCodeCancel}
                        isCodeValid={props.isSignUpCodeValid}
                        isCodeValidating={props.isSignUpCodeValidating} />
                </FlatPopUp>
            }
            {
                showForgotPasswordPopUp &&
                <FlatPopUp>
                    <EnterLoginEmail
                        onSubmit={(email) => onForgotPasswordEmailSubmit(email)}
                        onCancel={() => onForgotPasswordCancel()} />
                </FlatPopUp>
            }
            <LargeLogo />
            <div className='spacer' />
            <LoginForm onSignUp={() => setShowSignUpPopUp(true)}
                onLogin={(u, p) => onLogin(u, p)}
                onForgotPassword={() => setShowForgotPasswordPopUp(true)}
                snackbar={props.snackbarRef} />
        </div>
    )

}

export default compose(
    connect(stateToProps, dispatchToProps)
)(LoginPage)