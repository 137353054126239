import {
  CLOSE_DIAGNOSTIC_QUESTIONS_POP_UP,
  SET_SELECTED_QUESTION_TREE,
  CLEAR_QUESTION_TREE_TIMEOUT,
  SET_HIDE_FREQUENCY_TIMER_FOR_SELECTED_QUESTION_TREE,
  CLEAR_QUESTION_COOLDOWNS,
  SET_QUESTION_POP_UP_POSITION
} from '../types/questions'

const initialState = {
  selectedQuestionTree: null,
  hideFrequencyTimer: null,
  position: null,
  questionCooldowns: {} // dictionary id: timer
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case SET_SELECTED_QUESTION_TREE: {
      console.log('SET_SELECTED_QUESTION_TREE')
      let newQuestionCooldowns = { ...state.questionCooldowns }
      if (payload.selectedQuestionTreeTimeout) {
        newQuestionCooldowns[payload.selectedQuestionTree.diagnosticsRuleId] = payload.selectedQuestionTreeTimeout
      }
      return { ...state, selectedQuestionTree: payload.selectedQuestionTree, questionCooldowns: newQuestionCooldowns }
    }
    case CLOSE_DIAGNOSTIC_QUESTIONS_POP_UP:
      console.log('CLOSE_DIAGNOSTIC_QUESTIONS_POP_UP')
      return { ...state, selectedQuestionTree: null, hideFrequencyTimer: null }
    case CLEAR_QUESTION_TREE_TIMEOUT: {
      const newQuestionCooldowns = { ...state.questionCooldowns }
      delete newQuestionCooldowns[payload.diagnosticsRuleId]
      return { ...state, questionCooldowns: newQuestionCooldowns }
    }
    case SET_HIDE_FREQUENCY_TIMER_FOR_SELECTED_QUESTION_TREE:
      return { ...state, hideFrequencyTimer: payload.timer }
    case CLEAR_QUESTION_COOLDOWNS:
      return { ...state, questionCooldowns: {} }
    case SET_QUESTION_POP_UP_POSITION:
      return { ...state, position: payload.position }
    default:
      return state
  }
}
