import React from 'react'
import CardBlock from '../CardBlock/cardBlock'
import BriefSystemInformation from '../../components/BriefSystemInformation/briefSystemInformation'
import BriefServiceHistory from '../BriefServiceHistory/briefServiceHistory'
import BriefDeviceFeed from '../../components/BriefDeviceFeed/briefDeviceFeed'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const stateToProps = state => ({
  selectedProfile: state.selectedProfile
})

const HomeView = (props) => {
  const yellowHeaderInDeviceFeed =
    !props.selectedProfile.isConnected || !props.selectedProfile.messageReceived || (props.selectedProfile.liftOperationStateMessageReceived && props.selectedProfile.liftOperationState.v === 'WAIT')

  const getExpandDisabled = () => {
    const { profile } = props.selectedProfile

    return !(profile && profile.assistId)
  }

  const handleAssignAssistClick = () => {
    props.history.push(`${props.match.url}/assign-device`)
  }

  return (
    <>
      <div className='col rows-2'>
        <CardBlock header='System Information'
          linkTo={`${props.match.url}/system-info`}
          expandDisabled={getExpandDisabled()}>
          <BriefSystemInformation profile={props.selectedProfile.profile}
            loading={props.selectedProfile.isSystemInfoLoading}
            displaySpinner={props.selectedProfile.displaySystemInfoSpinner} />
        </CardBlock>
        <CardBlock header='Service History' linkTo={`${props.match.url}/service-history`}>
          <BriefServiceHistory reports={props.selectedProfile.reports}
            repairTypes={props.selectedProfile.repairTypes}
            loading={props.selectedProfile.isServiceHistoryLoading} />
        </CardBlock>
      </div>
      <div className='col rows-1'>
        <CardBlock header='Device Feed'
          linkTo={`${props.match.url}/device-feed`}
          FheaderColor={yellowHeaderInDeviceFeed ? '#FFDE00' : null}
          expandDisabled={getExpandDisabled()}>
          <BriefDeviceFeed lastMessageTime={props.selectedProfile.lastDeviceFeedMessageTime}
            feed={props.selectedProfile.deviceFeed}
            isFeedLoading={props.selectedProfile.isDeviceFeedLoading || (props.selectedProfile.isSystemInfoLoading && props.selectedProfile.profile === null)}
            profile={props.selectedProfile.profile}
            onAssignAssistClick={handleAssignAssistClick} />
        </CardBlock>
      </div>
    </>
  )
}

export default connect(stateToProps)(withRouter(HomeView))
