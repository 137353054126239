import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.scss'
import Root from './Root'
import { configureStore } from './store'

const store = configureStore()

ReactDOM.render(<Root store={store} />, document.getElementById('root'))
