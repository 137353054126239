import React, { Component } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import './styles.scss'

class PhoneInput extends Component {
  render() {
    const {
      id,
      input: { value, onChange, ...other },
      meta: { touched, error }
    } = this.props
    const hasError = touched && error
    return (
      <>
        {hasError && <div className='validation-message'>{error}</div>}
        <ReactPhoneInput
          country={'us'}
          value={value}
          inputProps={{ ...other, id }}
          onChange={(val) => onChange(val)}
        />
      </>
    )
  }
}

export default PhoneInput
