import React from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import SearchDeviceHeader from '../../containers/SearchDeviceHeader/searchDeviceHeader'
import confirmUserActions from '../../store/actions/confirmUser'
import { Link, withRouter } from 'react-router-dom'
import roleDict from '../../utils/roleNamesDict'

class UserCreationConfirmation extends React.Component {

    state = {

    }

    componentDidMount() {
        this.props.fetchConfirmationUser(this.props.match.params.userId)
            .then(() => {
                if (this.props.user.isActive) {
                    this.props.history.push('/devices')
                }
            })
    }

    handleAccept = () => {
        this.props.acceptUser(this.props.match.params.userId)
            .then(() => {
                this.props.history.push('/devices')
                this.props.snackbarRef.current.openSnackBar('User was confirmed.', { backgroundColor: '#469D7C' })
            })
            .catch((e) => {
                this.props.snackbarRef.current.openSnackBar(e.message, { backgroundColor: '#a83256' })
            })
    }

    handleReject = () => {
        this.props.history.push('/devices')
        this.props.snackbarRef.current.openSnackBar('User was rejected.')
    }


    // if role === user then redirect or if selected user is confirmed then redirect
    render() {
        const { user } = this.props
        return (

            <div className='UserCreationConfirmation'>

                <SearchDeviceHeader />
                <div className="back">
                    <Link to='/devices' />
                    <div className="wide-container">
                        <div className="h1">
                            {
                                user
                                    ? `${user.firstName} ${user.lastName}`
                                    : 'Loading...'
                            }
                        </div>
                    </div>
                </div>
                <div className="wide-container profile-block">
                    {/* <EditableUserInformationForm userId={props.match.params.userId} updateTitle={setTitle} /> */}

                    <label>First Name</label>
                    <div className='uneditable-form-field'>
                        {user && user.firstName}
                    </div>

                    <label>Last Name</label>
                    <div className='uneditable-form-field'>
                        {user && user.lastName}
                    </div>

                    <label>Role</label>
                    <div className='uneditable-form-field'>
                        {user && roleDict[user.role]}
                    </div>

                    {
                        user && user.role === 'user'
                            ? <></>
                            : <>
                                <label>Contact Phone Number</label>
                                <div className='uneditable-form-field'>
                                    {user && (user.phone || '-')}
                                </div>
                            </>
                    }

                    <label>Email</label>
                    <div className='uneditable-form-field'>
                        {user && user.email}
                    </div>

                    <div className='buttons-container'>
                        <input type='button' value='Accept' className='btn green big-radius shadow' onClick={() => { this.handleAccept() }} />
                        <input type='button' value='Reject' className='btn red big-radius shadow' onClick={() => { this.handleReject() }} />
                    </div>
                </div>
            </div>
        )
    }

}

const stateToProps = state => ({
    user: state.confirmUser.user,
    snackbarRef: state.common.snackbarReference,
})

const dispatchToProps = {
    fetchConfirmationUser: confirmUserActions.fetchUser,
    acceptUser: confirmUserActions.accept,
    rejectUser: confirmUserActions.reject
}

export default connect(stateToProps, dispatchToProps)(withRouter(UserCreationConfirmation))
