import {
    FETCH_EDITABLE_FLEET_MANAGER_ERROR,
    FETCH_EDITABLE_FLEET_MANAGER_INFO,
    FETCH_EDITABLE_FLEET_MANAGER_INFO_SUCCESS,
    CLEAR_EDITABLE_FLEET_MANAGER,
    SAVE_EDITABLE_FLEET_MANAGER,
    SET_EDITABLE_FLEET_MANAGER
} from '../types/editableFleetManager'
import usersService from '../../services/usersService'
import { reset } from 'redux-form'
import userRole from '../../constants/userRole'
import diff from 'object-diff'
import { objectPropsEmptyToNull } from '../../utils/objectPropsEmptyToNull'

const saveFleetManager = (userId, userData) => async dispatch => {
    try {
        let user = null

        if (userId === 'new') {

            const newUser = {
                mobilePhone: userData.mobilePhone,
                firstName: userData.firstName,
                lastName: userData.lastName,
                isActive: false,
                signUpCode: userData.signUpCode,
                phone: userData.phone,
                email: userData.email,
                role: userRole.fleetManager,
                password: userData.password,
                streetAddress: userData.streetAddress,
                zip: userData.zip,
                state: userData.state,
                city: userData.city,
                country: userData.country
            }

            newUser.mobilePhone === '+' && delete newUser.mobilePhone
            newUser.phone === '+' && delete newUser.phone

            user = await usersService.createUser(newUser)
        } else {
            const existingFleetManager = await usersService.getUser(userId)

            delete userData.fleetAdminEmail

            objectPropsEmptyToNull(userData)
            userData.phone = userData.phone === '+' ? null : userData.phone
            userData.mobilePhone = userData.mobilePhone === '+' ? null : userData.mobilePhone
            
            const editedUser = {
                id: userId,
                ...diff(existingFleetManager, userData)
            }

            user = await usersService.updateUser(editedUser)
        }

        dispatch({ type: SAVE_EDITABLE_FLEET_MANAGER, user })
    } catch (error) {
        throw error
    }
}

const selectFleetManagerInfo = (userId) => async dispatch => {
    try {
        dispatch({ type: FETCH_EDITABLE_FLEET_MANAGER_INFO })
        const user = await usersService.getUser(userId)

        await setFleetAdminEmail(user)

        if (user.role !== userRole.fleetManager) {
            throw new Error('Selected user is not a Fleet Manager.')
        }

        dispatch({ type: FETCH_EDITABLE_FLEET_MANAGER_INFO_SUCCESS, user })
    } catch (error) {
        dispatch({ type: FETCH_EDITABLE_FLEET_MANAGER_ERROR, error })
    }
}

const clearFleetManagerInfo = () => async dispatch => {
    dispatch({ type: CLEAR_EDITABLE_FLEET_MANAGER })
}

const resetEditableFleetManagerFormValues = () => async dispatch => {
    dispatch(reset('editableFleetManagerInformationForm'))
}

const setEditableFleetManagerInfo = (data) => async dispatch => {
    await setFleetAdminEmail(data)
    dispatch({
        type: SET_EDITABLE_FLEET_MANAGER,
        data
    })
}

export default {
    saveFleetManager,
    selectFleetManagerInfo,
    clearFleetManagerInfo,
    resetEditableFleetManagerFormValues,
    setEditableFleetManagerInfo
}

const setFleetAdminEmail = async (user) => {
    let fleetAdminEmail = 'No Fleet Admin'

    try {
        if (user.fleetAdminId) {
            const fleetAdmin = await usersService.getUser(user.fleetAdminId)
            fleetAdminEmail = fleetAdmin.email
        }
    } catch (error) {
        fleetAdminEmail = "Fleet admin wasn't found"
    }

    user.fleetAdminEmail = fleetAdminEmail
}