
import {
  FETCH_CONFIRM_USER,
  FETCH_CONFIRM_USER_SUCCESS,
  FETCH_CONFIRM_USER_ERROR,
  CLEAR_CONFIRM_USER
} from '../types/confirmUser'

const initialState = {
  isLoading: false,
  error: null,
  user: null
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_CONFIRM_USER:
      return { ...state, isLoading: true }
    case FETCH_CONFIRM_USER_SUCCESS:
      return { ...state, isLoading: false, user: payload.user }
    case FETCH_CONFIRM_USER_ERROR:
      return { ...state, isLoading: false, error: payload.error }
    case CLEAR_CONFIRM_USER:
      return initialState
    default:
      return state
  }
}
