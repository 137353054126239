import React, { Component } from 'react'
import './styles.scss'

class DeleteConfirmationDialog extends Component {
    handleOk = () => {
        this.props.onClose(true)
    }

    handleCancel = () => {
        this.props.onClose(false)
    }

    render() {
        return (
            <div className={`delete-profile-overlay ${this.props.show ? 'show' : 'hide'}`} onClick={this.handleCancel}>
                <div className='profile-delete-confirmation-dialog'>
                    <span className='icon-warning' />
                    {this.props.warningText}
                    <div className='profile-delete-actions'>
                        <button className='btn cancel shadow' onClick={this.handleCancel}>Cancel</button>
                        <button className='btn red shadow' onClick={this.handleOk}>{this.props.deleteButtonText || 'Delete'}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeleteConfirmationDialog
