import {
    SET_CHECK_RESET_PM_PASSWORD_LOADING,
    SET_RESETTING_PM
} from '../types/resetPM'

const initialState = {
    checkPasswordLoading: false,
    resetting: false
}

export default (state = initialState, payload) => {
    switch (payload.type) {
        case SET_CHECK_RESET_PM_PASSWORD_LOADING:
            return { ...state, checkPasswordLoading: payload.loading }
        case SET_RESETTING_PM:
            return { ...state, resetting: payload.resetting }
        default:
            return state
    }
}
