import http from './http'

const fetchClientTypes = async () => {
  try {
    const response = await http.get('clientTypes')
    return response.data
  } catch (error) {
    console.log(error)
    throw error.response.data
  }
}

const createClient = async (client) => {
  try {
    const response = await http.post('client', client)
    return response.data
  } catch (error) {
    console.log(error)
    throw error.response.data
  }
}

// doesnt work
const updateClient = async (client) => {
  try {
    const response = await http.put(`/client`, client)
    return response.data
  } catch (error) {
    console.log(error)
    throw error.response.data
  }
}

const fetchClient = async (clientId) => {
  try {
    const response = await http.get(`client/${clientId}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error.response.data
  }
}

export default {
  fetchClientTypes,
  createClient,
  updateClient,
  fetchClient
}
