import React from 'react'
import './styles.scss'
import LoadingSpinner from '../LoadingSpinner/loadingSpinner'
import userTypes from '../../utils/userTypes'

const BriefSystemInformation = (props) => {
  const { profile, loading, displaySpinner } = props

  const getSubtitleByUserType = (userType) => {
    if (!userType) {
      return
    }

    const formatted = userType.trim().toLowerCase()
    let result = 'Client'

    if (formatted === userTypes.facility) {
      result = 'Location'
    }

    return result
  }

  const getDescriptionByUserType = (userType) => {
    if (!userType) {
      return
    }

    const formatted = userType.trim().toLowerCase()

    switch (formatted) {
      case userTypes.facility:
        return (
          <p>
            {`${profile.clientRaw.companyName},`}
            <br />
            {`${profile.clientRaw.unit},`}
            <br />
            {`Room ${profile.clientRaw.roomNumber}`}
          </p>
        )
      case userTypes.individual:
        return (
          <p>
            {`${profile.clientRaw.firstName} ${profile.clientRaw.lastName}${profile.clientRaw.streetAddress ? ',' : ''}`}
            <br />
            {`${profile.clientRaw.streetAddress || ''}${profile.clientRaw.city ? ',' : ''}`}
            <br />
            {`${profile.clientRaw.city || ''}${profile.clientRaw.state ? ',' : ''} ${profile.clientRaw.state || ''}`}
          </p>
        )
      default:
        return null
    }
  }

  return (
    <div className='BriefSystemInformation'>
      <div className='col'>
        {
          // when we unassign/assign we don't recieve the profile and it shows loader
          !profile || (loading && displaySpinner)
            ? <LoadingSpinner />
            : <>
              <div className='device-title'>{profile.serialNumber}</div>
              <div className='text'>
                <span className='h6'>Model</span>
                <p>{profile.modelNumber}</p>
                <span className='h6'>{getSubtitleByUserType(profile.clientRaw.userType)}</span>
                {getDescriptionByUserType(profile.clientRaw.userType)}
              </div>
            </>
        }

      </div>
    </div>
  )
}

export default BriefSystemInformation
