export const SET_LOGOUT_MENU_OPEN_STATE = 'SET_LOGOUT_MENU_OPEN_STATE'
export const SET_DEVICE_LOGO_MENU_OPEN_STATE = 'SET_DEVICE_LOGO_MENU_OPEN_STATE'

export const SET_DEVICE_FEED_HEADER_SLIDER = 'SET_DEVICE_FEED_HEADER_SLIDER'
export const SET_DEVICE_FEED_CONTENT_SLIDER = 'SET_DEVICE_FEED_CONTENT_SLIDER'

export const SET_SNACKBAR_REF = 'SET_SNACKBAR_REF'

export const CHECK_SIGN_UP_CODE_EXISTENCE = 'CHECK_SIGN_UP_CODE_EXISTENCE' // need to block submit button while loading
export const SET_SIGN_UP_CODE_VALIDATION = 'SET_SIGN_UP_CODE_VALIDATION' //it doesnt mean that code exists it means that request was processed with no errors
export const CHECK_SIGN_UP_CODE_EXISTENCE_ERROR = 'CHECK_SIGN_UP_CODE_EXISTENCE_ERROR'

export const SET_SHOW_REGISTRATION_APPROVE_POP_UP = 'SET_SHOW_REGISTRATION_APPROVE_POP_UP'
