import React from 'react'

export default (props) => {
    const { meta: { error, touched }, input, label, displayErrors, options, displayFn, getValueFn, renderDefaultOption = () => null } = props
    const hasError = (displayErrors || touched) && error

    return <>
        <label htmlFor={input.name}>{label}</label>
        {hasError && <div className='validation-message'>{error}</div>}
        <select name={input.name}
            id={input.name}
            {...input}
            value={input.value ? input.value : ''}>
            {
                renderDefaultOption()
            }
            {
                options.map(o => {
                    return (
                        <option key={getValueFn(o)}
                            value={getValueFn(o)}>
                            {displayFn(o)}
                        </option>)
                })
            }
        </select>
    </>
}
