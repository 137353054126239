import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import './styles.scss'
import RepairCheckBox from '../RepairCheckBox/repairCheckBox'
import moment from 'moment'
import { connect } from 'react-redux'
import serviceHistoryItemActions from '../../store/actions/serviceHistoryItem'
import LoadingSpinner from '../../components/LoadingSpinner/loadingSpinner'

const stateToProps = (state) => ({
  serviceHistoryItem: state.serviceHistoryItem,
  selectedProfile: state.selectedProfile,
  snackbarRef: state.common.snackbarReference
})

class EditableServiceHistoryItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditable: false,
      report: null,
      repairs: []
    }
  }

  componentDidMount() {
    const { reportId } = this.props.match.params

    this.props.selectReport(reportId).then(() => {
      this.setState({
        isEditable: reportId === 'new',
        report: { ...this.props.serviceHistoryItem.report, repairs:{ids:[]} },
        repairs: this.props.serviceHistoryItem.repairTypes.map(r => { r.checked = this.props.serviceHistoryItem.report.repairs.ids.includes(r.repairTypeId); return r })
      })
    })
  }

  handleDateChange = (e) => {
    const newDateValue = e.target.value
    this.setState((state) => {
      const newReport = { ...state.report }
      newReport.reportDate = newDateValue
      return { report: newReport }
    })
  }

  handleCheck = (repair) => {
    this.setState(state => {
      const repairIdx = state.repairs.indexOf(repair)
      const newRepair = { ...state.repairs[repairIdx] }
      newRepair.checked = !newRepair.checked
      const newRepairs = [
        ...state.repairs.slice(0, repairIdx),
        newRepair,
        ...state.repairs.slice(repairIdx + 1)
      ]
      return { repairs: newRepairs }
    })
  }

  handleCommentChange = (value) => {
    this.setState(state => {
      const newReport = { ...state.report }
      newReport.comment = value
      return { report: newReport }
    })
  }

  handleSubmitReport = () => {
    const newReport = { ...this.state.report, profileId: this.props.selectedProfile.profile.profileId }
    newReport.repairs.ids = this.state.repairs.filter(r => r.checked).map(r => r.repairTypeId)
    
    this.props.saveReport(this.props.match.params.reportId, newReport)
    .then(() => {
      this.setState({ isEditable: false })
      this.props.snackbarRef.current.openSnackBar('Report has been saved')
      this.props.history.push(`/devices/${this.props.selectedProfile.profile.profileId}/service-history`)
    })
  }

  onEdit = (e) => {
    this.setState({ isEditable: true })
  }

  render() {
    const linkBack = this.props.match.url.substring(0, this.props.match.url.lastIndexOf('/'))

    return (
      !this.props.selectedProfile.profile || !this.state.report
        ? <LoadingSpinner />
        :
        <div id='editable-service-history-item'>
          <div className={'close'}>
            <Link to={linkBack} />
            <p>{!this.state.report.serviceReportId
              ? 'New Report'
              : `${moment(this.state.report.reportDate).format('LL')}`}</p>
          </div>

          <div className='item-info'>
            <div className='col'>
              <form name='edit' className='form-edit'>
                <p className='h6'>Date</p>
                <input type='date' name='date'
                  id='date' value={moment(this.state.report.reportDate).format('YYYY-MM-DD')}
                  onChange={this.handleDateChange} disabled={ true } />

                <p className='h6'>Repairs (Select 3 Most Relevant)</p>

                <div className='checkboxes'>
                  {
                    this.state.repairs
                      .filter(t => t.profileTypeId === this.props.selectedProfile.profile.profileTypeId).map(repair => {
                        repair.disabled = !this.state.isEditable || (this.state.isEditable && (this.state.repairs.filter(r => r.checked).length >=3 && !repair.checked))
                        return <RepairCheckBox onChange={() => this.handleCheck(repair)}
                          key={repair.repairTypeId} repair={repair} />
                      })
                  }
                </div>

                <p className='h6'>Comments</p>
                <textarea rows={5}
                  className='comments-text-area'
                  value={this.state.report.comment}
                  onChange={(e) => this.handleCommentChange(e.target.value)}
                  disabled={!this.state.isEditable}>

                </textarea>


                <input type='button' className={`btn orange small submit-edit-btn ${this.state.isEditable ? '' : 'hide'}`}
                  disabled={this.state.repairs.every(r => !r.checked)} value='Submit Report' onClick={this.handleSubmitReport} />
                <button type='button' className={`btn orange small submit-edit-btn ${this.state.isEditable ? 'hide' : ''}`}
                  onClick={this.onEdit}>Edit</button>
              </form>
            </div>
          </div>
        </div>
    )
  }
}

export default connect(stateToProps, serviceHistoryItemActions)(withRouter(EditableServiceHistoryItem))
