import { parsePhoneNumberFromString } from 'libphonenumber-js'

// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
// eslint-disable-next-line
const SERIAL_NUMBER_REGEX = /^[a-zA-Z0-9]{8,21}$/
// eslint-disable-next-line
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
// eslint-disable-next-line
export const SEARCH_USER_REGEX = /#|&/

const SIGNUP_CODE_REGEX = /^\d{6,}$/

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

const email = value =>
  value && !EMAIL_REGEX.test(value)
    ? 'Invalid email address'
    : undefined

const phone = num => {
  let value = num

  if (!value || value === '+') {
    return undefined
  }

  if (value[0] !== '+') {
    value = '+' + value
  }

  const phoneNum = parsePhoneNumberFromString(value)

  const isValid = phoneNum && phoneNum.isPossible()
  return !isValid ? 'Invalid phone number' : undefined
}

const serial = value =>
  value && !SERIAL_NUMBER_REGEX.test(value)
    ? 'Invalid serial number'
    : undefined

const password = (value, allValues) => {
  if (!PASSWORD_REGEX.test(value)) {
    return 'Password must contain at least 1 digit, 1 special character and must be at least 8 characters long'
  }

  if (allValues.password !== allValues.confirmPassword) {
    return 'Passwords do not match'
  }
}

const signUpCode = (value) => {
  return value && !SIGNUP_CODE_REGEX.test(value)
    ? 'Sign Up Code must be 6 digits long or more'
    : undefined
}

export default {
  required,
  email,
  phone,
  serial,
  password,
  signUpCode
}
