import React, { useState } from 'react'
import './styles.scss'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'

const LoginForm = (props) => {

  let passwordRef = React.createRef()

  const [loginBtnAvailable, setLoginBtnAvailable] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()

    setLoginBtnAvailable(false)

    props.onLogin(username, password)
      .catch((e) => {
        setLoginBtnAvailable(true)
        props.snackbar.current.openSnackBar(e.message, { backgroundColor: '#a83256' })
      })
  }

  const handlePasswordEyeClick = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <form id='login-form' onSubmit={onSubmit}>
      <div>
        <label htmlFor='username'>Email</label>
        <input onChange={(e) => setUsername(e.target.value)}
          value={username}
          type='text'
          id='username' />
      </div>
      <div className='password'>
        <label htmlFor='password'>Password</label>
        <input onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={passwordVisible ? 'text' : 'password'}
          id='password'
          ref={passwordRef} />
        <span className='password-eye' onClick={handlePasswordEyeClick}>
          {
            passwordVisible
              ? <IoMdEyeOff />
              : <IoMdEye />
          }
        </span>
      </div>
      <div className='remember-me'>
        <label className='checkbox-label'>
          <input type='checkbox' id='remember-me' />
          <span className='checkbox-custom rectangular' />
        </label>
        <label className='remember-label' htmlFor='remember-me'>Remember Me</label>
      </div>
      <input type='submit' disabled={!username || !password || !loginBtnAvailable} value='Login' className={`btn yellow big-radius shadow`} />
      <input type='button' value='Sign Up' className={`btn gray-m big-radius shadow`} onClick={() => props.onSignUp()} />
      <span className='forgot-password'>Forgot Password? <span onClick={() => props.onForgotPassword()}>Click here to Reset Your Password</span></span>
    </form>
  )
}

export default LoginForm
