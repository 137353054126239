import React, { Component } from 'react'
import CardBlock from '../CardBlock/cardBlock'
import DetailDeviceFeed from '../../components/DetailDeviceFeed/detailDeviceFeed'
import withSizes from 'react-sizes'
import { connect } from 'react-redux'
import commonActions from '../../store/actions/common'
import streamingActions from '../../store/actions/streaming'
import selectedProfileActions from '../../store/actions/selectedProfile'

const mapSizesToProps = ({ width }) => ({
  isSliding: width < 1023
})

const mapStateToProps = (state) => ({
  headerSliderRef: state.common.deviceFeedHeaderSliderRef,
  contentSliderRef: state.common.deviceFeedContentSliderRef,
  selectedProfile: state.selectedProfile
})

const mapDispatchToProps = {
  setHeaderSliderRef: commonActions.setDeviceFeedHeaderSlider,
  setContentSliderRef: commonActions.setDeviceFeedContentSlider,
  sendRenewAdvancedDiagnosticsData: streamingActions.sendRenewAdvancedDiagnosticsData,
  exportExcelReport: selectedProfileActions.exportExcelReport,
  getModelNumbers: selectedProfileActions.fetchModelNumbers,
  getLiftCycleHistory: selectedProfileActions.fetchLiftCycleHistory
}

class DetailDeviceFeedCardBlock extends Component {

  componentDidMount() {
    const {
      getModelNumbers,
      getLiftCycleHistory,
      match: { params: { selectedProfileId } }
    } = this.props
    getModelNumbers()
    getLiftCycleHistory(selectedProfileId)
  }

  renewDiagnosticsLog = () => {
    const {
      sendRenewAdvancedDiagnosticsData,
      selectedProfile
    } = this.props
    sendRenewAdvancedDiagnosticsData(selectedProfile.profile.assistId, 'advancedDiagnosticsData') // 'diagnosticsLog')
  }

  exportExcelReport = () => {
    const {
      exportExcelReport,
      selectedProfile
    } = this.props
    exportExcelReport(selectedProfile.profile.profileId)
  }

  getDeviceAnalyticsProps = () => {
    const {
      selectedProfile: { modelNumbers, profile }
    } = this.props

    let model = null

    if (profile && profile.modelNumber) {
      model = modelNumbers.find(m => m.name === profile.modelNumber)
    }

    if (model) {
      return model.deviceAnalyticsFields.split(',')
    } else {
      return []
    }
  }

  render() {
    const {
      isSliding,
      headerSliderRef,
      contentSliderRef,
      setHeaderSliderRef,
      setContentSliderRef,
      selectedProfile,
      match
    } = this.props
    const linkBack = match.url.substring(0, match.url.lastIndexOf('/'))

    return (
      <CardBlock header='Device Feed'
        linkTo={linkBack}
        sliding={isSliding}
        sliderTitles={['Device Feed', 'Cycle History', 'Device Analytics']}
        sliderRef={el => setHeaderSliderRef(el)}
        slider={headerSliderRef}
        sliderSettings={{
          arrows: false,
          slidesToShow: 1,
          infinite: false,
          slidesToScroll: 1,
          dots: true,
          asNavFor: contentSliderRef
        }}
        headerColor={!selectedProfile.isConnected
          || !selectedProfile.messageReceived
          || (selectedProfile.liftOperationStateMessageReceived && selectedProfile.liftOperationState.v === 'WAIT')
          ? '#FFDE00'
          : null}>
        <DetailDeviceFeed
          sliderSettings={{
            arrows: false,
            slidesToShow: 1,
            infinite: false,
            slidesToScroll: 1,
            dots: false,
            asNavFor: headerSliderRef
          }}
          renewDiagnosticsLog={this.renewDiagnosticsLog}
          selectedProfile={selectedProfile}
          sliderRef={el => setContentSliderRef(el)}
          isSliding={isSliding}
          lastDeviceFeedMessageTime={selectedProfile.lastDeviceFeedMessageTime}
          lastErrorLogMessageTime={selectedProfile.lastErrorLogMessageTime}
          lastDiagnosticsLogMessageTime={selectedProfile.lastDiagnosticsLogMessageTime}
          exportExcelReportButtonDisabled={selectedProfile.exportExcelReportButtonDisabled}
          exportExcelReport={this.exportExcelReport}
          deviceAnalyticsProps={this.getDeviceAnalyticsProps()}
        />
      </CardBlock>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(DetailDeviceFeedCardBlock))
