import React, { Component } from 'react'
import smallLogo from '../../assets/img/logo-small.svg'
import './styles.scss'
import { connect } from 'react-redux'
import commonActions from '../../store/actions/common'
import authActions from '../../store/actions/auth'
import ConfirmationDialog from '../../components/ConfirmationDialog/confirmationDialog'
import authService from '../../services/auth'
import roleNamesDict from '../../utils/roleNamesDict'
import { Link } from 'react-router-dom'

const stateToProps = (state) => ({
  logoutMenuOpen: state.common.logoutMenuOpen
})

const dispatchToProps = {
  logout: authActions.logout,
  setLogoutMenuOpenState: commonActions.setLogoutMenuOpenState
}

class SearchDeviceHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogoutConfirmation: false
    }
  }

  handleCloseLogoutConfirmation = (val) => {
    if (val) {
      this.props.logout()
    }
    this.setState({ showLogoutConfirmation: false })
  }

  render() {
    const classes = `settings ${this.props.logoutMenuOpen ? 'open' : ''}`

    return (
      <>
        <ConfirmationDialog title='Are you sure you would like to Log Out?'
          show={this.state.showLogoutConfirmation}
          onOk={() => this.handleCloseLogoutConfirmation(true)}
          onCancel={() => this.handleCloseLogoutConfirmation(false)} />
        <header id='search-device-header'>
          <div className='container'>
            <div className='logo'><img src={smallLogo} alt='handicare' />{roleNamesDict[authService.getCurrentUserRole()]}</div>
            <div className='toolbar'>
              <div className={classes}>
                <span className='icon-gear' onClick={() => this.props.setLogoutMenuOpenState(!this.props.logoutMenuOpen)} />
                <div className='settings-menu'>
                  <ul>
                    <li><Link to='/reset' onClick={() => this.props.setLogoutMenuOpenState(!this.props.logoutMenuOpen)}>Change Password</Link></li>
                    {
                      // eslint-disable-next-line
                    }<li><a onClick={() => this.setState({ showLogoutConfirmation: true })}>Log Out</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(SearchDeviceHeader)
