import React from 'react'
import largeLogo from '../../assets/img/logo-big.svg'

const LargeLogo = () => {
  return (
    <div>
      <img src={largeLogo} alt='handicare logo' />
    </div>
  )
}

export default LargeLogo
