import {
  FETCH_SELCETED_REPORT,
  FETCH_SELECTED_REPORT_SUCCESS,
  SELECTED_REPORT_ERROR
} from '../types/serviceHistoryItem'

const initialState = {
  isLoading: false,
  error: null,
  report: null,
  repairTypes: []
}

export default (state = initialState, payload) => {
  switch (payload.type) {
    case FETCH_SELCETED_REPORT:
      return { ...state, isLoading: true }
    case FETCH_SELECTED_REPORT_SUCCESS:
      return { ...state, isLoading: false, report: payload.report, repairTypes: payload.repairTypes }
    case SELECTED_REPORT_ERROR:
      return { ...state, error: payload.error }
    default:
      return state
  }
}
