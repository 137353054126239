import {
    FETCH_EDITABLE_FLEET_MANAGER_ERROR,
    FETCH_EDITABLE_FLEET_MANAGER_INFO,
    FETCH_EDITABLE_FLEET_MANAGER_INFO_SUCCESS,
    CLEAR_EDITABLE_FLEET_MANAGER,
    SAVE_EDITABLE_FLEET_MANAGER,
    SET_EDITABLE_FLEET_MANAGER
} from '../types/editableFleetManager'

const initialState = {
    isLoading: false,
    error: null,
    data: null,
    userId: null
}

export default (state = initialState, payload) => {
    switch (payload.type) {
        case FETCH_EDITABLE_FLEET_MANAGER_INFO:
            return { ...state, isLoading: true }
        case FETCH_EDITABLE_FLEET_MANAGER_INFO_SUCCESS:
            return { ...state, isLoading: false, data: payload.user }
        case FETCH_EDITABLE_FLEET_MANAGER_ERROR:
            return { ...state, isLoading: false, error: payload.error }
        case CLEAR_EDITABLE_FLEET_MANAGER:
            return initialState
        case SAVE_EDITABLE_FLEET_MANAGER:
            return { ...state, userId: payload.user.id, isLoading: false, error: null }
        case SET_EDITABLE_FLEET_MANAGER:
            return { ...state, data: payload.data }
        default:
            return state
    }
}
