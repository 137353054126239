import React from 'react'
import './styles.scss'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'
import PhoneInput from '../../PhoneInput/phoneInput'
import validators from '../../../utils/validators'
import editableFleetManagerActions from '../../../store/actions/editableFleetManager'
import commonActions from '../../../store/actions/common'
import renderInput from '../../../utils/renderInput'
import usersService from '../../../services/usersService'
import forgotPassword from '../../../services/forgotPassword'
import authService from '../../../services/auth'
import userRole from '../../../constants/userRole'
import countryList from 'country-list'
import DeleteConfirmationDialog from '../../DeleteConfirmationDialog/deleteConfirmationDialog'
import renderSelect from '../../../utils/renderSelect'
import CountryDefaultOption from '../../CountryDefaultOption/CountryDefaultOption'

class EditableFleetManagerForm extends React.Component {

    state = {
        backClicked: false,
        displayErrors: false,
        isEditable: false,
        buttonsDisabled: true,
        showDeleteConfirmationDialog: false
    }

    componentDidMount() {
        const { updateTitle } = this.props

        if (!!this.props.userId) {
            this.props.selectFleetManagerInfo(this.props.userId)
                .then(() => {
                    updateTitle(`${this.props.editableFleetManager.data.lastName} ${this.props.editableFleetManager.data.firstName}`)
                    this.setState({
                        buttonsDisabled: false
                    })
                })
        } else {
            this.setState({
                buttonsDisabled: false,
                isEditable: true
            })
        }
    }

    componentWillUnmount() {
        this.props.clearFleetManagerInfo()
    }

    setDisplayErrors = (val) => {
        this.setState({ displayErrors: val })
    }

    setBackClicked = (val) => {
        this.setState({ backClicked: val })
    }

    handleBackClicked = () => {
        if (!this.props.userId) {
            this.props.history.push('/login')
        } else {
            if (this.state.isEditable) {
                this.setState({ isEditable: false })
            } else {
                this.setBackClicked(true)
            }
        }
    }

    renderButtons = () => {
        const { buttonsDisabled, isEditable } = this.state
        const { editableFleetManager } = this.props

        const session = authService.getSession()

        return (<>
            {
                !session || authService.getCurrentUserRole() === userRole.fleetAdmin
                    ? <div className='buttons-container'>
                        {
                            isEditable
                                ? <input type='submit' value='Save' className='btn green big-radius shadow' disabled={buttonsDisabled} />
                                : <input type='button' value='Edit' className='btn green big-radius shadow' disabled={buttonsDisabled} onClick={(e) => { e.preventDefault(); this.setState({ isEditable: true }) }} />
                        }
                        {
                            !this.props.userId
                                ? <></>
                                : <>
                                    <input type='button' value='Password Reset' className='btn green big-radius shadow'
                                        disabled={isEditable || buttonsDisabled} onClick={e => this.handlePasswordReset(e)} />
                                    <input type='button' value={editableFleetManager.data && editableFleetManager.data.isActive ? 'Deactivate' : 'Activate'}
                                        className='btn yellow big-radius shadow' disabled={isEditable || buttonsDisabled}
                                        onClick={e => this.handleBlockUnblock(e)} />
                                    <input type='button' value='Delete' className='btn red big-radius shadow'
                                        disabled={isEditable || buttonsDisabled} onClick={e => this.handleDelete(e)} />
                                </>
                        }
                    </div>
                    : <></>
            }
        </>
        )

    }

    handleSubmit = (event) => {
        const { currentFormValues, currentSyncErrors, saveFleetManager, snackbarRef, signUpCode } = this.props
        event.preventDefault()

        this.setState({ buttonsDisabled: true })

        for (const prop in currentSyncErrors) {
            if (currentSyncErrors.hasOwnProperty(prop)) {
                this.setDisplayErrors(true)
                this.setState({ buttonsDisabled: false })
                return
            }
        }

        saveFleetManager(this.props.userId || 'new',
            this.props.userId ? currentFormValues : { ...currentFormValues, signUpCode })
            .then(() => {
                this.setState({ buttonsDisabled: false, isEditable: false })
                if (!this.props.userId) {
                    this.props.history.push('/login')
                    this.props.setShowRegistrationApprovePopUp(true, 'Thanks for registering! You will receive an email when your registration has been approved by the system administrator')
                } else {
                    this.props.history.push(`/fleetManager/${this.props.editableFleetManager.userId}`)
                    this.props.selectFleetManagerInfo(this.props.editableFleetManager.userId)
                        .then(() => {
                            this.props.updateTitle(`${this.props.editableFleetManager.data.lastName} ${this.props.editableFleetManager.data.firstName}`)
                            snackbarRef.current.openSnackBar('Fleet Manager has been saved', { backgroundColor: '#469D7C' })
                        })
                }
                this.props.resetEditableFleetManagerFormValues()
            })
            .catch((e) => {
                snackbarRef.current.openSnackBar(e.message, { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    handleBlockUnblock = (event) => {
        event.preventDefault()

        const { snackbarRef, editableFleetManager, setEditableFleetManagerInfo, userId } = this.props

        const snackBarText = !editableFleetManager.data.isActive
            ? 'Fleet Manager was activated successfully.'
            : 'Fleet Manager was deactivated successfully.'

        this.setState({ buttonsDisabled: true })

        usersService.updateUser({ id: userId, isActive: !editableFleetManager.data.isActive })
            .then((user) => {
                setEditableFleetManagerInfo(user)
                snackbarRef.current.openSnackBar(snackBarText, { backgroundColor: '#469D7C' })
                this.setState({ buttonsDisabled: false })
            })
            .catch(() => {
                snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    handleDelete = (event) => {
        event.preventDefault()
        this.setState({ showDeleteConfirmationDialog: true })
    }

    handleDeleteConfirmationClick = (val) => {
        if (val) {
            const { snackbarRef } = this.props

            this.setState({ buttonsDisabled: true })

            usersService.deleteUser(this.props.userId)
                .then(() => {
                    this.props.history.push('/devices')
                    snackbarRef.current.openSnackBar('Fleet Manager was deleted successfully.', { backgroundColor: '#469D7C' })
                })
                .catch(() => {
                    snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                    this.setState({ buttonsDisabled: false })
                })
        }

        this.setState({ showDeleteConfirmationDialog: false })
    }

    handlePasswordReset = (event) => {
        event.preventDefault()

        const { snackbarRef, editableFleetManager } = this.props

        this.setState({ buttonsDisabled: true })

        forgotPassword.sendForgotPasswordEmail(editableFleetManager.data.email)
            .then(() => {
                snackbarRef.current.openSnackBar('Reset password email was sent successfully.', { backgroundColor: '#469D7C' })
                this.setState({ buttonsDisabled: false })
            })
            .catch(() => {
                snackbarRef.current.openSnackBar('There were some errors. Please try again later.', { backgroundColor: '#a83256' })
                this.setState({ buttonsDisabled: false })
            })
    }

    render() {
        const { backClicked, displayErrors } = this.state
        const { editableFleetManager } = this.props

        if (backClicked) {
            return <Redirect to='/devices' />
        }

        return (
            <>
                <DeleteConfirmationDialog show={this.state.showDeleteConfirmationDialog}
                    onClose={this.handleDeleteConfirmationClick}
                    warningText={<p>Are you sure you would like to delete Fleet Manager?</p>} />
                <form className='EditableFleetManagerForm' onSubmit={this.handleSubmit}>

                    {
                        this.state.isEditable
                            ? <Field name='firstName' component={renderInput} label='First Name *' validate={validators.required} displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">First Name</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.firstName}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='lastName' component={renderInput} label='Last Name *' validate={validators.required} displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">Last Name</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.lastName}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='companyName' component={renderInput} label='Company Name' />
                            : <>
                                <label className="not-editable">Company Name</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.companyName}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='streetAddress' component={renderInput} label='Street Address' />
                            : <>
                                <label className="not-editable">Street Address</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.streetAddress}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='city' component={renderInput} label='City' displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">City</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.city}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='state' component={renderInput} label='State/Province' displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">State/Province</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.state}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='country' component={renderSelect} label='Country *' displayErrors={displayErrors} validate={validators.required} options={countryList.getData()}
                                displayFn={c => `${c.code} - ${c.name}`} getValueFn={c => `${c.code} - ${c.name}`} renderDefaultOption={() => <CountryDefaultOption disabled />} />
                            : <>
                                <label className="not-editable">Country</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.country}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='zip' component={renderInput} label='Zip/Postal Code' displayErrors={displayErrors} />
                            : <>
                                <label className="not-editable">Zip/Postal Code</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.zip}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <><label htmlFor='phone'>Cell Phone Number (Alerts)</label>
                                <Field name='phone' id='phone' component={PhoneInput} validate={validators.phone} /></>
                            : <>
                                <label className="not-editable">Cell Phone Number (Alerts)</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.phone}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <><label htmlFor='mobilePhone'>Contact Phone Number</label>
                                <Field id='mobilePhone' name='mobilePhone' component={PhoneInput} validate={validators.phone} /></>
                            : <>
                                <label className="not-editable">Contact Phone Number</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.mobilePhone}
                                </div>
                            </>
                    }

                    {
                        this.state.isEditable
                            ? <Field name='email' component={renderInput} label='Email *' type='email' validate={[validators.required, validators.email]} />
                            : <>
                                <label className="not-editable">Email</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.email}
                                </div>
                            </>
                    }

                    {
                        !this.props.userId
                            ? <>
                                <Field name='password'
                                    component={renderInput}
                                    label='Password *'
                                    type='password'
                                    validate={[validators.password]}
                                    displayErrors={displayErrors} />
                                <Field name='confirmPassword'
                                    component={renderInput}
                                    label='Re-enter Password *'
                                    type='password'
                                    validate={[validators.password]}
                                    displayErrors={displayErrors} />
                            </>
                            : <></>
                    }

                    {
                        this.state.isEditable
                            ? <div className='required-label'>(*)<i>Required Fields</i></div>
                            : <>
                                <label className="not-editable">Fleet Admin Contact Information</label>
                                <div className='uneditable-form-field'>
                                    {editableFleetManager.data && editableFleetManager.data.fleetAdminEmail}
                                </div>
                            </>
                    }

                    {
                        this.renderButtons()
                    }
                </form>
            </>
        )
    }
}

const stateToProps = state => ({
    initialValues: state.editableFleetManager.data,
    currentFormValues: getFormValues('editableFleetManagerInformationForm')(state),
    currentSyncErrors: getFormSyncErrors('editableFleetManagerInformationForm')(state),
    snackbarRef: state.common.snackbarReference,
    editableFleetManager: state.editableFleetManager
})

const dispatchToProps = {
    ...editableFleetManagerActions,
    setShowRegistrationApprovePopUp: commonActions.setShowRegistrationApprovePopUp
}

EditableFleetManagerForm = reduxForm({
    form: 'editableFleetManagerInformationForm',
    enableReinitialize: true
})(EditableFleetManagerForm)

export default connect(stateToProps, dispatchToProps)(withRouter(EditableFleetManagerForm))
