import {
    FETCH_DASHBOARD_CATEGORY_AMOUNTS,
    FETCH_DASHBOARD_CATEGORY_AMOUNTS_ERROR,
    FETCH_DASHBOARD_CATEGORY_AMOUNTS_SUCCESS,
    SET_DASHBOARD_CATEGORY_AMOUNTS,
    SET_DASHBOARD_CATEGORY,
    CLEAR_DASHBOARD_ITEMS,
    FETCH_DASHBOARD_ITEMS,
    FETCH_DASHBOARD_ITEMS_SUCCESS,
    FETCH_DASHBOARD_ITEMS_ERROR,
    SET_DASHBOARD_SEARCHVALUE,
    RESET_DASHBOARD_SEARCHED,
    RESET_DASHBOARD_CATEGORY
} from '../types/dashboard'
import dashboardCategories from '../../constants/dashboardCategories'

const initialState = {
    categoryAmounts: {},
    selectedCategory: dashboardCategories.users,
    items: [],
    searchValue: null,
    searched: false,
    searchedCategory: null,
    isLoading: false,
    error: null
}

export default (state = initialState, payload) => {
    switch (payload.type) {
        case FETCH_DASHBOARD_CATEGORY_AMOUNTS:
            return { ...state, isLoading: true }
        case FETCH_DASHBOARD_CATEGORY_AMOUNTS_SUCCESS:
            return { ...state, isLoading: false, categoryAmounts: payload.categoryAmounts }
        case FETCH_DASHBOARD_CATEGORY_AMOUNTS_ERROR:
            return { ...state, isLoading: false, error: payload.error }
        case SET_DASHBOARD_CATEGORY_AMOUNTS:
            return { ...state, categoryAmounts: payload.categoryAmounts }
        case SET_DASHBOARD_CATEGORY:
            return { ...state, selectedCategory: payload.category }
        case CLEAR_DASHBOARD_ITEMS:
            return { ...state, items: [] }
        case FETCH_DASHBOARD_ITEMS:
            return { ...state, isLoading: true, error: null }
        case FETCH_DASHBOARD_ITEMS_SUCCESS:
            return { ...state, isLoading: false, items: payload.items, searchedCategory: payload.searchedCategory, searched: true }
        case FETCH_DASHBOARD_ITEMS_ERROR:
            return { ...state, error: payload.error }
        case SET_DASHBOARD_SEARCHVALUE:
            return { ...state, searchValue: payload.value }
        case RESET_DASHBOARD_SEARCHED:
            return { ...state, searched: payload.searched }
        case RESET_DASHBOARD_CATEGORY:
            return { ...state, searchedCategory: null, selectedCategory: dashboardCategories.users }
        default:
            return state
    }
}