import http from './http'

const getOpenProfileBySerialNumber = async (serialNumber) => {
    try {
        const response = await http.get(`openprofile/byserial/${serialNumber}`)
        return response.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export default {
    getOpenProfileBySerialNumber
}