import {
    FETCH_USERS,
    FETCH_USERS_ERROR,
    FETCH_USERS_SUCCESS,
    CLEAR_USERS_LIST
} from '../types/users'
import usersService from '../../services/usersService'

const searchUsers = (searchValue) => async dispatch => {
    try {
        dispatch({ type: FETCH_USERS })

        const items = await usersService.searchUsers(searchValue)

        dispatch({ type: FETCH_USERS_SUCCESS, items })

    } catch (error) {
        dispatch({ type: FETCH_USERS_ERROR, error })
    }
}

const sendSignUpInfo = (userId) => async dispatch => {
    return await usersService.sendSignUpInfo(userId)
}

export const clearUsersList = () => ({ type: CLEAR_USERS_LIST })

export default {
    searchUsers,
    sendSignUpInfo
}