import React, { Component } from 'react'
import { connect } from 'react-redux'
import ToolBarHeader from '../ToolBarHeader/toolBarHeader'
import PrivateRoute from '../PrivateRoute/privateRoute'
import './styles.scss'
import commonActions from '../../store/actions/common'
import DetailSystemInfoCardBlock from '../DetailSystemInfoCardBlock/detailSystemInfoCardBlock'
import DetailServiceHistoryCardBlock from '../DetailServiceHistoryCardBlock/detailServiceHistoryCardBlock'
import HomeView from '../HomeView/homeView'
import DetailDeviceFeedCardBlock from '../DetailDeviceFeedCardBlock/detailDeviceFeedCardBlock'
import selectedProfileActions from '../../store/actions/selectedProfile'
import { withRouter } from 'react-router-dom'
import streamingActions from '../../store/actions/streaming'
import QuestionairePopUp from '../../components/QuestionairePopUp/questionairePopUp'
import questionsActions from '../../store/actions/questions'
import { CLEAR_SELECTED_PROFILE } from '../../store/types/selectedProfile'
import { CLEAR_QUESTION_COOLDOWNS } from '../../store/types/questions'
import AssignDeviceCardBlock from '../../components/AssignDeviceCardBlock/AssignDeviceCardBlock'
import { chargeMessage } from '../../store/selectors'

const stateToProps = state => ({
  showOverlay: state.common.logoutMenuOpen || state.common.deviceLogoMenuOpen,
  selectedProfile: state.selectedProfile,
  streaming: state.streaming,
  auth: state.auth,
  questions: state.questions,
  status: chargeMessage(state),
})

const dispatchToProps = {
  ...commonActions,
  ...selectedProfileActions,
  ...streamingActions,
  ...questionsActions,
}

class MasterPage extends Component {
  state = {
    showQuestions: false,
  }

  componentDidUpdate() {
    if (this.props.status !== 'Trying To Connect') {
      clearInterval(this.renewAdvancedDiagnosticsDataTimer)
    }
  }

  componentDidMount() {
    try {
      const {
        match: { params },
        fetchProfileFeed,
        fetchSelectedProfileData,
        // startSendingConnectedMessage,
        fetchSelectedProfileServiceHistory,
        connectToStreamingSocket,
        sendRenewAdvancedDiagnosticsData,
      } = this.props

      const { selectedProfileId } = params

      // check if it has asssist assigned
      fetchProfileFeed(selectedProfileId)
      fetchSelectedProfileData(selectedProfileId).then(() => {
        const { selectedProfile, auth } = this.props
        if (selectedProfile.profile.assistId) {
          sendRenewAdvancedDiagnosticsData(selectedProfile.profile.assistId, 'advancedDiagnosticsData')

          const retryRenewAdvancedDiagnosticsData = () => {
            sendRenewAdvancedDiagnosticsData(selectedProfile.profile.assistId, 'advancedDiagnosticsData')
          }

          this.renewAdvancedDiagnosticsDataTimer = setInterval(retryRenewAdvancedDiagnosticsData, 20 * 1000)

          connectToStreamingSocket(selectedProfile.profile.assistId, 1, auth.session.token.accessToken)
          // startSendingConnectedMessage(selectedProfile.profile.assistId)
        }
      })
      fetchSelectedProfileServiceHistory(selectedProfileId)
    } catch (e) {
      console.log(e)
    }
  }

  componentWillUnmount() {
    const { profile } = this.props.selectedProfile
    if (profile) {
      // check if the data is loaded before we leave the page
      this.props.closeStreamingSocket(profile.assistId)
    }

    clearInterval(this.renewAdvancedDiagnosticsDataTimer)

    this.props.dispatch({ type: CLEAR_SELECTED_PROFILE })
    this.props.dispatch({ type: CLEAR_QUESTION_COOLDOWNS })
  }

  closeMenus = () => {
    this.props.setLogoutMenuOpenState(false)
    this.props.setDeviceLogoMenuOpenState(false)
  }

  stopDragging = ref => {
    const boundClientRect = ref.current.getBoundingClientRect()
    this.props.setQuestionPopUpPosition(boundClientRect.x, boundClientRect.y)
  }

  render() {
    const { selectedProfile, questions } = this.props
    const profileTypeName = (selectedProfile.profile && selectedProfile.profile.profileType && selectedProfile.profile.profileType.name) || 'question'

    return (
      <>
        <div className={`overlay-bg ${this.props.showOverlay ? 'show' : 'hide'}`} onClick={this.closeMenus} />
        {!!questions.selectedQuestionTree && selectedProfile.messageReceived && selectedProfile.isConnected ? (
          <QuestionairePopUp
            questionTree={questions.selectedQuestionTree}
            position={questions.position}
            handleClose={this.props.closeDiagnosticsQuestionPopUps}
            handleDragStop={this.stopDragging}
            setLastQuestion={() => this.props.dispatch(questionsActions.setHideFrequencyTimer())}
          />
        ) : (
          <></>
        )}
        <div id="master-page">
          <ToolBarHeader
            logo={require(`../../assets/img/deviceTypes/${profileTypeName}.png`)}
            header={(selectedProfile.profile && selectedProfile.profile.serialNumber) || 'Loading...'}
          />{' '}
          {/** select profile from state and set header */}
          <div id="content">
            <div className="container">
              <PrivateRoute exact path={this.props.match.path} component={HomeView} />
              <PrivateRoute path={`${this.props.match.path}/system-info`} component={DetailSystemInfoCardBlock} />
              <PrivateRoute path={`${this.props.match.path}/service-history`} component={DetailServiceHistoryCardBlock} />
              <PrivateRoute path={`${this.props.match.path}/device-feed`} component={DetailDeviceFeedCardBlock} />
              {selectedProfile.profile && selectedProfile.profile.assistId ? null : (
                <PrivateRoute path={`${this.props.match.path}/assign-device`} component={AssignDeviceCardBlock} />
              )}
            </div>
          </div>
          <footer />
        </div>
      </>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(withRouter(MasterPage))
