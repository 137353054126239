import {
    FETCH_EDITABLE_LOCAL_ADMIN_ERROR,
    FETCH_EDITABLE_LOCAL_ADMIN_INFO,
    FETCH_EDITABLE_LOCAL_ADMIN_INFO_SUCCESS,
    CLEAR_EDITABLE_LOCAL_ADMIN,
    SAVE_EDITABLE_LOCAL_ADMIN,
    SET_EDITABLE_LOCAL_ADMIN_INFO
} from '../types/editableLocalAdmin'
import usersService from '../../services/usersService'
import { reset } from 'redux-form'
import userRole from '../../constants/userRole'
import diff from 'object-diff'
import { objectPropsEmptyToNull } from '../../utils/objectPropsEmptyToNull'

const saveLocalAdmin = (userId, userData) => async dispatch => {
    try {
        let user = null

        if (userId === 'new') {
            const newUser = {
                isActive: true,
                role: userRole.localAdmin,
                ...userData,
                confirmPassword: undefined
            }

            newUser.mobilePhone === '+' && delete newUser.mobilePhone
            newUser.phone === '+' && delete newUser.phone

            user = await usersService.createUser(newUser)
        } else {
            const existingUser = await usersService.getUser(userId)

            objectPropsEmptyToNull(userData)
            userData.phone = userData.phone === '+' ? null : userData.phone
            userData.mobilePhone = userData.mobilePhone === '+' ? null : userData.mobilePhone

            const editedUser = {
                id: userId,
                ...diff(existingUser, userData)
            }

            user = await usersService.updateUser(editedUser)
        }

        dispatch({ type: SAVE_EDITABLE_LOCAL_ADMIN, user })
    } catch (error) {
        dispatch({ type: FETCH_EDITABLE_LOCAL_ADMIN_ERROR, error })
        throw error
    }
}

const selectLocalAdminInfo = (userId) => async dispatch => {
    try {
        dispatch({ type: FETCH_EDITABLE_LOCAL_ADMIN_INFO })
        const user = await usersService.getUser(userId)

        if (user.role !== userRole.localAdmin) {
            throw new Error('Selected user is not a Regional Admin.')
        }

        dispatch({ type: FETCH_EDITABLE_LOCAL_ADMIN_INFO_SUCCESS, user })
    } catch (error) {
        dispatch({ type: FETCH_EDITABLE_LOCAL_ADMIN_ERROR, error })
    }
}

const clearLocalAdminInfo = () => async dispatch => {
    dispatch({ type: CLEAR_EDITABLE_LOCAL_ADMIN })
}

const resetEditableLocalAdminFormValues = () => async dispatch => {
    dispatch(reset('editableLocalAdminInformationForm'))
}

export const setEditableLocalAdminInfo = (data) => ({
    type: SET_EDITABLE_LOCAL_ADMIN_INFO,
    data
})

export default {
    saveLocalAdmin,
    selectLocalAdminInfo,
    clearLocalAdminInfo,
    resetEditableLocalAdminFormValues
}