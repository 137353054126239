import React from 'react'
import './styles.scss'
import authService, { rolePriorities } from '../../services/auth'

const AnalyticButtons = (props) => {

    const { buttonsData } = props

    return (
        <div className='AnalyticButtons'>
            {
                rolePriorities[authService.getCurrentUserRole()] > rolePriorities.localAdmin
                    ? <div className='analytic-button' onClick={props.getLocalAdmins}>
                        <div className='analytic-button-count'>{buttonsData['localAdmins']}</div>
                        <div className='analytic-button-title'>Regional Admins</div>
                    </div>
                    : <></>
            }
            {
                rolePriorities[authService.getCurrentUserRole()] > rolePriorities.fleetAdmin
                    ? <div className='analytic-button' onClick={props.getFleetAdmins}>
                        <div className='analytic-button-count'>{buttonsData['fleetAdmins']}</div>
                        <div className='analytic-button-title'>Fleet Admins</div>
                    </div>
                    : <></>
            }
            {
                rolePriorities[authService.getCurrentUserRole()] > rolePriorities.fleetManager
                    ? <div className='analytic-button' onClick={props.getFleetManagers}>
                        <div className='analytic-button-count'>{buttonsData['fleetManagers']}</div>
                        <div className='analytic-button-title'>Fleet Managers</div>
                    </div>
                    : <></>
            }
            <div className='analytic-button' onClick={props.getUsers}>
                <div className='analytic-button-count'>{buttonsData['users']}</div>
                <div className='analytic-button-title'>Users</div>
            </div>
            <div className='analytic-button warning' onClick={props.getProblemProfiles}>
                <div className='analytic-button-count'>{buttonsData['problem']}</div>
                <div className='analytic-button-title'>Problem Devices</div>
            </div>
            <div className='analytic-button warning' onClick={props.getOverdueProfiles}>
                <div className='analytic-button-count'>{buttonsData['overdue']}</div>
                <div className='analytic-button-title'>PM Service Due</div>
            </div>
        </div>
    )
}

export default AnalyticButtons
