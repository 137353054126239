import React from 'react'
import './styles.scss'
import LoadingSpinner from '../../../LoadingSpinner/loadingSpinner'

const Assigning = (props) => {
    return (
        <div className='Assigning'>
            <div className='description'>
                Connecting with the Assist to complete assignment process.
            </div>
            <LoadingSpinner />
        </div>
    )
}

export default Assigning
