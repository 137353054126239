import React from 'react'
import './styles.scss'

const FlatPopUp = (props) => {

    return (
        <div className='FlatPopUp'>
            <div className='overlay'>
                <div className='dialog-box'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default FlatPopUp