import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import './styles.scss'

class CardBlock extends Component {
  render() {
    const {
      header,
      linkTo,
      sliding,
      slider,
      sliderRef,
      sliderTitles,
      sliderSettings,
      headerColor,
      expandDisabled
    } = this.props

    return (
      <div className='block'>
        <div className={`title ${sliding ? 'sliding-title' : ''}`} style={headerColor ? { backgroundColor: headerColor } : null}>

          {
            sliding
              ? <div className='mobile-navigate'>
                <div className='mobile-navigate-slider'>
                  <div className='mobile-navigate-arrow-prev' onClick={() => slider.slickPrev()} />
                  <div id='mobile-navigate-slides'>
                    <Slider {...sliderSettings}
                      ref={sliderRef}>
                      {
                        sliderTitles.map(sliderTitle => <span className='h1' key={sliderTitle}>{sliderTitle}</span>)
                      }
                    </Slider>
                  </div>
                  <div className='mobile-navigate-arrow-next' onClick={() => slider.slickNext()} />
                </div>
              </div>
              : <></>
          }

          <h1>{header}</h1>
          {
            linkTo
              ? <span className={`icon-filter ${expandDisabled ? 'disabled' : ''}`}>
                {
                  expandDisabled
                    ? null
                    : <Link to={linkTo} />
                }
              </span>
              : null
          }
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default withRouter(CardBlock)
