import React, { Component } from 'react'
import DetailServiceHistory from '../../components/DetailServiceHistory/detailServiceHistory'
import CardBlock from '../CardBlock/cardBlock'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute/privateRoute'
import EditableServiceHistoryItem from '../EditableServiceHistoryItem/editableServiceHistoryItem'
import { connect } from 'react-redux'

const stateToProps = state => ({
  selectedProfile: state.selectedProfile
})

class DetailServiceHistoryCardBlock extends Component {
  render () {
    const linkBack = this.props.match.url.substring(0, this.props.match.url.lastIndexOf('/'))

    return (
      <CardBlock header='Service History' linkTo={linkBack}>
        <Switch>
          <PrivateRoute exact path={this.props.match.path} component={DetailServiceHistory} selectedProfile={this.props.selectedProfile} />
          <PrivateRoute path={`${this.props.match.path}/:reportId`}
            component={EditableServiceHistoryItem} />
        </Switch>
      </CardBlock>
    )
  }
}

export default connect(stateToProps)(DetailServiceHistoryCardBlock)
